import React, { useEffect, useState } from 'react'
import './Clendar.scss'
import uaertext from '../../../assets/uaertext.svg'
import axios from 'axios'
export default function Calendar({ labelName, fliterDate }) {


    useEffect(() => {
        //generateCalendar()
        daycalendarFunction()
    }, [fliterDate])

    const timepicker = [
        "1:00PM",
        "1:30PM",
        "2:00PM",
        "2:30PM",
        "3:00PM",
        "3:30PM",
        "4:00PM",
        "4:30PM",
        "5:00PM",
        "5:30PM",
        "6:00PM",
        "6:30PM",
        "7:00PM",
        "7:30PM",


    ];

    const [startDate, setStartDate] = useState('2024-06-01');
    const [endDate, setEndDate] = useState('2024-06-01');
    const [calendar, setCalendar] = useState([]);

    const [Daycalendar, setDaycalendar] = useState([]);
    const [daytimer, setdaytimer] = useState([]);
    const daycalendarFunction = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getbooking?teamusername=VSRNCC&calendarview=true&dateOfBooking=${fliterDate}`
            )
            .then(({ data }) => {
                if (data.success) {


                    setDaycalendar(data?.result)
                    // let pop = data?.result?.map(item => {
                    //     return (
                    //         item?.time
                    //     )
                    // })
                    // setdaytimer(pop)
                    // console.log("pop", pop)
                } else {
                    setDaycalendar([])
                }
            });
    }

    const generateCalendar = () => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dayNames = ["Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday", "Sunday"];
        const calendarArray = [];

        for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
            const dayNumber = date.getDate();
            const dayName = dayNames[date.getDay()];
            const timeEvents = timepicker.map((time, i) => {
                // For demo purposes, mapping some names to specific times
                // if (dayNumber === 1 && time === "12:30AM") {
                //     return { time, name: "John Doe" };
                // } else if (dayNumber === 3 && time === "1:00AM") {
                //     return { time, name: "Jane Smith" };
                // } else {
                //     return { time, name: "" };
                // }
                return {
                    time, obj: [{
                        name: "Jerome Bellingham", id: i
                    },

                    ]
                };
            });
            calendarArray.push({ dayNumber, dayName, timeEvents });
        }

        setCalendar(calendarArray);
    };

    console.log("adasdasd", calendar)
    const [info, setinfo] = useState({
        time: "",
        day: "",
        index: ""
    })

    const addfunc = (time, day, index) => {
        setinfo({
            time: time,
            day: day,
            index: index
        })
    }
    const conditionalStatus = (item) => {
        console.log("czcxznczbcnz", item)
        if (item === "booked") {
            return <div
                className="colorbutton"
                style={{ background: "#34A853" }}>Upcoming</div>;
        } else if (item === "Upcoming") {
            return <div
                className="colorbutton"
                style={{ background: "#34A853" }}>Upcoming</div>;
        }

        else if (item === "cancelled") {
            return <div className="colorbutton" style={{ background: "#FF0000" }}>Cancelled</div>;
        } else if (item === "arrived") {
            return <div className="colorbutton" style={{ background: "#C57E50" }}>Arrived</div>;
        } else if (item === "pre-assessed") {
            return <div className="colorbutton" style={{ background: "#0052B4" }}>Pre-Assessed</div>;
        } else if (item === "consulting") {
            return <div className="colorbutton" style={{ background: "#FBBC05" }}>In Process</div>;
        } else if (item === "completed") {
            return <div className="colorbutton" style={{ background: "#000000" }}>Completed</div>;
        }
    };
    return (
        <div className='daycalendar'>

            <div className='tWrap'>


                <div class="tWrap__head">

                    <table>
                        <thead>
                            <tr>
                                <th>Timze</th>
                                <th >{fliterDate?.toString()}</th>
                            </tr>
                        </thead>
                    </table>
                </div>



                <div class="tWrap__body">
                    <table>
                        <tbody>

                            {/* {daytimer.map((time, timeIndex) => (
                                <tr key={timeIndex}>
                                    <td>{time}</td>

                                </tr>
                            ))} */}

                            {
                                Daycalendar?.map((sitem, timeIndex) => {
                                    // console.log("sitem", Object?.values(sitem?.bookingData)?.length)

                                    return (
                                        <tr key={timeIndex}>
                                            <td>{sitem?.time}</td>
                                            <td key={timeIndex}>
                                                <div className='maindv'>
                                                    <div className='celdndartite'
                                                        style={{
                                                            height: "68px"
                                                        }}
                                                    // onMouseEnter={() => addfunc(event?.time, day?.dayNumber, i)}
                                                    // onMouseLeave={() => setinfo({
                                                    //     time: "",
                                                    //     day: "",
                                                    //     index: "",
                                                    // })}
                                                    >
                                                        <div
                                                            className='divspacing'
                                                            style={{
                                                                width: "100%",
                                                                justifyContent: "space-between"
                                                            }}
                                                        >
                                                            <div>
                                                                <div className='linebreak'
                                                                    style={{
                                                                        width: "200px",
                                                                        fontSize: "14px",
                                                                        fontWeight: "600"
                                                                    }}
                                                                >{sitem?.bookingData?.clientdetails?.[0]?.name}</div>


                                                            </div>
                                                            <div className='topmidel'
                                                                style={{
                                                                    width: "100px",
                                                                }}
                                                            >
                                                                <div>

                                                                    <div className='middleshort'>{sitem?.bookingData?.dateOfBooking}</div>


                                                                </div>

                                                            </div>

                                                            <div className='topmidel cutsommodel'
                                                                style={{
                                                                    width: "200px",

                                                                }}
                                                            >

                                                                <div className='middleshort'>
                                                                    {sitem?.bookingData?.specialistDetails?.[0]?.PlayerDetails?.[0]?.name}

                                                                </div>


                                                            </div>


                                                            <div className='topmidel cutsommodel'
                                                                style={{
                                                                    width: "200px",

                                                                }}
                                                            >

                                                                <div className='middleshort'>            {labelName == "theraphy" ? sitem?.bookingData?.theraphyDetails?.[0]?.name : sitem?.bookingData?.servicedetails?.[0]?.name}</div>


                                                            </div>

                                                            <div
                                                                className='divspacing'
                                                            >
                                                                {sitem?.bookingData != "" &&
                                                                    <div className='bottomdown'>
                                                                        <div className='sessionstart'>
                                                                            {conditionalStatus(sitem?.bookingData?.bookingStatus)}
                                                                        </div>
                                                                        <div className='sessionstart'
                                                                            style={{
                                                                                background: "#FC4F00"
                                                                            }}
                                                                        >Start Session</div>

                                                                    </div>
                                                                }


                                                            </div>

                                                        </div>


                                                        {/* {
            day?.dayNumber == info?.day && event?.time == info?.time && i == info?.index &&
            < div className='openclanderDrawer'>


                <div className='topsel'>

                    <div className='img-text-section'>
                        <img src={uaertext} />
                        <div>
                            <div className='titleName'>Dwight Schrutr</div>
                            <div className='subtext'>Female - 25</div>
                            <div className='subtext'>+91 9848 012 345</div>
                        </div>

                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <rect x="0.583008" y="1.22658" width="23" height="23" rx="5.5" fill="#E9FAFF" />
                        <rect x="0.583008" y="1.22658" width="23" height="23" rx="5.5" stroke="#9FE8FF" />
                        <path d="M16.1199 7.63567V17.8175H14.9268L9.37847 9.82317H9.27904V17.8175H8.04608V7.63567H9.23926L14.8074 15.6499H14.9069V7.63567H16.1199Z" fill="#06C1FD" />
                    </svg>
                </div>
                <div className='mainmiddle'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                        <path d="M14.5635 1.41531C15.1377 1.0325 15.958 1.44266 15.958 2.12625V9.15359C15.958 9.83719 15.1377 10.2473 14.5635 9.86453L11.583 7.78641V3.49344L14.5635 1.41531ZM9.39551 0.376251C10.1064 0.376251 10.708 0.977814 10.708 1.68875V9.56375C10.708 10.302 10.1064 10.8763 9.39551 10.8763H1.52051C0.782227 10.8763 0.208008 10.302 0.208008 9.56375V1.68875C0.208008 0.977814 0.782227 0.376251 1.52051 0.376251H9.39551ZM8.52051 6.2825V3.24734C8.52051 2.89188 8.21973 2.59109 7.86426 2.59109H4.80176C4.41895 2.59109 4.14551 2.86453 4.14551 3.24734C4.14551 3.60281 4.41895 3.90359 4.80176 3.90359H6.27832L2.58691 7.595C2.31348 7.84109 2.31348 8.25125 2.58691 8.49734C2.69629 8.63406 2.86035 8.68875 3.05176 8.68875C3.21582 8.68875 3.37988 8.63406 3.5166 8.49734L7.20801 4.80594V6.2825C7.20801 6.66531 7.48145 6.93875 7.86426 6.93875C8.21973 6.93875 8.52051 6.66531 8.52051 6.2825Z" fill="#767A85" />
                    </svg>
                    <div className='topmidel'>
                        <div>

                            <div className='middeltext'>Consultation Details</div>
                            <div className='middleshort'>26-June-2024</div>
                            <div className='middleshort'>09:30 AM - 05:30 PM</div>
                        </div>
                        <div
                            style={{
                                marginTop: "1.2rem"
                            }}
                        >
                            <div className='middleshort'>Body Massage</div>
                            <div className='middeltext'>
                                <div className='cricle'></div>
                                Drg. Wade Warren</div>
                        </div>
                    </div>
                </div>

                <div className='mainmiddle'
                    style={{
                        paddingTop: "0",
                        borderBottom: "1px solid #D1D7E1"
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                        <path d="M14.5635 1.41531C15.1377 1.0325 15.958 1.44266 15.958 2.12625V9.15359C15.958 9.83719 15.1377 10.2473 14.5635 9.86453L11.583 7.78641V3.49344L14.5635 1.41531ZM9.39551 0.376251C10.1064 0.376251 10.708 0.977814 10.708 1.68875V9.56375C10.708 10.302 10.1064 10.8763 9.39551 10.8763H1.52051C0.782227 10.8763 0.208008 10.302 0.208008 9.56375V1.68875C0.208008 0.977814 0.782227 0.376251 1.52051 0.376251H9.39551ZM8.52051 6.2825V3.24734C8.52051 2.89188 8.21973 2.59109 7.86426 2.59109H4.80176C4.41895 2.59109 4.14551 2.86453 4.14551 3.24734C4.14551 3.60281 4.41895 3.90359 4.80176 3.90359H6.27832L2.58691 7.595C2.31348 7.84109 2.31348 8.25125 2.58691 8.49734C2.69629 8.63406 2.86035 8.68875 3.05176 8.68875C3.21582 8.68875 3.37988 8.63406 3.5166 8.49734L7.20801 4.80594V6.2825C7.20801 6.66531 7.48145 6.93875 7.86426 6.93875C8.21973 6.93875 8.52051 6.66531 8.52051 6.2825Z" fill="#767A85" />
                    </svg>
                    <div className='topmidel'>
                        <div>

                            <div className='middeltext'>Status</div>
                            <div className='roundmain'>
                                <div
                                    className='round'
                                ></div>
                                <div className='line'></div>
                                <div
                                    className='round'
                                    style={{
                                        background: "#FC4F00"

                                    }}
                                ></div>
                                <div className='line'></div>
                                <div
                                    className='round'
                                ></div>
                                <div className='line'></div>
                                <div
                                    className='round'
                                ></div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='bottomdown'>
                    <div className='sessionstart'>Start Session</div>
                    <div className='crirlcebutton'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M14.9996 11.4817V13.5893C15.0004 13.7849 14.9602 13.9786 14.8817 14.1578C14.8031 14.3371 14.6879 14.498 14.5435 14.6303C14.399 14.7626 14.2285 14.8633 14.0428 14.9259C13.8571 14.9886 13.6603 15.0119 13.465 14.9943C11.299 14.7594 9.2183 14.0207 7.39023 12.8376C5.68945 11.7589 4.24748 10.3198 3.16674 8.62243C1.9771 6.78969 1.23677 4.70306 1.00571 2.53156C0.988123 2.33729 1.01126 2.1415 1.07364 1.95664C1.13603 1.77178 1.2363 1.60191 1.36807 1.45785C1.49984 1.31378 1.66022 1.19868 1.839 1.11986C2.01779 1.04105 2.21106 1.00025 2.40651 1.00007H4.51826C4.85987 0.996713 5.19105 1.11744 5.45007 1.33976C5.70909 1.56208 5.87828 1.87081 5.92609 2.20841C6.01522 2.88287 6.18052 3.54511 6.41883 4.18249C6.51354 4.43394 6.53403 4.70721 6.47789 4.96993C6.42175 5.23265 6.29132 5.4738 6.10207 5.66481L5.20809 6.55701C6.21016 8.31581 7.6693 9.77206 9.43159 10.7721L10.3256 9.87994C10.517 9.69106 10.7586 9.56089 11.0218 9.50486C11.2851 9.44883 11.5589 9.46929 11.8108 9.56381C12.4495 9.80164 13.113 9.96662 13.7888 10.0556C14.1308 10.1037 14.4431 10.2756 14.6663 10.5386C14.8895 10.8015 15.0081 11.1372 14.9996 11.4817Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='crirlcebutton'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.25 5.25L12 9L17.25 12.75V5.25Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.5 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V12.75C0.75 13.5784 1.42157 14.25 2.25 14.25H10.5C11.3284 14.25 12 13.5784 12 12.75V5.25C12 4.42157 11.3284 3.75 10.5 3.75Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        } */}
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                            }


                            {/* {timepicker.map((time, timeIndex) => (
                                <tr key={timeIndex}>
                                    <td>{time}</td>
                                    {calendar.map((day, dayIndex) => {
                                        const event = day.timeEvents.find(event => event.time === time);

                                        return (
                                            <td key={dayIndex}>
                                                <div className='maindv'>
                                                    {event?.obj?.map((item, i) => {

                                                        return (



                                                            <div className='celdndartite'
                                                                style={{
                                                                    height: "68px"
                                                                }}
                                                                onMouseEnter={() => addfunc(event?.time, day?.dayNumber, i)}
                                                                onMouseLeave={() => setinfo({
                                                                    time: "",
                                                                    day: "",
                                                                    index: "",
                                                                })}
                                                            >
                                                                <div
                                                                    className='divspacing'

                                                                >
                                                                    <div>
                                                                        <div className='linebreak'
                                                                            style={{
                                                                                width: "auto",
                                                                                fontSize: "14px"
                                                                            }}
                                                                        >{item ? item?.name : ""}</div>

                                                                        <div className='time'>


                                                                             Femate - 25

                                                                        </div>
                                                                    </div>
                                                                    <div className='topmidel'
                                                                        style={{
                                                                            width: "max-content"
                                                                        }}
                                                                    >
                                                                        <div>

                                                                            <div className='middeltext'>Consultation Details</div>
                                                                            <div className='middleshort'>26-June-2024 | 09:30 AM - 05:30 PM</div>

                                                                        </div>

                                                                    </div>

                                                                    <div className='topmidel cutsommodel'

                                                                    >
                                                                        <div>

                                                                            <div className='middeltext'>Status</div>
                                                                            <div className='roundmain'>
                                                                                <div
                                                                                    className='round'
                                                                                ></div>
                                                                                <div className='line'></div>
                                                                                <div
                                                                                    className='round'
                                                                                    style={{
                                                                                        background: "#FC4F00"

                                                                                    }}
                                                                                ></div>
                                                                                <div className='line'></div>
                                                                                <div
                                                                                    className='round'
                                                                                ></div>
                                                                                <div className='line'></div>
                                                                                <div
                                                                                    className='round'
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className='divspacing'
                                                                >
                                                                    <div className='bottomdown'>
                                                                        <div className='sessionstart'>Start Session</div>
                                                                        <div className='crirlcebutton'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                <path d="M14.9996 11.4817V13.5893C15.0004 13.7849 14.9602 13.9786 14.8817 14.1578C14.8031 14.3371 14.6879 14.498 14.5435 14.6303C14.399 14.7626 14.2285 14.8633 14.0428 14.9259C13.8571 14.9886 13.6603 15.0119 13.465 14.9943C11.299 14.7594 9.2183 14.0207 7.39023 12.8376C5.68945 11.7589 4.24748 10.3198 3.16674 8.62243C1.9771 6.78969 1.23677 4.70306 1.00571 2.53156C0.988123 2.33729 1.01126 2.1415 1.07364 1.95664C1.13603 1.77178 1.2363 1.60191 1.36807 1.45785C1.49984 1.31378 1.66022 1.19868 1.839 1.11986C2.01779 1.04105 2.21106 1.00025 2.40651 1.00007H4.51826C4.85987 0.996713 5.19105 1.11744 5.45007 1.33976C5.70909 1.56208 5.87828 1.87081 5.92609 2.20841C6.01522 2.88287 6.18052 3.54511 6.41883 4.18249C6.51354 4.43394 6.53403 4.70721 6.47789 4.96993C6.42175 5.23265 6.29132 5.4738 6.10207 5.66481L5.20809 6.55701C6.21016 8.31581 7.6693 9.77206 9.43159 10.7721L10.3256 9.87994C10.517 9.69106 10.7586 9.56089 11.0218 9.50486C11.2851 9.44883 11.5589 9.46929 11.8108 9.56381C12.4495 9.80164 13.113 9.96662 13.7888 10.0556C14.1308 10.1037 14.4431 10.2756 14.6663 10.5386C14.8895 10.8015 15.0081 11.1372 14.9996 11.4817Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='crirlcebutton'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                <path d="M17.25 5.25L12 9L17.25 12.75V5.25Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M10.5 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V12.75C0.75 13.5784 1.42157 14.25 2.25 14.25H10.5C11.3284 14.25 12 13.5784 12 12.75V5.25C12 4.42157 11.3284 3.75 10.5 3.75Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {
                                                                    day?.dayNumber == info?.day && event?.time == info?.time && i == info?.index &&
                                                                    < div className='openclanderDrawer'>


                                                                        <div className='topsel'>

                                                                            <div className='img-text-section'>
                                                                                <img src={uaertext} />
                                                                                <div>
                                                                                    <div className='titleName'>Dwight Schrutr</div>
                                                                                    <div className='subtext'>Female - 25</div>
                                                                                    <div className='subtext'>+91 9848 012 345</div>
                                                                                </div>

                                                                            </div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                                <rect x="0.583008" y="1.22658" width="23" height="23" rx="5.5" fill="#E9FAFF" />
                                                                                <rect x="0.583008" y="1.22658" width="23" height="23" rx="5.5" stroke="#9FE8FF" />
                                                                                <path d="M16.1199 7.63567V17.8175H14.9268L9.37847 9.82317H9.27904V17.8175H8.04608V7.63567H9.23926L14.8074 15.6499H14.9069V7.63567H16.1199Z" fill="#06C1FD" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='mainmiddle'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                                                                                <path d="M14.5635 1.41531C15.1377 1.0325 15.958 1.44266 15.958 2.12625V9.15359C15.958 9.83719 15.1377 10.2473 14.5635 9.86453L11.583 7.78641V3.49344L14.5635 1.41531ZM9.39551 0.376251C10.1064 0.376251 10.708 0.977814 10.708 1.68875V9.56375C10.708 10.302 10.1064 10.8763 9.39551 10.8763H1.52051C0.782227 10.8763 0.208008 10.302 0.208008 9.56375V1.68875C0.208008 0.977814 0.782227 0.376251 1.52051 0.376251H9.39551ZM8.52051 6.2825V3.24734C8.52051 2.89188 8.21973 2.59109 7.86426 2.59109H4.80176C4.41895 2.59109 4.14551 2.86453 4.14551 3.24734C4.14551 3.60281 4.41895 3.90359 4.80176 3.90359H6.27832L2.58691 7.595C2.31348 7.84109 2.31348 8.25125 2.58691 8.49734C2.69629 8.63406 2.86035 8.68875 3.05176 8.68875C3.21582 8.68875 3.37988 8.63406 3.5166 8.49734L7.20801 4.80594V6.2825C7.20801 6.66531 7.48145 6.93875 7.86426 6.93875C8.21973 6.93875 8.52051 6.66531 8.52051 6.2825Z" fill="#767A85" />
                                                                            </svg>
                                                                            <div className='topmidel'>
                                                                                <div>

                                                                                    <div className='middeltext'>Consultation Details</div>
                                                                                    <div className='middleshort'>26-June-2024</div>
                                                                                    <div className='middleshort'>09:30 AM - 05:30 PM</div>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: "1.2rem"
                                                                                    }}
                                                                                >
                                                                                    <div className='middleshort'>Body Massage</div>
                                                                                    <div className='middeltext'>
                                                                                        <div className='cricle'></div>
                                                                                        Drg. Wade Warren</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='mainmiddle'
                                                                            style={{
                                                                                paddingTop: "0",
                                                                                borderBottom: "1px solid #D1D7E1"
                                                                            }}
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                                                                                <path d="M14.5635 1.41531C15.1377 1.0325 15.958 1.44266 15.958 2.12625V9.15359C15.958 9.83719 15.1377 10.2473 14.5635 9.86453L11.583 7.78641V3.49344L14.5635 1.41531ZM9.39551 0.376251C10.1064 0.376251 10.708 0.977814 10.708 1.68875V9.56375C10.708 10.302 10.1064 10.8763 9.39551 10.8763H1.52051C0.782227 10.8763 0.208008 10.302 0.208008 9.56375V1.68875C0.208008 0.977814 0.782227 0.376251 1.52051 0.376251H9.39551ZM8.52051 6.2825V3.24734C8.52051 2.89188 8.21973 2.59109 7.86426 2.59109H4.80176C4.41895 2.59109 4.14551 2.86453 4.14551 3.24734C4.14551 3.60281 4.41895 3.90359 4.80176 3.90359H6.27832L2.58691 7.595C2.31348 7.84109 2.31348 8.25125 2.58691 8.49734C2.69629 8.63406 2.86035 8.68875 3.05176 8.68875C3.21582 8.68875 3.37988 8.63406 3.5166 8.49734L7.20801 4.80594V6.2825C7.20801 6.66531 7.48145 6.93875 7.86426 6.93875C8.21973 6.93875 8.52051 6.66531 8.52051 6.2825Z" fill="#767A85" />
                                                                            </svg>
                                                                            <div className='topmidel'>
                                                                                <div>

                                                                                    <div className='middeltext'>Status</div>
                                                                                    <div className='roundmain'>
                                                                                        <div
                                                                                            className='round'
                                                                                        ></div>
                                                                                        <div className='line'></div>
                                                                                        <div
                                                                                            className='round'
                                                                                            style={{
                                                                                                background: "#FC4F00"

                                                                                            }}
                                                                                        ></div>
                                                                                        <div className='line'></div>
                                                                                        <div
                                                                                            className='round'
                                                                                        ></div>
                                                                                        <div className='line'></div>
                                                                                        <div
                                                                                            className='round'
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className='bottomdown'>
                                                                            <div className='sessionstart'>Start Session</div>
                                                                            <div className='crirlcebutton'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                    <path d="M14.9996 11.4817V13.5893C15.0004 13.7849 14.9602 13.9786 14.8817 14.1578C14.8031 14.3371 14.6879 14.498 14.5435 14.6303C14.399 14.7626 14.2285 14.8633 14.0428 14.9259C13.8571 14.9886 13.6603 15.0119 13.465 14.9943C11.299 14.7594 9.2183 14.0207 7.39023 12.8376C5.68945 11.7589 4.24748 10.3198 3.16674 8.62243C1.9771 6.78969 1.23677 4.70306 1.00571 2.53156C0.988123 2.33729 1.01126 2.1415 1.07364 1.95664C1.13603 1.77178 1.2363 1.60191 1.36807 1.45785C1.49984 1.31378 1.66022 1.19868 1.839 1.11986C2.01779 1.04105 2.21106 1.00025 2.40651 1.00007H4.51826C4.85987 0.996713 5.19105 1.11744 5.45007 1.33976C5.70909 1.56208 5.87828 1.87081 5.92609 2.20841C6.01522 2.88287 6.18052 3.54511 6.41883 4.18249C6.51354 4.43394 6.53403 4.70721 6.47789 4.96993C6.42175 5.23265 6.29132 5.4738 6.10207 5.66481L5.20809 6.55701C6.21016 8.31581 7.6693 9.77206 9.43159 10.7721L10.3256 9.87994C10.517 9.69106 10.7586 9.56089 11.0218 9.50486C11.2851 9.44883 11.5589 9.46929 11.8108 9.56381C12.4495 9.80164 13.113 9.96662 13.7888 10.0556C14.1308 10.1037 14.4431 10.2756 14.6663 10.5386C14.8895 10.8015 15.0081 11.1372 14.9996 11.4817Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </div>
                                                                            <div className='crirlcebutton'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                    <path d="M17.25 5.25L12 9L17.25 12.75V5.25Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M10.5 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V12.75C0.75 13.5784 1.42157 14.25 2.25 14.25H10.5C11.3284 14.25 12 13.5784 12 12.75V5.25C12 4.42157 11.3284 3.75 10.5 3.75Z" stroke="#1F2026" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>



                                                        )
                                                    })}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))} */}
                        </tbody>

                    </table>

                </div>

            </div>


        </div >
    )
}
