import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss"; import '../../assets/Scsss/Global.scss'
import Drawer from 'react-modern-drawer'
// import './catoriesSummarypage.scss'
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown,
    EachCustomImageUpload,
    EachCustomImageUploadcustom,
    EachCustomSelectMultiple,
    EachInputCustom, EachInputsepfic, MultiDropdownNested, MultiDropdown,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput,
} from "../../components/Custom"; import Editingpage from '../EditingPage/EditingPage'
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";

const Organization = () => {
    const {
        email,
        actionBtns,
        setActionBtns,
        globalSearchQuery,
        dropDowndownvalue, editingToggle, seteditingToggle
    } = useContext(GlobalContext);
    const [checkBoxStage, setCheckBoxStage] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [catoriesSummary, setcatoriesSummary] = useState([]);
    const [programitem, setprogramitem] = useState({

        name: "",
        description: "",

        coverPhoto: "",
        whyprogram: "",
        inlcudeprogram: "",
        price: "",
        peroid: "",
        specialistIds: [
            { name: "", id: "" }
        ],
        theripyIds: [
            { name: "", id: "" }
        ],
        duration: "",
        startprogram: "",
        typelacae: "",
        NumberOfConsultations: ""

    });


    useEffect(() => {
        tablefucntion();
    }, [dropDowndownvalue]);
    const [therpiId, settherpiId] = useState([]);

    const [sepcialdata, setsepcialdata] = useState([]);

    useEffect(() => {
        roondropdowm();
        tablefucntion123()
    }, []);
    const addmorespicalid = (e) => {
        e.stopPropagation();
        if (sepcialdata.filter(function (cv) {
            return !programitem?.specialistIds.find(function (e) {
                return e.id == cv.specialistid;
            })
        })?.length > 0) {

            if (programitem?.specialistIds[programitem?.specialistIds?.length - 1]?.name != "") {
                setprogramitem({ ...programitem, specialistIds: [...programitem?.specialistIds, { id: "", name: "" }] });
                // }
                let pop = sepcialdata.filter(function (cv) {
                    return !programitem?.specialistIds.find(function (e) {
                        return e.id == cv.specialistid;
                    })
                })
                setsepcialdata(pop)


                return

            }




        }
    }

    const addmoreroom = (e) => {
        e.stopPropagation();
        if (therpiId.filter(function (cv) {
            return !programitem?.theripyIds.find(function (e) {
                return e.id == cv.theraphyId;
            })
        })?.length > 0) {
            if (programitem?.theripyIds[programitem?.theripyIds?.length - 1]?.name != "") {
                setprogramitem({ ...programitem, theripyIds: [...programitem?.theripyIds, { id: "", name: "" }] });
                // }

                let pop = therpiId.filter(function (cv) {
                    return !programitem?.theripyIds.find(function (e) {
                        return e.id == cv.theraphyId;
                    })
                })
                console.log("234234234234", pop);
                settherpiId(pop)
                return

            }

        }
    }

    const history = useNavigate()
    const tablefucntion123 = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/gettheraphy?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                settherpiId(res?.data?.data);
            });
    };

    const roondropdowm = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getspecialist?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setsepcialdata(res?.data?.data);

                }
                else {
                    setsepcialdata([]);
                }
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

            });
    };
    const tablefucntion = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getprogram?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                setcatoriesSummary(res?.data?.data);
            });
    };

    const [player, setplayer] = useState([]);

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    const monthNames = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    console.log("cxc66666zxczxc", player);
    const [active, setactive] = useState("Pre Assessment Form");

    const [page, setpage] = useState("main");
    const [getsesion, setgetsesion] = useState({});
    // useEffect(() => {
    //     setpage("second")
    // }, [])

    // const getclientid = (e) => {

    //     axios
    //         .get(`https://uatamma.apimachine.com/api/getconsultcatoriesSummarys?consultationcatoriesSummaryid=${e}`)
    //         .then((res) => {
    //             //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

    //             setgetsesion(res?.data?.data?.[0] || {})
    //             setpage("second")

    //         });
    // }

    const [formName, setFormName] = useState("");
    const [questions, setQuestions] = useState([
        {
            name: "",
            answerType: "",
            isMandatory: null,
        },
    ]);
    const [isOpen, setIsOpen] = useState(null);
    const [formErr, setFormErr] = useState(null);
    const [allSpecialist, setAllSpecialist] = useState([]);
    const [selectedSpecialist, setSelectedSpecialist] = useState(null);


    const handleSubmit = () => {
        let specialistIds = programitem?.specialistIds?.map(item => {
            return item?.id
        })
        let theripyIds = programitem?.theripyIds?.map(item => {
            return item?.id
        })
        axios
            .post(`https://uatamma.apimachine.com/api/addprogram`, {

                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
                "name": programitem?.name,
                "description": programitem?.description,
                "icon": programitem?.website,
                "specialists": [...specialistIds],
                // "locationType": programitem?.typelacae,
                "whytakethis": programitem?.whyprogram,
                "whatwillbedelivered": programitem?.inlcudeprogram,
                "price": programitem?.price,
                "duration": programitem?.duration,

                "NumberOfConsultations": programitem?.NumberOfConsultations,
                "theraphyIds": [...theripyIds]








            })
            .then(({ data }) => {
                if (data.status) {
                    setCheckBoxStage("");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                    setFormErr(null);
                } else {
                    setFormErr(data.message);
                }
            });
    };
    let loc = window.location.pathname
    let finallo = loc?.split('/')
    return (
        <>
            {
                editingToggle ?
                    <Editingpage />
                    :
                    <div className="global-dashboard">
                        <div className="label-box-header">
                            Packages
                        </div>
                        <div className="top-section-golbal">
                            <div className="left-side">


                                <div className="label-box">
                                    <svg
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z" fill="#5A5656" />
                                        <path d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z" fill="#5A5656" />
                                    </svg>

                                    Export

                                </div>



                                <>

                                    <div className="label-box">
                                        <svg
                                            style={{
                                                marginRight: "10px"
                                            }}
                                            xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="#5A5656" />
                                        </svg>
                                        Add Filter
                                    </div></>


                            </div>


                            <div className="right-side">
                                <div className="shortBy">Sort By</div>
                                <div className="dropdownlist">
                                    Newest

                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                        <path d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z" fill="#5A5656" />
                                    </svg>

                                </div>
                                <div className="addLabel"
                                    onClick={() => setCheckBoxStage("add")}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="white" />
                                    </svg>
                                    Add New
                                </div>
                                <Drawer
                                    size={450}
                                    // duration={500}
                                    open={checkBoxStage}
                                    onClose={() => setCheckBoxStage("")}
                                    direction='right'
                                    className='bla bla bla'
                                    overlayColor="#FFF"
                                    overlayOpacity={0}
                                >

                                    {checkBoxStage == "add" && (
                                        <div
                                            className="drowpside-right"

                                        >
                                            {/* <div style={{
                                    position: "absolute",
                                    right: "1rem"
                                }}
                                    className={classNames.closeBtn}
                                    onClick={() => {
                                        setCheckBoxStage("");
                                    }}
                                >
                                    X
                                </div> */}
                                            <div
                                                className={classNames.eachFilterItem}
                                                style={{
                                                    fontWeight: "500",
                                                    pointerEvents: "none",
                                                    paddingLeft: "0",
                                                    marginBottom: "2rem",


                                                }}
                                            >
                                                Add Packages
                                            </div>
                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachInputsepfic
                                                    title="What is the name of the package?"
                                                    name="name"
                                                    placeholder="Name..."
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />
                                            </div>
                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachCustomTextarea
                                                    title="Describe the package"
                                                    name="description"
                                                    placeholder="Description..."
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />

                                            </div>

                                            <div
                                                style={{
                                                    fontSize: "0.9rem",
                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                Upload package icon
                                            </div>
                                            <div
                                                style={{
                                                    width: "200px"
                                                }}
                                            >

                                                <EachCustomImageUpload
                                                    title="Upload package icon"
                                                    name="coverPhoto"
                                                    placeholder="Upload"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                //  topTitle="true"
                                                />
                                            </div>


                                            <div
                                                style={{
                                                    marginTop: "2rem",
                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachCustomTextarea
                                                    title="Why take this package"
                                                    name="whyprogram"
                                                    placeholder=""
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />

                                            </div>


                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachCustomTextarea
                                                    title="What will be included in this package"
                                                    name="inlcudeprogram"
                                                    placeholder=""
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />

                                            </div>


                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >

                                                <EachInputCustom
                                                    title="What is the price of this package"
                                                    name="price"

                                                    label="INR"
                                                    placeholder="0.00"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"

                                                />
                                                {/* <EachInput
                                                    title="What is the price of this package"
                                                    name="price"
                                                    placeholder="0.00"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                /> */}
                                            </div>

                                            <div
                                                style={{

                                                    marginBottom: "1rem",
                                                    position: "relative",
                                                }}
                                            >
                                                <EachInput
                                                    title="What is the duration of the therapy?"
                                                    name="duration"
                                                    placeholder="0.00"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />

                                                <div
                                                    style={{

                                                        position: "absolute",
                                                        right: "06px",
                                                        top: "38px",
                                                        width: "120px"
                                                    }}
                                                >
                                                    <EachCustomDropdown
                                                        dropdown={["Minimum Days", "Maximum Days", "Exact Days"]}
                                                        name="peroid"
                                                        title="Period title"
                                                        stateValue={programitem}
                                                        setState={setprogramitem}
                                                    // topTitle="true"
                                                    />
                                                </div>
                                            </div>


                                            {/* <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachInput
                                                    title="What time does this package start?"
                                                    name="startprogram"
                                                    placeholder="00:00"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />
                                            </div> */}


                                            {/* <div
                                                style={{

                                                    marginBottom: "2rem"
                                                }}
                                            >
                                                <EachInput
                                                    title="What type of place "
                                                    name="typelacae"
                                                    placeholder="00:00"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />
                                            </div> */}
                                            <div
                                                style={{
                                                    marginBottom: "0rem"
                                                }}
                                            >

                                                {
                                                    programitem?.specialistIds?.map((item, i) => {
                                                        return (
                                                            <MultiDropdownNested
                                                                dropdown={sepcialdata}
                                                                key={i}
                                                                indexx={i}
                                                                name="specialistIds"
                                                                title="Which specialists will participate in this package ?"
                                                                stateValue={programitem}
                                                                setState={setprogramitem}
                                                                // stateValue={services}
                                                                // setState={setservices}
                                                                topTitle="true"
                                                            />

                                                        )
                                                    })
                                                }

                                            </div>             <div
                                                style={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                    textAlign: "left",
                                                    textDecoration: "underline",
                                                    marginBottom: "2rem"
                                                }}

                                                onClick={addmorespicalid}
                                            >Add another specialist</div>






                                            <div
                                                style={{
                                                    marginBottom: "0rem"
                                                }}
                                            >

                                                {
                                                    programitem?.theripyIds?.map((item, i) => {
                                                        return (
                                                            <MultiDropdown
                                                                dropdown={therpiId}
                                                                key={i}
                                                                indexx={i}
                                                                name="theripyIds"
                                                                title="Which therapies will be included"
                                                                stateValue={programitem}
                                                                setState={setprogramitem}
                                                                // stateValue={services}
                                                                // setState={setservices}
                                                                topTitle="true"
                                                            />

                                                        )
                                                    })
                                                }

                                            </div>



                                            <div
                                                style={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                    textAlign: "left",
                                                    textDecoration: "underline",
                                                    marginBottom: "1rem"
                                                }}

                                                onClick={addmoreroom}
                                            >Add another therapy</div>





                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachInput
                                                    title="How many consultations are included?"
                                                    name="NumberOfConsultations"
                                                    placeholder="0"
                                                    stateValue={programitem}
                                                    setState={setprogramitem}
                                                    topTitle="true"
                                                />
                                            </div>






                                            <button
                                                className={classNames.submitBtn
                                                }
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                                        </div>
                                    )}
                                </Drawer>
                            </div>
                        </div>


                        <div className="handle-table">
                            <div className="g-table-sec">
                                <div className="table-section">
                                    <table style={{ minWidth: "100%" }}>
                                        <thead>
                                            <tr
                                                style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                                            >

                                                <th>
                                                    Name <SortArrows />
                                                </th>

                                                <th>
                                                    Duration (Days)
                                                    <SortArrows />
                                                </th>
                                                <th>
                                                    Cost (INR)
                                                    <SortArrows />
                                                </th>

                                                <th>
                                                    Description
                                                    <SortArrows />
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {catoriesSummary?.map((row, index) => {
                                                let date = row?.createdAt?.split("-");
                                                let finial = row?.createdAt?.split("-")?.[2]?.split("T");
                                                return (
                                                    <tr
                                                        key={row?.brokerage_name + index}
                                                        style={{
                                                            gridTemplateColumns: "min-content repeat(4, 1fr)",
                                                            pointerEvents: sidebarOpen ? "none" : "",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => history(`/owner/packages/${row?.programid}`)}

                                                    >

                                                        <td>


                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >

                                                                <div>
                                                                    <div>
                                                                        {row?.name}
                                                                        {/* <CopyBtn copyText={row?.name} /> */}
                                                                    </div>
                                                                    {/* <div>
                                                                {row?.consultationcatoriesSummaryid}
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        </td>


                                                        <td

                                                        >



                                                            {row?.duration}




                                                        </td>


                                                        <td>

                                                            {row?.price}




                                                        </td>

                                                        <td>

                                                            {row?.description}




                                                        </td>


                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>



            }












        </>
    );
};

export default Organization;
