import React, { useEffect, useState } from 'react'
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown, NewUIMyEachInput, MYEachCustomImageUpload, StaffEachInputDatePicker, NewDateAndPicker,
    EachCustomImageUpload, NewEachCustomDropdown,
    NewEachCustomImageUploadcustom,
    EachCustomSelectMultiple, NewMyEachCustomTextarea,
    EachInputCustom, EachInputsepfic, MyEachCustomImageUpload,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput, RadioNewUIMyEachInput,
} from "../../components/Custom";
import './CreateNew.scss'
import logobilling from '../../assets/logobilling.svg'
import axios from 'axios';
import { useDetectClickOutside } from "react-detect-click-outside";
export default function CreateNew({ setpagelist }) {
    const [selectedDate, setSelectedDate] = useState('');
    const [tableDroptoggle, settableDroptoggle] = useState(false);
    const [dropid, setdropid] = useState('');
    const [qtyid, setqtyid] = useState('');
    const [qyttoggle, setqyttoggle] = useState('');
    const [search, setsearch] = useState({
        name: ""
    })
    const qytdata = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [tableItem, settableItem] = useState([])
    const [totalprice, settotalprice] = useState(0)


    const [caluclationDetail, setcaluclationDetail] = useState([
        {
            serviceName: "",
            bookingdate: "",
            type: "",
            price: "",
            qty: "",
            finalPrice: "",
            bookingid: "",
        },

    ])
    useEffect(() => {
        settotalprice(caluclationDetail.reduce((total, booking) => total + parseFloat(booking?.finalPrice ? booking?.finalPrice : 0), 0))
    }, [caluclationDetail, qtyid])
    console.log("caluclationDetail", caluclationDetail)
    const calldrop = (i) => {
        setdropid(i)
        settableDroptoggle(true)
    }

    const qtycalldrop = (i) => {
        setqtyid(i)
        setqyttoggle(true)
    }
    const qtyupdateBookingDate = (item, i) => {

        setcaluclationDetail(prevDetails =>
            prevDetails.map((detail, index) =>
                index === i ? {
                    ...detail,
                    qty: item,
                    finalPrice: Number(detail?.price) * Number(item),
                } : detail
            )
        );
        setqtyid("")
        setqyttoggle(false)
    };
    const updateBookingDate = (item, i) => {
        setcaluclationDetail(prevDetails =>
            prevDetails.map((detail, index) =>
                index === i ? {
                    ...detail,

                    serviceName: item?.type == "consultationservice" ? item?.servicedetails?.[0]?.name : item?.theraphyDetails?.[0]?.name,

                    bookingdate: item?.dateOfBooking,
                    type: item?.type,
                    price: item?.type == "consultationservice" ? item?.servicedetails?.[0]?.cost : item?.theraphyDetails?.[0]?.cost,
                    qty: 1,
                    bookingid: item?.bookingId,
                    finalPrice: Number(item?.type == "consultationservice" ? item?.servicedetails?.[0]?.cost : item?.theraphyDetails?.[0]?.cost) * 1,
                } : detail
            )
        );
        setdropid("")
        settableDroptoggle(false)
    };

    const addNewObject = () => {
        const newObject = {
            serviceName: "",
            bookingdate: "",
            type: "",
            price: "",
            qty: "",
            finalPrice: "",
            bookingid: "",
        };

        setcaluclationDetail(prevDetails => [...prevDetails, newObject]);
    };
    const [patientlist, setpatientlist] = useState({
        name: "",
        YearOfBirth: "",
        Country: "",
        Address: "",
        email: "",
        Gender: "",
        PhoneNumber: "",
        invoiceid: "",
    })


    const [searchstore, setsearchstore] = useState([])
    const [close, setclose] = useState(false)
    const [storeItemData, setstoreItemData] = useState({})
    const [userid, setuserid] = useState("")
    console.log("caluclationDetail", caluclationDetail)
    useEffect(() => {
        searchfunction()


    }, [])
    const handle = (e) => {
        console.log("xzxczxczxczxc")
        setsearch({
            name: ""
        })
        setclose(false)
        // setsearch({
        //     name: e?.name
        // })
        setpatientlist({
            name: e?.name,
            YearOfBirth: (new Date().getFullYear()) - Number(e?.YearOfBirth),
            Country: e?.Country,
            Address: e?.Address,
            Gender: e?.Gender,
            email: e?.email,
            PhoneNumber: e?.PhoneNumber,
            invoiceid: ""
        })
        console.log("ezxczx", patientlist)
        setcaluclationDetail([
            {
                serviceName: "",
                bookingdate: "",
                type: "",
                price: "",
                qty: "",
                finalPrice: "",
            },])
        setuserid(e?.userid)
        TableDeatilfunction(e?.userid)
    }
    console.log("search", close)
    const closeLists = () => {
        setclose(false)
    };
    useEffect(() => {
        if (search?.name?.length > 0) {
            setclose(true)
        }
        else {
            setclose(false)
        }
    }, [search?.name])
    const refsearch = useDetectClickOutside({
        onTriggered: closeLists,
        allowAnyKey: false,
    });
    const searchfunction = () => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getallclientsforteam/${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setsearchstore(res?.data?.data);
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setsearchstore([]);
                }
                //
            });
    };
    const TableDeatilfunction = (e) => {

        axios

            .get(
                `https://uatamma.apimachine.com/api/getbooking?clientId=${e}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    settableItem(res?.data?.data);

                }
                else {
                    settableItem([]);

                }

            });
    };
    const [inoviceid, setinoviceid] = useState("")

    const removeSecondIndex = (e) => {
        if (caluclationDetail?.length > 1) {
            const updatedBookings = caluclationDetail?.filter((_, index) => index !== e);
            setcaluclationDetail(updatedBookings);
        }

    };
    const handleSubmit = () => {
        let booking = caluclationDetail?.map(item => {
            return item?.bookingid
        })
        axios
            .post(`https://uatamma.apimachine.com/api/addinvoice`, {
                "bookingids": [...booking
                ],
                "clientid": userid,
                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
                "totalamount": totalprice


            })
            .then(({ data }) => {
                if (data.status) {

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                } else {

                }
            });
    };


    return (
        <>
            <div className="top-nav-list"
                style={{
                    justifyContent: "end",
                    gap: "1.3rem"
                }}
            >
                <div className="short-drop-down-orange"
                    style={{
                        background: "#19213D"

                    }}
                    onClick={handleSubmit}
                >Submit


                </div>

                <div className="short-drop-down-orange"
                    onClick={() => setpagelist("main")}
                >Go Back


                </div>
            </div>

            <div className='createbilling'>
                <div>
                    <div className='topdec-cleintcreate'>

                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <div className='invocie-Name'>INVOICE</div>
                            {/* <div className='invocie-id'>Invoice ID: <span>      <input
                                value={inoviceid}

                                style={{
                                    height: "23px",
                                    width: "7rem",

                                    marginLeft: "6px"

                                }}
                                placeholder="" className='priceinput'
                                onChange={(e) => setinoviceid(e.target.value)}
                            /></span></div> */}
                            <div className='searchByName'>Search by Client ID/Name</div>
                            <div className="Innerinnputsection"
                                style={{
                                    marginTop: "0px"
                                }}
                            >

                                <div className='subdivision'
                                    style={{
                                        position: "relative"
                                    }}
                                >
                                    <NewUIMyEachInput
                                        title="Client ID"
                                        placeholder="Enter Client ID"
                                        // stateValue={patientlist?.name}
                                        name="name"
                                        stateValue={search}
                                        setState={setsearch}
                                        topTitle="true"
                                    />
                                    {
                                        search?.name?.length > 0 &&

                                        <div className='showthedropdown'
                                            ref={refsearch}
                                        >
                                            {
                                                searchstore?.filter((temp) =>
                                                    temp?.UserDetails?.[0]?.UserDetails?.[0]?.name
                                                        ?.toLowerCase()
                                                        ?.includes(search?.name?.toLowerCase())
                                                )?.map(item => {
                                                    return (
                                                        <>
                                                            <div className="listitemNamedrop"
                                                                onClick={() => handle(item?.UserDetails?.[0]?.UserDetails?.[0])}
                                                            >{item?.UserDetails?.[0]?.UserDetails?.[0]?.name}</div>
                                                        </>
                                                    )
                                                })
                                            }

                                        </div>
                                    }
                                </div>
                                {/* <div className='subdivision'>
                                    <div className='serchbuttom'>
                                        Search
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                                            <path d="M16.642 15.0414C16.9545 15.3955 16.9649 15.7497 16.6732 16.1039C16.5274 16.2497 16.3503 16.3226 16.142 16.3226C15.9545 16.3226 15.767 16.2497 15.5795 16.1039L11.392 11.9164C10.267 12.833 8.92322 13.3018 7.36072 13.3226C5.52738 13.2809 4.00655 12.6455 2.79822 11.4164C1.56905 10.1872 0.933634 8.65593 0.891968 6.8226C0.933634 4.98927 1.55863 3.45802 2.76697 2.22885C3.99613 0.999685 5.52738 0.364268 7.36072 0.322601C9.19405 0.364268 10.7253 0.999685 11.9545 2.22885C13.1836 3.45802 13.8191 4.98927 13.8607 6.8226C13.8399 8.36427 13.3711 9.70802 12.4545 10.8539L16.642 15.0414ZM2.39197 6.8226C2.43363 8.23927 2.92322 9.41635 3.86072 10.3539C4.79822 11.2914 5.9753 11.7809 7.39197 11.8226C8.80863 11.7809 9.98572 11.2914 10.9232 10.3539C11.8607 9.41635 12.3503 8.23927 12.392 6.8226C12.3503 5.40593 11.8607 4.22885 10.9232 3.29135C9.98572 2.35385 8.80863 1.86427 7.39197 1.8226C5.9753 1.86427 4.79822 2.35385 3.86072 3.29135C2.92322 4.22885 2.43363 5.40593 2.39197 6.8226Z" fill="white" />
                                        </svg>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        <div>
                            <img src={logobilling}
                                style={{
                                    marginBottom: "10px"
                                }}
                            />
                            <div className='addressdecrbtion'>VSR Nature Cure Center</div>
                            <div className='deatiltextaddress'>(612) 856 - 0989</div>
                            <div className='deatiltextaddress'>contact@maurosicard.com</div>
                            <div className='deatiltextaddress'>Pablo Alto, San Francisco, CA 92102, United States of America</div>
                        </div>
                    </div>


                    <div className='textSectionTop'>
                        <div className='infotext'>
                            Personal Information:
                        </div>
                        {/* <div
            className='editlabel'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M8.84006 2.9L3.36673 8.69333C3.16006 8.91333 2.96006 9.34667 2.92006 9.64667L2.6734 11.8067C2.58673 12.5867 3.14673 13.12 3.92006 12.9867L6.06673 12.62C6.36673 12.5667 6.78673 12.3467 6.9934 12.12L12.4667 6.32667C13.4134 5.32667 13.8401 4.18667 12.3667 2.79334C10.9001 1.41334 9.78673 1.9 8.84006 2.9Z" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.92676 3.86664C8.21342 5.70664 9.70676 7.1133 11.5601 7.29997" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.1667H14" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Edit</div> */}
                    </div>
                    <div className="Innerinnputsection">

                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="First name"
                                placeholder="First Name"
                                // stateValue={patientlist?.name}
                                name="name"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true" toggle={true}
                            />
                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Phone Number"
                                placeholder="Phone Number"
                                // stateValue={patientlist?.name}
                                name="PhoneNumber"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true" toggle={true}
                            />
                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Email Address"
                                placeholder="Email Address"
                                // stateValue={patientlist?.name}
                                name="email"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true"
                                toggle={true}
                            />
                            {/* <NewDateAndPicker
                            title="Date of birth"
                            placeholder="DD/MM/YYYY"
                            stateValue={patientlist}
                            setState={setpatientlist}
                            name="YearOfBirth"
                            topTitle="true"
                        /> */}
                        </div>
                    </div>

                    <div className="Innerinnputsection">
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Age"
                                // stateValue={patientlist?.name}
                                name="YearOfBirth"
                                placeholder="Age"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true"
                                toggle={true}
                            />
                        </div>

                        <div className='subdivision'>

                            <NewUIMyEachInput
                                title="Gender"
                                // stateValue={patientlist?.name}
                                name="Gender"
                                placeholder="Gender"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true"
                                toggle={true}
                            />

                            {/* <NewEachCustomDropdown
                            dropdown={[
                                "Male",
                                "Female",

                            ]}
                            name="gender"
                            title="Gender"
                            placeholder="Select Gender"
                            Select Gender
                            stateValue={patientlist}
                            setState={setpatientlist}
                            topTitle="true"
                            type="single"
                        /> */}


                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Country"
                                // stateValue={patientlist?.name}
                                placeholder="Country"
                                name="Country"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true"
                                toggle={true}
                            />
                        </div>


                    </div>


                    <div className="Innerinnputsection">
                        <div className='subdivision'
                            style={{
                                width: "100%"
                            }}
                        >

                            <NewMyEachCustomTextarea
                                title="Address"
                                // stateValue={patientlist?.name}
                                name="Address"
                                placeholder="Enter Address"
                                stateValue={patientlist}
                                setState={setpatientlist}
                                topTitle="true"
                            />


                        </div>
                    </div>

                    <div className='tablesierigt'>
                        <div class="containerpage">
                            <div class="tWrap">
                                <div class="tWrap__head">
                                    <table>
                                        <thead>
                                            <tr>

                                                <th>Service Name</th>
                                                <th>Service Date</th>
                                                <th>Service Type</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="tWrap__body"
                                    style={{
                                        height: "100%"
                                    }}
                                >
                                    <table>
                                        <tbody>
                                            {
                                                caluclationDetail?.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <td

                                                            >
                                                                <div className='dropdown-createbill-main'>
                                                                    <div className='dropdown-createbill'
                                                                        onClick={() => calldrop(i)}
                                                                    >
                                                                        {item?.serviceName ? item?.serviceName : "Select"}
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M19.92 8.95001L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.07996 8.95001" stroke="#3D4754" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                    {
                                                                        tableDroptoggle && dropid == i &&
                                                                        <div className='dropdownlist'>
                                                                            {
                                                                                tableItem?.map(item => {
                                                                                    return (
                                                                                        <div className='tableDropdownItems'
                                                                                            onClick={() => updateBookingDate(item, i)}
                                                                                        >
                                                                                            <div className='leftsidetable'>
                                                                                                <div className='NameTable'>{item?.type == "consultationservice" ? item?.servicedetails?.[0]?.name : item?.theraphyDetails?.[0]?.name}</div>
                                                                                                <div className='SubNameTable'>{item?.program == "true" ? "Package" : "Ad-hoc"}</div>
                                                                                            </div>

                                                                                            <div className='leftsidetable'>
                                                                                                <div className='NameTable'
                                                                                                    style={{
                                                                                                        textAlign: "right"
                                                                                                    }}
                                                                                                >{item?.dateOfBooking}</div>
                                                                                                <div className='SubNameTable'
                                                                                                    style={{
                                                                                                        textAlign: "right"
                                                                                                    }}
                                                                                                >{item?.type}</div>

                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>

                                                            </td>
                                                            <td>

                                                                <input
                                                                    style={{
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    placeholder={item?.bookingdate} className='priceinput' />


                                                            </td>
                                                            <td>
                                                                <input
                                                                    style={{
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    placeholder={item?.type} className='priceinput' />

                                                            </td>

                                                            <td>
                                                                <input
                                                                    style={{
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    placeholder={item?.price} className='priceinput' />

                                                            </td>

                                                            <td

                                                            >
                                                                <input
                                                                    style={{
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    placeholder={item?.qty} className='priceinput' />
                                                                {/* <div className='dropdown-createbill-main'>
                                                                    <div className='dropdown-createbill'
                                                                        onClick={() => qtycalldrop(i)}
                                                                    >
                                                                        {item?.qty ? item?.qty : 1}
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M19.92 8.95001L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.07996 8.95001" stroke="#3D4754" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                    {
                                                                        qyttoggle && qtyid == i &&
                                                                        <div className='dropdownlist'>
                                                                            {
                                                                                qytdata?.map(item => {
                                                                                    return (
                                                                                        <div className='tableDropdownItems'
                                                                                            onClick={() => qtyupdateBookingDate(item, i)}
                                                                                        >
                                                                                            <div className='leftsidetable'
                                                                                                style={{
                                                                                                    width: "100%"
                                                                                                }}
                                                                                            >
                                                                                                <div className='NameTable'>{item}</div>

                                                                                            </div>


                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div> */}

                                                            </td>


                                                            <td>
                                                                <input
                                                                    style={{
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    placeholder={item?.finalPrice} className='priceinput' />

                                                            </td>

                                                            <td>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "start",
                                                                    gap: "1rem"
                                                                }}>


                                                                    <svg
                                                                        onClick={() => removeSecondIndex(i)}
                                                                        className='addsvg'
                                                                        xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                                        <rect x="0.5" y="0.5" width="47" height="47" rx="7.5" fill="#FFF7F3" />
                                                                        <rect x="0.5" y="0.5" width="47" height="47" rx="7.5" stroke="#FC4F00" />
                                                                        <path d="M19.7 25.2V22.41H31.49V25.2H19.7Z" fill="#FC4F00" />
                                                                    </svg>

                                                                    <svg
                                                                        onClick={addNewObject}
                                                                        className='addsvg'
                                                                        xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                                        <rect x="0.5" y="0.5" width="47" height="47" rx="7.5" fill="#FFF7F3" />
                                                                        <rect x="0.5" y="0.5" width="47" height="47" rx="7.5" stroke="#FC4F00" />
                                                                        <path d="M30.5 24C30.4583 24.4583 30.2083 24.7083 29.75 24.75H24.75V29.75C24.7083 30.2083 24.4583 30.4583 24 30.5C23.5417 30.4583 23.2917 30.2083 23.25 29.75V24.75H18.25C17.7917 24.7083 17.5417 24.4583 17.5 24C17.5417 23.5417 17.7917 23.2917 18.25 23.25H23.25V18.25C23.2917 17.7917 23.5417 17.5417 24 17.5C24.4583 17.5417 24.7083 17.7917 24.75 18.25V23.25H29.75C30.2083 23.2917 30.4583 23.5417 30.5 24Z" fill="#FC4F00" />
                                                                    </svg>
                                                                </div>



                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }



                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='bottomtestbilling'>
                                <div>
                                    <div className='mediciantitle'
                                        style={{
                                            marginBottom: "0.7rem",
                                            color: "#202020",
                                            fontWeight: "600"
                                        }}
                                    >Terms & Conditions:

                                    </div>
                                    <div
                                        className='tearmtext'
                                    >Fees and payment terms will be established in the contract or agreement prior to the commencement of the project. An initial deposit will be required before any design work begins. We reserve the right to suspend or halt work in the event of non-payment.</div>
                                </div>
                                <div className='textdivrightside'>
                                    <div className='billingTextresult'>
                                        <div className='NameTextsublist'>Subtotal </div>
                                        <div className='finaltext '>{totalprice}</div>
                                    </div>
                                    <div className='billingTextresult'>
                                        <div className='NameTextsublist'>Discount <span>(Special Offer)</span> </div>
                                        <div className='finaltext'>0.00</div>
                                    </div>
                                    <div className='billingTextresult'>
                                        <div className='NameTextsublist'>TAX: </div>
                                        <div className='finaltext'>0.00</div>
                                    </div>

                                    <div className='billingTextresult'
                                        style={{
                                            background: "#F6F8FC",
                                            height: "32px"

                                        }}
                                    >
                                        <div className='NameTextsublist'>Invoice total</div>
                                        <div className='finaltext'>{totalprice}</div>
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
