import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Consultation.module.scss";
import "./Custom.scss";
import Drawer from "react-modern-drawer";
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";
import {
  CopyBtn,
  CustomCheckbox,
  EachCustomDropdown,
  EachCustomImageUpload,
  EachCustomImageUploadcustom,
  EachCustomSelectMultiple,
  EachInputCustom,
  EachCustomTextarea,
  OwnEachCustomDropdown,
  EachInput,
} from "../../components/Custom";
import { IoMdArrowDropdown } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";

import Editingpage from "../EditingPage/EditingPage";
const Organization = () => {
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    dropDowndownvalue,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [consultant, setconsultant] = useState([]);
  const [consulation, setconsulation] = useState({
    constultandName: [],
    constultandid: [],
    locationid: "",
    locationName: "",
    spaceid: "",
    spaceName: "",
    name: "",
    description: "",
    coverPhoto: "",
    duration: "",

    cost: "",
  });
  console.log("0000", consulation);

  const [specialistlistitem, setspecialistlistitem] = useState("");

  const removeMe = (index) => {
    const temp = [...consulation?.constultandName];
    temp.splice(index, 1);
    setconsulation({
      constultandName: temp,
    });
  };

  useEffect(() => {
    tablefucntion();
    specialistfunction();
    locationfucntion();
    playerfunctiom();
  }, [dropDowndownvalue]);
  const [locationdata, setlocationdata] = useState({});

  const tablefucntion = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getspecialist?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        setconsultant(res?.data?.data);
      });
  };
  const locationfucntion = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res?.data.success) {
          setlocationdata(res?.data?.data);
        } else {
          setlocationdata([]);
        }
      });
  };
  const [player, setplayer] = useState([]);
  const playerfunctiom = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getspecialist?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}&&notSpecialists=true`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res?.data.success) {
          setplayer(res?.data?.data);
        } else {
          setplayer([]);
        }
      });
  };

  const specialistfunction = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getconsultationservice?teamusername${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data.success) {
          setspecialistlistitem(res?.data?.data);
        } else {
          setspecialistlistitem([]);
        }
        //
      });
  };

  const submitservicessFucntion = () => {
    let pop = consulation?.constultandName?.map((item) => {
      return item?.id;
    });
    console.log("PoP", pop);

    axios
      .post("https://uatamma.apimachine.com/api/addconsultationservice", {
        teamplayerid: consulation?.spaceid,
        locationid: consulation?.locationid,
        serviceids: pop,
      })
      .then(({ data }) => {
        if (data.status) {
          toastify("Added succesfully!", "success");
          setCheckBoxStage("");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toastify(data?.message, "error");
        }
      });
  };
  let loc = window.location.pathname;
  let finallo = loc?.split("/");

  console.log("cxc66666zxczxc", player);
  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow");

    localStorage?.setItem("drawershow", Number(addlocal + 1));
  };
  useEffect(() => {
    seteditingToggle(false)
  }, [])
  const history = useNavigate()
  return (
    <>
      {editingToggle ? (
        <Editingpage />
      ) : (
        <div className="global-dashboard">
          <div className="label-box-header" onClick={localclikc}>
            {finallo?.[3]}
          </div>
          <div className="top-section-golbal">
            <div className="left-side">
              <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#5A5656"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#5A5656"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#5A5656"
                    />
                  </svg>
                  Add Filter
                </div>
              </>
            </div>

            <div className="right-side">
              <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div>
              {/* <div className="addLabel" onClick={() => setCheckBoxStage("add")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Add New
              </div> */}

              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                {checkBoxStage == "add" && (
                  <div className="drowpside-right">
                    {/* <div
                                        className={classNames.closeBtn}
                                        onClick={() => {

                                            setCheckBoxStage("");
                                        }}
                                    >
                                        X
                                    </div> */}
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        marginBottom: "2rem",
                      }}
                    >
                      Add Specialist
                    </div>

                    <div className={classNames.formFields}>
                      <div
                        style={{
                          marginBottom: "2rem",
                        }}
                      >
                        <EachCustomDropdown
                          dropdown={player}
                          name="spaceName"
                          title="Which staff member do you want to make a specialist?"
                          stateValue={consulation}
                          setState={setconsulation}
                          topTitle="true"
                        />
                      </div>

                      <div
                        style={{
                          marginBottom: "2rem",
                        }}
                      >
                        <EachCustomDropdown
                          dropdown={locationdata}
                          name="locationName"
                          title="Which location will this specialist work at?"
                          stateValue={consulation}
                          setState={setconsulation}
                          topTitle="true"
                        />
                      </div>
                      {consulation?.constultandName?.length > 0 && (
                        <>
                          {consulation?.constultandName?.map((item, i) => {
                            return (
                              <div className="selectdconsutantdrop">
                                <div className="flexbox">
                                  <img src={item?.icon} />

                                  <div className="title">{item?.name}</div>
                                </div>
                                <svg
                                  onClick={() => removeMe(i)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                >
                                  <path
                                    d="M5.9065 5L9.81208 1.09441C10.0626 0.843956 10.0627 0.438428 9.8121 0.18792C9.56161 -0.0626075 9.15612 -0.0626661 8.90559 0.187901L5 4.09349L1.09441 0.18792C0.843959 -0.062588 0.438411 -0.0626661 0.187903 0.187881C-0.0626246 0.438389 -0.0626441 0.843859 0.187903 1.09439L4.09346 5L0.187884 8.90557C-0.0620582 9.15551 -0.0620582 9.56217 0.187884 9.81208C0.438372 10.0626 0.843881 10.0626 1.09439 9.81208L4.99996 5.90646L8.90553 9.81204C9.15598 10.0626 9.56153 10.0626 9.81202 9.81206C10.0625 9.56157 10.0626 9.15608 9.81204 8.90551L5.9065 5Z"
                                    fill="#C57E50"
                                  />
                                  <path
                                    d="M5.90651 5L9.81208 1.09441C10.0626 0.843956 10.0627 0.438428 9.8121 0.18792C9.56161 -0.0626075 9.15612 -0.0626661 8.90559 0.187901L5 4.09347V5.90652L8.90557 9.8121C9.15602 10.0626 9.56157 10.0626 9.81206 9.81211C10.0626 9.56163 10.0626 9.15614 9.81208 8.90557L5.90651 5Z"
                                    fill="#C57E50"
                                  />
                                </svg>
                              </div>
                            );
                          })}
                        </>
                      )}

                      <OwnEachCustomDropdown
                        dropdown={specialistlistitem}
                        name="constultandName"
                        title="Add services for this specialist"
                        stateValue={consulation}
                        setState={setconsulation}
                        topTitle="true"
                      />
                      <button
                        className={classNames.submitBtn}
                        onClick={submitservicessFucntion}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </Drawer>
            </div>
          </div>

          <div className="handle-table">
            <div className="g-table-sec">
              <div className="table-section">
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        gridTemplateColumns: "min-content repeat(4, 1fr)",
                      }}
                    >
                      <th>
                        Name <SortArrows />
                      </th>
                      <th>
                        Type
                        <SortArrows />
                      </th>
                      <th>
                        Locations
                        <SortArrows />
                      </th>
                      <th>
                        Services
                        <SortArrows />
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {consultant?.map((row, index) => (

                      <tr
                        key={row?.brokerage_name + index}
                        style={{
                          gridTemplateColumns: "min-content repeat(4, 1fr)",
                          pointerEvents: sidebarOpen ? "none" : "",
                        }}
                        onClick={async () => {
                          await history(`/owner/staffing/specialists/${row?.specialistid}`)

                        }}
                      >
                        <td>
                          {row?.TeamDetails?.map((such) => {
                            console.log("czxczxczxc", row)
                            return such?.PlayerDetails?.map((item) => {
                              return (
                                <>
                                  {/* <img
                                    className="icon"
                                    style={{
                                      width: "26px",
                                      height: "26px",
                                      borderRadius: "10rem",
                                      marginRight: "12px",
                                    }}
                                    src={item?.ProfilePic}
                                  /> */}

                                  {item?.name}
                                </>
                              );
                            });
                          })}
                        </td>
                        <td>

                          <div
                            style={{
                              width: "120px", borderRadius: "5px",
                              height: "39px", fontWeight: "700", display: "flex", alignItems: "center", justifyContent: "center",
                              color: "#fff", textTransform: "capitalize",
                              background: row?.type?.toLowerCase() == "therapist" ? "#F2C94C" : row?.type?.toLowerCase() == "consultant" && "#2F80ED" 
                            }}
                          >

                            {row?.type}


                          </div>
                        </td>
                        <td>{row?.locationdetails?.[0]?.name}</td>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          {row?.servicedetails.map((item) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "21px",
                                }}
                              >
                                {item?.name}
                              </div>
                            );
                          })}
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Organization;
