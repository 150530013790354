import React, { useState } from 'react'
import './Summary.scss'
import logosvg from '../../../assets/logosvg.svg'
import Dite from '../Preception/Customj'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CompositionData from '../Preception/CompositionData';
export default function Summary({ summeryDetail, summyTable, summy, agesummy }) {
    const { type } = useParams()
    const [listseleted, setlistseleted] = useState("Medicine")
    console.log("summyTable", summyTable)

    const tablelist = () => {
        switch (listseleted) {

            case "Composition Medicine":
                return (
                    <>
                        <CompositionData
                            listseleted={listseleted}
                            summyTable={summyTable}
                        />
                    </>
                )
            case "Diet Plans":
                return (
                    <>
                        <Dite
                            listseleted={listseleted}
                            summyTable={summyTable}
                        />
                    </>
                )
            case "Lifestyle Advice":
                return (
                    <>       <div class="containerpage">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>

                                            <th> Name</th>
                                            <th>Type</th>
                                            <th>Description</th>



                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        {
                                            summyTable?.map((item, i) => {
                                                return (

                                                    <>

                                                        {
                                                            item?.lifestyleList?.map((such, z) => {
                                                                return (
                                                                    <tr>

                                                                        <td
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "700",
                                                                                marginBottom: "5px"
                                                                            }}
                                                                        >

                                                                            {such?.name}

                                                                        </td>

                                                                        <td>     ---

                                                                        </td>

                                                                        <td>   {such?.description}

                                                                        </td>


                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>







                                                )
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    </>
                )
            case "Investigations":
                return (
                    <>       <div class="containerpage">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>

                                            <th> Name</th>
                                            <th>Lab</th>
                                            <th>Description</th>



                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        {
                                            summyTable?.map((item, i) => {
                                                console.log(item?.notes, "adsasssssssssssss")
                                                return (

                                                    <>

                                                        {
                                                            item?.investigationList?.map((such, z) => {
                                                                return (
                                                                    <tr>

                                                                        <td
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "700",
                                                                                marginBottom: "5px"
                                                                            }}>


                                                                            {such?.name}
                                                                        </td>

                                                                        <td>   {such?.lab}

                                                                        </td>

                                                                        <td>      {such?.description}

                                                                        </td>


                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>







                                                )
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    </>
                )

            case "Treatments":
                return (
                    <>       <div class="containerpage">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>

                                            <th>Medication Name</th>
                                            <th>Type</th>
                                            <th>Details</th>



                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        {
                                            summyTable?.map((item, i) => {
                                                return (

                                                    <>
                                                        <tr>

                                                            <td
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "700",
                                                                    marginBottom: "5px"
                                                                }}
                                                            >

                                                                {item?.theraphydetails?.[0]?.name}
                                                            </td>

                                                            <td>  ---

                                                            </td>

                                                            <td>    {item?.theraphyids?.[0]?.details}

                                                            </td>


                                                        </tr>
                                                    </>







                                                )
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    </>
                )

            case "Medicine":
                return (
                    <>       <div class="containerpage">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>

                                            <th>Medication Name</th>
                                            <th>Frequency</th>
                                            <th>Repetitions</th>
                                            <th>Dosage</th>


                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        {
                                            summyTable?.map((item, i) => {
                                                return (

                                                    <>

                                                        {
                                                            item?.items?.map((such, z) => {
                                                                return (
                                                                    <tr>

                                                                        <td
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "700",
                                                                                marginBottom: "5px"
                                                                            }}
                                                                        >

                                                                            {such?.name}
                                                                        </td>

                                                                        <td>   {such?.frequency}

                                                                        </td>

                                                                        <td>
                                                                            {such?.lengthOfTime}
                                                                        </td>

                                                                        <td>
                                                                            {such?.dosage}




                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>







                                                )
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    </>
                )

                break;

            default:
                break;
        }
    }
    return (
        <div className='summaryhistory'

        >
            <div className='top'>
                <div className='left'>
                    <div className='NameRed'>{summy?.specialistDetails?.[0]?.PlayerDetails?.[0]?.name}</div>
                    <div className='des'>{summy?.specialistDetails?.[0]?.PlayerDetails?.[0]?.email}</div>

                    <div className='NameRed'

                        style={{
                            marginTop: "1.3rem"
                        }}
                    >
                        {summy?.bookingDetails?.[0]?.locationDetails?.[0]?.name}

                    </div>
                    <div className='des'>
                        Iirish Valley, Dharmaram Village, Near Bits Pilani campus, Keesara Mandal, Medchal District, Shamirpet, Telangana - 501301

                    </div>
                    <div className='des'>Email: vsrncc@gmail.com
                    </div>
                    <div className='des'>Cell: +91 84310 04444, +91 89050 04444</div>
                </div>
                <img src={logosvg} />
            </div>
            <div className='tablistitem'>
                <div className='f-box'>
                    <div className='titlebox'>
                        Patient Name: <span>{summy?.clientdetails?.[0]?.name}</span>
                    </div>
                    <div className='titlebox'>
                        Age: <span>{(new Date().getFullYear()) - Number(agesummy?.UserDetails?.[0]?.UserDetails?.[0]?.YearOfBirth)} Years</span>
                    </div>
                    <div className='titlebox'>
                        Gender: <span>{agesummy?.UserDetails?.[0]?.UserDetails?.[0]?.Gender}</span>
                    </div>
                </div>

                <div className='f-box'>

                    <div className='titlebox'>
                        Date: <span>{summy?.bookingDetails?.[0]?.dateOfBooking}</span>
                    </div>
                    <div className='titlebox'>
                        Time: <span>{summy?.bookingDetails?.[0]?.timeOfBooking}</span>
                    </div>
                </div>
            </div>
            <div className='bottomtablesummary'>
                <div className='leftsidetable'>
                    {
                        summeryDetail?.map(such => {
                            return (
                                <>
                                    <div className='mediciantitle'
                                        style={{
                                            marginBottom: "1rem"
                                        }}
                                    >{such?.name}
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M15.9912 2.33691C16.6787 3.02441 16.6787 4.11816 15.96 4.80566L9.17871 11.5869C8.95996 11.8057 8.67871 11.9619 8.36621 12.0557C7.30371 12.3057 4.99121 12.7744 4.99121 12.7744C4.95996 12.7744 4.95996 12.7744 4.92871 12.7744C4.67871 12.7744 4.45996 12.5557 4.52246 12.2744L5.27246 8.89941C5.33496 8.58691 5.49121 8.27441 5.74121 8.05566L12.4912 1.30566C12.835 0.961914 13.2725 0.774414 13.7412 0.774414C14.1787 0.774414 14.6162 0.961914 14.96 1.30566L15.9912 2.33691ZM14.9287 3.74316C15.0225 3.64941 15.0225 3.49316 14.9287 3.39941L13.8975 2.36816C13.835 2.30566 13.7725 2.27441 13.7412 2.27441C13.6787 2.27441 13.6162 2.30566 13.5537 2.36816L6.80371 9.11816C6.77246 9.14941 6.74121 9.18066 6.74121 9.24316L6.33496 10.9619C6.95996 10.8369 7.61621 10.6807 8.05371 10.5869C8.08496 10.5869 8.11621 10.5557 8.14746 10.5244L14.9287 3.74316ZM13.7725 9.77441C14.1787 9.77441 14.5225 10.1182 14.5225 10.5244L14.4287 14.5244C14.4287 15.7744 13.4287 16.7744 12.21 16.7744H2.74121C1.52246 16.7744 0.522461 15.7744 0.522461 14.5244V5.02441C0.522461 3.80566 1.52246 2.77441 2.74121 2.77441H7.27246C7.67871 2.77441 8.02246 3.11816 8.02246 3.52441C8.02246 3.96191 7.67871 4.27441 7.27246 4.27441H2.77246C2.33496 4.27441 2.02246 4.61816 2.02246 5.02441V14.5244C2.02246 14.9619 2.33496 15.2744 2.77246 15.2744H12.2725C12.6787 15.2744 13.0225 14.9619 13.0225 14.5244V10.5244C13.0225 10.1182 13.335 9.77441 13.7725 9.77441Z" fill="#19376D" />
                                        </svg> */}
                                    </div>
                                    <ul>
                                        {
                                            such?.questions?.map(item => {
                                                return (
                                                    <li><span>{item?.name}</span> {item?.answer}

                                                    </li>
                                                )
                                            })
                                        }

                                        {/* <li><span>No angina</span> (for 10 days)</li>
                        <li><span>Tiriedness</span> (for 1 month)</li> */}
                                    </ul>
                                </>
                            )
                        })
                    }










                </div>

                <div className='tablesierigt'>

                    {
                        type == "consultationservice" &&

                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: 'flex-start',

                                    gap: "1rem",
                                    width: "100%"
                                }}
                            >
                                <div className='mediciantitle'
                                    style={{
                                        fontWeight: listseleted == "Medicine" && "700"
                                    }}
                                    onClick={() => setlistseleted("Medicine")}

                                >Medicine
                                </div>
                                <div className='mediciantitle'
                                    style={{
                                        fontWeight: listseleted == "Treatments" && "700"
                                    }}
                                    onClick={() => setlistseleted("Treatments")}
                                >Treatments
                                </div>
                                <div className='mediciantitle'
                                    style={{
                                        fontWeight: listseleted == "Diet Plans" && "700"
                                    }}
                                    onClick={() => setlistseleted("Diet Plans")}
                                >Diet Plans
                                </div>
                                <div className='mediciantitle'
                                    style={{
                                        fontWeight: listseleted == "Investigations" && "700"
                                    }}
                                    onClick={() => setlistseleted("Investigations")}

                                >

                                    Investigations
                                </div>
                                <div className='mediciantitle'
                                    style={{
                                        fontWeight: listseleted == "Lifestyle Advice" && "700"
                                    }}
                                    onClick={() => setlistseleted("Lifestyle Advice")}

                                >

                                    Lifestyle Advice
                                </div>
                                <div className='mediciantitle'
                                    style={{
                                        fontWeight: listseleted == "Composition Medicine" && "700"
                                    }}
                                    onClick={() => setlistseleted("Composition Medicine")}

                                >

                                    Composition Medicine
                                </div>
                            </div>
                            {tablelist()}
                            <div className='notes'>Note</div>
                            <div className='notestect'>{summyTable?.[0]?.notes}</div>
                        </>
                    }




                    {/* <div className='mediciantitle'
                        style={{
                            marginBottom: "1rem",
                            color: "#202020"
                        }}
                    >Chief complaints

                    </div>
                    <ul>
                        <li>Take bed rest

                        </li>
                        <li>Do not eat outside food</li>
                        <li>Eat easy to digest food like boiled rice with dall</li>
                    </ul> */}
                </div>
            </div>
        </div >
    )
}
