import React, { useState, useRef, useEffect } from "react";
import './BookingDrawer.scss'

import "react-datepicker/dist/react-datepicker.css";
import { useDetectClickOutside } from "react-detect-click-outside";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import Package from "./Package";
import axios from 'axios'
import loadingIcon from "../../../../assets/images/loadingicon.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faSlash } from "@fortawesome/free-solid-svg-icons";
export default function BookingDrawer({ setbookingDrawer, tabName, packagetablecall }) {
    const timelist = ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00"]
    const datepickerRef = useRef(null);
    const [startDate, setStartDate] = useState(new Date());
    const [startime, setstartime] = useState("")
    const [endtime, setendtime] = useState("--:--")
    const [pstartDate, setpStartDate] = useState(new Date());
    const [pendDate, setpEndDate] = useState(new Date());
    // const [startDate, setStartDate] = useState(null);

    useEffect(() => {
        handleClickDatepickerIcon()

    }, [])
    const [locationstored, setlocationstored] = useState("")
    const [searchroom, setsearchroom] = useState("")
    const [selectedCost, setselectedCost] = useState("")
    function handleClickDatepickerIcon() {
        const datepickerElement = datepickerRef?.current;
        // console.log("datepickerElement = ", datepickerElement);
        datepickerElement?.setFocus(true);
    }
    const [locationSearch, setlocationSearch] = useState("")
    const [bookingtime, setbookingtime] = useState("")
    const [bookingdate, setbookingdate] = useState("")

    const [packagestartDate, setpackagestartDate] = useState(new Date());
    const [packageendDate, setpackageendDate] = useState(new Date());
    const [apipackagestartDate, setapipackagestartDate] = useState("");
    const [apipackageendDate, setapipackageendDate] = useState("");
    const [ampm, setampm] = useState("PM")
    const [ampmtoggle, setampmtoggle] = useState(false)
    const [datetoggle, setdatetoggle] = useState(false)

    const startingdatefunctionPackage = (e) => {
        const formatted = format(e, 'dd-MM-yyyy');
        setpackagestartDate(e)
        setapipackagestartDate(formatted)
    }

    const endingdatefunctionPackage = (e) => {
        const formatted = format(e, 'dd-MM-yyyy');
        setpackageendDate(e)
        setapipackageendDate(formatted)
    }

    console.log("packagestartDat", packageendDate, apipackageendDate)

    const Packagecaldnderdata = (e) => {
        //  handleClickDatepickerIcon()

        const [start, end] = e;
        const formattedstart = format(start, 'dd-MM-yyyy');
        const formattedend = format(end, 'dd-MM-yyyy');
        // if (start && end && start > end) {
        //     setpackageendDate(null); // Clear end date if start date is after end date
        // }
        // else {
        //     // setpackageendDate(end)
        // }
        setpackageendDate(end)
        setpackagestartDate(start);
        // ;
        setapipackagestartDate(formattedstart)
        setapipackageendDate(formattedend)

        //  setbookingdate(formatted)
        //  setStartDate(e)
        // setbookingtime(e?.toString()?.split(" ")?.[4])
        // let timebooking=e?.split(" ")
        console.log("234234fdfgfd", e)
    }



    const caldnderdata = (e) => {
        //  handleClickDatepickerIcon()
        const formatted = format(e, 'dd-MM-yyyy');

        if (specid !== "" && Object.keys(selectedsreachinfo).length > 0 && constitid !== "" && locationid !== "" & roomid !== "") {

            setbookingdate(formatted)
            setStartDate(e)
            setbookingtime(e?.toString()?.split(" ")?.[4])
            setdatetoggle(true)
        }
        else {
            setStartDate(new Date())

        }



        // let timebooking=e?.split(" ")
        console.log("StartDate", specid, constitid, locationid, roomid, labelName)
    }



    const [labelName, setlabelName] = useState("Consultations")
    const [togglesetvice, settogglesetvice] = useState(false)
    const closeList = () => {

        settogglesetvice(false)
    };

    const refservece = useDetectClickOutside({
        onTriggered: closeList,
        allowAnyKey: false,
    });
    const [starttimedrop, setstarttimedrop] = useState(false)

    const [endtimedrop, setendtimedrop] = useState(false)


    const closeend = () => {

        setstarttimedrop(false)
    };

    const refTimeend = useDetectClickOutside({
        onTriggered: closeend,
        allowAnyKey: false,
    });


    const closeTime = () => {

        setstarttimedrop(false)
    };
    const closeTimeamp = () => {

        setampmtoggle(false)
    };

    const refTimeClose = useDetectClickOutside({
        onTriggered: closeTime,
        allowAnyKey: false,
    });
    const refTimeampm = useDetectClickOutside({
        onTriggered: closeTimeamp,
        allowAnyKey: false,
    });
    const closeroom = () => {

        setroomtoggle(false)
    };

    const refroom = useDetectClickOutside({
        onTriggered: closeroom,
        allowAnyKey: false,
    });


    const closeLists = () => {
        setserachtext("")
    };

    const refsearch = useDetectClickOutside({
        onTriggered: closeLists,
        allowAnyKey: false,
    });
    const [serachtext, setserachtext] = useState('')

    const [selectedServiceName, setselectedServiceName] = useState("")
    const [servicedropClinet, setservicedropClinet] = useState([])
    const [searchService, setsearchService] = useState("")
    const [RadioSelect, setRadioSelect] = useState("")
    const [selectedsreachinfo, setselectedsreachinfo] = useState({})
    const [packageSeleted, setpackageSeleted] = useState({})
    const [durationName, setdurationName] = useState("")

    const [newTime, setNewTime] = useState('');
    let addtime = ""






    function convertTo24HourFormat1(e) {
        const [times, periods] = e?.split(/(AM|PM)/)
        let time12 = `${times.trim()} ${periods}`
        console.log("serachtext", time12)
        const [time, period] = time12.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        let addd = new Date().setHours(`${hours.toString().padStart(2, '0')}`, `${minutes.toString().padStart(2, '0')}`, 0, 0)



        let ax = new Date(new Date(addd)?.getTime() + Number(durationName) * 60000)
        let hourss = ax.getHours();
        const minutess = ax.getMinutes();
        const ampm = hourss >= 12 ? 'PM' : 'AM';

        hourss = hourss % 12;
        hourss = hourss ? hourss : 12; // the hour '0' should be '12'
        hourss = hourss < 10 ? hourss.toString().padStart(2, '0') : hourss
        const strMinutes = minutess < 10 ? '0' + minutess : minutess;

        return hourss + ':' + strMinutes + ampm
        // setNewTime(hours + ':' + strMinutes + ' ' + ampm);

    }

    const addMinutesretur = (date, minutes) => {

    };
    function convertTo24HourFormat(e) {
        const [times, periods] = e?.split(/(AM|PM)/)
        let time12 = `${times.trim()} ${periods}`
        console.log("serachtext", time12)
        const [time, period] = time12.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        let addd = new Date().setHours(`${hours.toString().padStart(2, '0')}`, `${minutes.toString().padStart(2, '0')}`, 0, 0)
        addMinutes(new Date(addd), Number(durationName));
    }


    const formatTime = (date) => {
        // console.log("zxczxczxc", date.getHours())
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        console.log("zxczxczxc", date.getHours(), ampm)
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? hours.toString().padStart(2, '0') : hours
        const strMinutes = minutes < 10 ? '0' + minutes : minutes;
        console.log("zxczxczxc", hours, strMinutes, ampm)
        setNewTime(hours + ':' + strMinutes + ampm);
    };



    const addMinutes = (date, minutes) => {
        formatTime(new Date(date?.getTime() + minutes * 60000));
    };


    console.log("durationName", newTime)
    const packageseletedfucntion = (e) => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getremainsession?clientid=${e}`
            )
            .then((res) => {
                if (res.data.success) {
                    setpackageSeleted(res?.data?.data?.[0]);
                    setRadioSelect("Package")
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setpackageSeleted({});
                    setRadioSelect("Ad-hoc")
                }
                //
            });
    };

    const therpyServiceFucntion = () => {
        setselectedServiceName("")
        axios
            .get(
                `https://uatamma.apimachine.com/api/gettheraphy?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setservicedropClinet(res?.data?.data);
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setservicedropClinet([]);
                }
                //
            });
    };



    const [searvhSpecialist, setsearvhSpecialist] = useState("")
    const selecttherpyfunction = () => {
        setselectedServiceName("")
        axios
            .get(
                `https://uatamma.apimachine.com/api/getconsultationservice?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setservicedropClinet(res?.data?.data);
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setservicedropClinet([]);
                }
                //
            });
    };
    console.log("servicedropClinet", servicedropClinet)
    const [serviceToggle, setserviceToggle] = useState(false)
    const [locationtoggle, setlocationtoggle] = useState(false)

    const closeloction = () => {
        setlocationtoggle(false)
    };
    const reflocation = useDetectClickOutside({
        onTriggered: closeloction,
        allowAnyKey: false,
    });

    const closeListsserivce = () => {
        setserviceToggle(false)
    };
    const refservce = useDetectClickOutside({
        onTriggered: closeListsserivce,
        allowAnyKey: false,
    });

    const [roomName, setroomName] = useState("")
    const [roomid, setroomid] = useState("")
    const [roomstore, setroomstore] = useState([])
    const [roomtoggle, setroomtoggle] = useState(false)

    const [searchstore, setsearchstore] = useState([])

    const [togglesearch, settogglesearch] = useState(false)
    const [locationid, setlocationid] = useState("")
    const callfucSearch = (e) => {
        setselectedsreachinfo(e)
        settogglesearch(true)
    }
    useEffect(() => {

        searchfunction()

    }, [])
    const [specDrown, setspecDrown] = useState([])
    const [constitid, setconstitid] = useState("");
    const [specName, setspecName] = useState("");
    const [specid, setspecid] = useState("");
    const [servicelocationid, setservicelocationid] = useState("")
    const [key, setKey] = useState(0);



    const [packageData, setpackageData] = useState([])
    const [packageName, setpackageName] = useState("")
    const [packageid, setpackageid] = useState("")

    useEffect(() => {
        oackagedata()
    }, [])

    const oackagedata = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getprogram?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setpackageData(res?.data?.data);
                } else {
                    setpackageData([]);
                }
                //
            });
    };



    useEffect(() => {
        if (labelName == "Consultations") {
            selecttherpyfunction()
            Therpygetloc()
            setservicedropClinet([])
            setselectedServiceName("")
            setlocationdrop([]);
            setspecDrown([])
            setspecName("")
            setspecid([])
            setlocationstored("")
            setlocationid("")
            setconstitid("")
            setbookingdate("")
            setbookingtime("")
            setstartime("")
            setsearchService("")
            setlocationSearch("");
            setsearvhSpecialist("");
            setsearchroom("");
            setroomstore([]);
            setselectedCost("")
            setroomName("");
            setroomid("");

        }
        else if (labelName === "Therapies") {
            therpyServiceFucntion()
            setservicedropClinet([])
            setselectedServiceName("")
            setlocationdrop([]);
            setspecDrown([])
            setspecName("")
            setspecid([])
            setlocationstored("")
            setlocationid("")
            setconstitid("")
            setbookingdate("")
            setbookingtime("")
            setstartime("")
            setsearchService("")
            setlocationSearch("");
            setsearvhSpecialist("");
            setsearchroom("");
            setroomstore([]); setroomName("");
            setselectedCost("");
            setroomid("");
        }
        else if (labelName === "Packages") {
            Therpygetloc()

            setservicedropClinet([])

            setlocationdrop([]);
            setspecDrown([])

            setspecid([])
            setselectedServiceName("")
            setspecName("")
            setlocationstored("")
            setlocationid("")
            setconstitid("")
            setbookingdate("")
            setbookingtime("")
            setsearchService("")
            setlocationSearch("");
            setsearvhSpecialist("");
            setsearchroom("");
            setstartime("")
            setroomName("");
            setselectedCost("");
            setroomid("");
            setpackageName("")
            setpackageid("")
            setapipackageendDate("")
            setapipackagestartDate("")
            setpackageendDate(null)
            setpackagestartDate(new Date())

            setKey(prevKey => prevKey + 1);
            setroomstore([]);


        }
        else {
            setroomName("");
            setroomid(""); setselectedCost("");
            setroomstore([]);
            setservicedropClinet([])
            setselectedServiceName("")
            setlocationdrop([]);
            setspecDrown([])
            setspecName("")
            setspecid([])
            setlocationstored("")
            setlocationid("")
            setconstitid("")
            setbookingdate("")
            setbookingtime("")
            setsearchService("")
            setlocationSearch("");
            setsearvhSpecialist("");
            setsearchroom("");
            setstartime("")
            setpackageendDate(null)
            setpackagestartDate(new Date())
            setapipackageendDate("")
            setapipackagestartDate("")
            setKey(prevKey => prevKey + 1);
        }

    }, [labelName])

    console.log("selectedCost", selectedCost)
    useEffect(() => {
        if (labelName == "Consultations") {
            if (selectedServiceName && locationstored) {

                getspe();
            }
        }

    }, [selectedServiceName, locationstored])


    useEffect(() => {
        if (labelName == "Therapies") {
            if (constitid && locationid) {


                getspetherpy();
                theryroomfucnmtion()
            }
        }
        else if (labelName == "Packages") {
            if (packageid && locationid) {
                roomfun()

            }
        }
        else if (labelName == "Consultations") {
            if (servicelocationid && constitid) {
                roomfunconstattion()
                setroomName("")
                setroomid("")

            }

        }

    }, [locationid, constitid, selectedServiceName])

    // useEffect(() => {
    //     if (servicelocationid) {
    //         roomfun() 
    //         setroomName("")
    //         setroomid("")
    //     }
    // }, [selectedServiceName])
    const theryroomfucnmtion = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getallrooms?theraphyid=${constitid}`
            )
            .then((res) => {
                if (res.data.success) {

                    if (res?.data?.data?.length <= 1) {
                        setroomName(res?.data?.data?.[0]?.RoomDetails?.[0]?.name)
                        setroomid(res?.data?.data?.[0]?.RoomDetails?.[0]?.roomid)
                    }

                    setroomstore(res?.data?.data);

                } else {
                    setroomstore([]);
                }
                //
            });
    };

    const roomfunconstattion = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getallrooms?serviceid=${constitid}`
            )
            .then((res) => {
                if (res.data.success) {
                    console.log("res?.data?.data?.length", res?.data?.data?.length)
                    setroomstore(res?.data?.data);
                    if (res?.data?.data?.length <= 1) {
                        setroomName(res?.data?.data?.[0]?.RoomDetails?.[0]?.name)
                        setroomid(res?.data?.data?.[0]?.RoomDetails?.[0]?.roomid)
                    }
                } else {
                    setroomstore([]);
                }
                //
            });
    };

    console.log("roomstore", roomstore)
    const roomfun = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getroom?locationid=${locationid}`
            )
            .then((res) => {
                if (res.data.success) {
                    setroomstore(res?.data?.data);
                } else {
                    setroomstore([]);
                }
                //
            });
    };


    const getspetherpy = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getspecialist?locationid=${locationid}&theraphyids=${constitid}`
            )
            .then((res) => {
                if (res.data.success) {
                    console.log("asdasdasd", res.data)
                    if (res?.data?.data?.length <= 1) {
                        setroomName(res?.data?.data?.[0]?.name)
                        setroomid(res?.data?.data?.[0]?.roomIds?.[0])
                    }
                    setspecDrown(res?.data?.data);
                } else {
                    setspecDrown([]);
                }
                //
            });
    };
    const getspe = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getspecialist?locationid=${locationid}&serviceids=${constitid}`
            )
            .then((res) => {
                if (res.data.success) {
                    setspecDrown(res?.data?.data);
                } else {
                    setspecDrown([]);
                }
                //
            });
    };
    console.log("specDrown", specDrown)
    const calllabelfunction = () => {
        setserachtext("")

    }

    const searchfunction = () => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getallclientsforteam/${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setsearchstore(res?.data?.data);
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setsearchstore([]);
                }
                //
            });
    };
    const [locationdrop, setlocationdrop] = useState([])

    useEffect(() => {
        if (labelName == "Therapies" && constitid) {
            TherpygfirstLocation()
        }
    }, [constitid])

    const TherpygfirstLocation = () => {
        setlocationstored("")
        setlocationid("")
        axios
            .get(
                `https://uatamma.apimachine.com/api/gettheraphy?theraphyId=${constitid}`
            )
            .then((res) => {
                if (res.data.success) {
                    setlocationdrop(res?.data?.data);
                } else {
                    setlocationdrop([]);
                }
                //
            });
    };
    const Therpygetloc = () => {
        setlocationstored("")
        setlocationid("")
        axios
            .get(
                `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setlocationdrop(res?.data?.data);
                } else {
                    setlocationdrop([]);
                }
                //
            });
    };
    console.log("locationdrop", locationdrop)
    const [loading, setloading] = useState(false)

    const Originaltherpysubmitfuction = () => {
        const [month, day, year] = bookingdate.split('-');
        let resultbooking = `${parseInt(month)}-${day}-${year}`
        setloading(true)
        let add
        if (RadioSelect == "Package") {
            add = {
                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
                "specialistid": specid,
                "dateOfBooking": bookingdate,
                "timeOfBooking": startime,
                "clientId": selectedsreachinfo.userid,
                "theraphyId": constitid,
                "locationId": locationid,
                "type": labelName == "Consultations" ? "consultationservice" : "theraphy",
                "roomid": roomid,
                "bookedSpecialistDate": resultbooking,
                "bookedSpecialistSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedClientDate": resultbooking,
                "bookedClientSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomDate": resultbooking,
                "programsessionid": packageSeleted?.programsessionid,
                "program": true,
                "newinvoice": "true",
                "totalamount": selectedCost,
            }
        }
        else {
            add = {
                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
                "specialistid": specid,
                "dateOfBooking": bookingdate,
                "timeOfBooking": startime,
                "clientId": selectedsreachinfo.userid,
                "theraphyId": constitid,
                "locationId": locationid,
                "type": labelName == "Consultations" ? "consultationservice" : "theraphy",
                "roomid": roomid,
                "bookedSpecialistDate": resultbooking,
                "bookedSpecialistSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },

                ],
                "bookedClientDate": resultbooking,
                "bookedClientSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomDate": resultbooking,
                "program": false,
                "newinvoice": "true",
                "totalamount": selectedCost,
            }
        }

        if (
            specid != "" &&
            bookingdate != "" &&
            startime != "" &&
            Object.keys(selectedsreachinfo)?.length > 0 &&
            constitid != "" &&
            locationid != "" &&
            roomid != "" &&
            resultbooking != "" &&
            newTime != ""
        ) {
            axios
                .post(
                    "https://uatamma.apimachine.com/api/createbooking",
                    {

                        ...add
                    }
                )
                .then(({ data }) => {
                    if (data.status) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    } else {
                        setloading(false)
                        toast(data?.message);
                    }
                });

        }
        else {
            toast("Please Complete All The Fields");
            setloading(false)

        }


    }


    console.log("selectedsreachinfo", Object.keys(selectedsreachinfo)?.length)



    const Packagesubmitfuction = () => {
        let asdasd = {
            "programid": packageid,
            "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
            "clientId": selectedsreachinfo.userid,
            "roomid": roomid,
            "startDate": apipackagestartDate,
            "endDate": apipackageendDate,
            "locationId": locationid,
        }
        console.log("Adadadsdasd22", asdasd)
        setloading(true)
        if (
            packageid != "" &&
            apipackagestartDate != "" &&
            apipackageendDate != "" &&
            Object.keys(selectedsreachinfo)?.length > 0 &&
            locationid != "" &&
            roomid != ""

        ) {
            axios
                .post(
                    "https://uatamma.apimachine.com/api/addprogramsession",
                    {
                        "programid": packageid,
                        "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
                        "clientid": selectedsreachinfo.userid,
                        "roomid": roomid,
                        "startDate": apipackagestartDate,
                        "endDate": apipackageendDate,
                        "locationId": locationid,

                    }
                )
                .then(({ data }) => {
                    if (data.status) {
                        setloading(false)
                        setselectedServiceName("")
                        setspecName("")
                        setlocationstored("")
                        setlocationid("")
                        setconstitid("")
                        setbookingdate("")
                        setbookingtime("")
                        setsearchService("")
                        setlocationSearch("");
                        setsearvhSpecialist("");
                        setsearchroom("");
                        setstartime("")
                        setroomName(""); setselectedCost("");
                        setroomid("");
                        setpackageName("")
                        setpackageid("")
                        setapipackageendDate("")
                        setapipackagestartDate("")
                        setpStartDate(new Date())
                        setpEndDate(new Date())

                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        packagetablecall()
                        setbookingDrawer(false)
                    } else {
                        setloading(false)
                        toast(data?.message);
                    }
                });
        }
        else {
            toast("Please Complete All The Fields");
            setloading(false)
        }



    }




    const therpysubmitfuction = () => {
        let pp = {
            "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
            "specialistid": specid,
            "dateOfBooking": bookingdate,
            "timeOfBooking": `${startime}${startime?.split(":")?.[0]?.replace(/^0+/, '') < 12 ? "AM" : "PM"}`,

            "clientId": selectedsreachinfo.userid,
            "serviceId": constitid,
            "locationId": locationid,
            "type": labelName == "Consultations" ? "consultationservice" : "theraphy",
            "roomid": roomid
        }
        const [month, day, year] = bookingdate.split('-');
        let resultbooking = `${parseInt(month)}-${day}-${year}`
        console.log("zxczxc", pp)
        setloading(true)
        let add
        if (RadioSelect == "Package") {
            add = {
                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
                "specialistid": specid,
                "dateOfBooking": bookingdate,
                "timeOfBooking": startime,
                "clientId": selectedsreachinfo.userid,
                "serviceId": constitid,
                "locationId": locationid,
                "type": labelName == "Consultations" ? "consultationservice" : "theraphy",
                "roomid": roomid,
                "bookedSpecialistDate": resultbooking,
                "bookedSpecialistSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedClientDate": resultbooking,
                "bookedClientSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomDate": resultbooking,
                "programsessionid": packageSeleted?.programsessionid,
                "program": true,
                "newinvoice": "true",
                "totalamount": selectedCost,
            }

        }

        else {
            add = {
                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || "",
                "specialistid": specid,
                "dateOfBooking": bookingdate,
                "timeOfBooking": startime,
                "clientId": selectedsreachinfo.userid,
                "serviceId": constitid,
                "locationId": locationid,
                "type": labelName == "Consultations" ? "consultationservice" : "theraphy",
                "roomid": roomid,
                "bookedSpecialistDate": resultbooking,
                "bookedSpecialistSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedClientDate": resultbooking,
                "bookedClientSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },
                ],
                "bookedRoomSlots": [
                    {
                        "startTime": startime,
                        "endTime": newTime,
                    },

                ],
                "bookedRoomDate": resultbooking,
                "program": false,
                "newinvoice": "true",
                "totalamount": selectedCost,
            }
        }
        if (
            specid != "" &&
            bookingdate != "" &&
            startime != "" &&
            Object.keys(selectedsreachinfo)?.length > 0 &&
            constitid != "" &&
            locationid != "" &&
            roomid != "" &&
            resultbooking != "" &&
            newTime != ""
        ) {
            axios
                .post(
                    "https://uatamma.apimachine.com/api/createbooking",
                    {
                        ...add

                    }
                )
                .then(({ data }) => {
                    if (data.status) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        // close()
                    } else {
                        setloading(false)
                        toast(data?.message);
                    }
                });

        }
        else {
            toast("Please Complete All The Fields");
            setloading(false)
        }


    }

    const [timeStore, settimeStore] = useState([])
    useEffect(() => {
        timefunction()
    }, [bookingdate, constitid])
    const timefunction = () => {

        const [month, day, year] = bookingdate.split('-');
        let resultbooking = `${parseInt(month)}-${day}-${year}`
        axios
            .get(
                `https://uatamma.apimachine.com/api/getallcalendars?roomid=${roomid}&clientid=${selectedsreachinfo.userid}&specialistid=${specid}&bookedDate=${resultbooking}`
            )
            .then((res) => {

                if (res.data.success) {
                    settimeStore(res?.data?.calendars?.availableSlots);
                } else {
                    settimeStore([]);
                }
                //
            });
    };

    const close = () => {
        setselectedServiceName("")
        setspecName("")
        setlocationstored("")
        setlocationid("")
        setconstitid("")
        setbookingdate("")
        setbookingtime("")
        setsearchService("")
        setlocationSearch("");
        setsearvhSpecialist("");
        setsearchroom("");
        setstartime("")
        setroomName("");
        setselectedCost("");
        setroomid("");
        setpackageName("")
        setpackageid("")
        setapipackageendDate("")
        setapipackagestartDate("")
        setpStartDate(new Date())
        setpEndDate(new Date())
        setselectedServiceName("")
        setspecName("")
        setlocationstored("")
        setlocationid("")
        setconstitid("")
        setbookingdate("")
        setbookingtime("")
        setsearchService("")
        setlocationSearch("");
        setsearvhSpecialist("");
        setsearchroom("");
        setstartime("");
        setselectedCost("");
        setroomName("");
        setroomid("");
        setpackageName("")
        setpackageid("")
        setapipackageendDate("")
        setbookingDrawer(false)
        setapipackagestartDate("")
        setpackageendDate(null)
        setpackagestartDate(new Date())
    }

    return (


        <>
            <ToastContainer />

            <div className='bookingDrawerPage'>
                {
                    loading &&
                    <div className="globalLoading"
                        style={{
                            zIndex: "99999",
                            inset: "auto",
                            height: "100%",
                            width: "727px"
                        }}
                    >
                        <img src={loadingIcon} alt="globalLoading" />
                    </div>
                }

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%"
                    }}
                >
                    <div className="inner-section-toggle">
                        <div className="top-dection-drawer"
                            style={{
                                display: "block",
                                marginBottom: "1rem"
                            }}
                        >
                            <div className='topName-section'>
                                <div className='name'>Book Appointment</div>
                                {/* <div className='id'>ID #202324</div> */}
                            </div>


                            <div style={{
                                display: "flex",
                                alignItems: "start"
                            }}>
                                <div className="ClientDetails">Client Details:</div>
                                {
                                    togglesearch &&
                                    <div className='id'
                                        style={{
                                            marginTop: "21px"
                                        }}
                                    >ID : {selectedsreachinfo.userid}</div>
                                }

                            </div>
                            <>
                                {
                                    togglesearch ?
                                        <div className="detailpagedrawershow"

                                        >
                                            <svg
                                                onClick={() => {
                                                    setRadioSelect("")
                                                    setserachtext("");
                                                    settogglesearch(false)
                                                }}
                                                xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                                <path d="M16.4341 1.5625C17.1216 2.25 17.1216 3.34375 16.4028 4.03125L9.62158 10.8125C9.40283 11.0312 9.12158 11.1875 8.80908 11.2812C7.74658 11.5312 5.43408 12 5.43408 12C5.40283 12 5.40283 12 5.37158 12C5.12158 12 4.90283 11.7812 4.96533 11.5L5.71533 8.125C5.77783 7.8125 5.93408 7.5 6.18408 7.28125L12.9341 0.53125C13.2778 0.1875 13.7153 0 14.1841 0C14.6216 0 15.0591 0.1875 15.4028 0.53125L16.4341 1.5625ZM15.3716 2.96875C15.4653 2.875 15.4653 2.71875 15.3716 2.625L14.3403 1.59375C14.2778 1.53125 14.2153 1.5 14.1841 1.5C14.1216 1.5 14.0591 1.53125 13.9966 1.59375L7.24658 8.34375C7.21533 8.375 7.18408 8.40625 7.18408 8.46875L6.77783 10.1875C7.40283 10.0625 8.05908 9.90625 8.49658 9.8125C8.52783 9.8125 8.55908 9.78125 8.59033 9.75L15.3716 2.96875ZM14.2153 9C14.6216 9 14.9653 9.34375 14.9653 9.75L14.8716 13.75C14.8716 15 13.8716 16 12.6528 16H3.18408C1.96533 16 0.965332 15 0.965332 13.75V4.25C0.965332 3.03125 1.96533 2 3.18408 2H7.71533C8.12158 2 8.46533 2.34375 8.46533 2.75C8.46533 3.1875 8.12158 3.5 7.71533 3.5H3.21533C2.77783 3.5 2.46533 3.84375 2.46533 4.25V13.75C2.46533 14.1875 2.77783 14.5 3.21533 14.5H12.7153C13.1216 14.5 13.4653 14.1875 13.4653 13.75V9.75C13.4653 9.34375 13.7778 9 14.2153 9Z" fill="#222222" />
                                            </svg>
                                            <div className="leftside">
                                                <div className="cricle">
                                                    {/* {member.substr(member.length -2)} */}
                                                    {selectedsreachinfo?.name?.substring(0, 2)}
                                                </div>
                                                <div className="maininner">
                                                    <div className="innertext">
                                                        <div className="smalltext">Client name:</div>
                                                        <div className="bigtext">{selectedsreachinfo?.name}</div>
                                                    </div>
                                                    <div className="innertext">
                                                        <div className="smalltext">Telephone number:</div>
                                                        <div className="bigtext">{selectedsreachinfo?.PhoneNumber}</div>
                                                    </div>

                                                    <div className="innertext">
                                                        <div className="smalltext">Package Name:</div>
                                                        <div className="bigtext">{packageSeleted?.ProgramDetails?.[0]?.name ? packageSeleted?.ProgramDetails?.[0]?.name : "No active package"}</div>
                                                    </div>


                                                </div>

                                            </div>

                                            <div className="leftside">
                                                <div className="maininner">
                                                    <div className="innertext">
                                                        <div className="smalltext">Gender:</div>
                                                        <div className="bigtext">{selectedsreachinfo?.Gender}</div>
                                                    </div>
                                                    <div className="innertext">
                                                        <div className="smalltext">E-Mail::</div>
                                                        <div className="bigtext">{selectedsreachinfo?.email}</div>
                                                    </div>

                                                    {
                                                        labelName == "Packages" &&
                                                        <div className="innertext"
                                                            style={{
                                                                display: "flex",
                                                                gap: "0.6rem"
                                                            }}
                                                        >
                                                            <div>
                                                                <div className="smalltext">Start Date</div>
                                                                <div className="bigtext">{packageSeleted?.startDate}</div>
                                                            </div>
                                                            <div>
                                                                <div className="smalltext">End Date</div>
                                                                <div className="bigtext">{packageSeleted?.endDate}</div>
                                                            </div>

                                                        </div>
                                                    }
                                                    {
                                                        labelName != "Packages" &&
                                                        <div className="innertext">
                                                            <div className="smalltext">Remaining Days:</div>
                                                            <div className="bigtext">{packageSeleted?.daysLeft ? `${packageSeleted?.daysLeft} days` : "NA"}</div>

                                                        </div>
                                                    }




                                                </div>

                                            </div>

                                        </div>
                                        :
                                        <div className="clientsearchinput"
                                        >
                                            <div className="dropdown-partent-div" style={{
                                                width: "344px"
                                            }}>
                                                <input placeholder="Client Name / ID"
                                                    value={serachtext}
                                                    onChange={(e) => setserachtext(e.target.value)}
                                                />

                                                {
                                                    serachtext?.length > 0 &&
                                                    <div className="divselectlist"
                                                        ref={refsearch}
                                                        style={{
                                                            width: "344px",
                                                            display: "block"
                                                        }}
                                                    >
                                                        {
                                                            searchstore?.filter((temp) =>
                                                                temp?.UserDetails?.[0]?.UserDetails?.[0]?.name
                                                                    ?.toLowerCase()
                                                                    ?.includes(serachtext?.toLowerCase())
                                                            )?.map(item => {
                                                                return (
                                                                    <>
                                                                        <div className="listitemNamedrop"
                                                                            onClick={() => {
                                                                                packageseletedfucntion(item?.UserDetails?.[0]?.UserDetails?.[0]?.userid)
                                                                                callfucSearch(item?.UserDetails?.[0]?.UserDetails?.[0])
                                                                            }
                                                                            }
                                                                        >{item?.UserDetails?.[0]?.UserDetails?.[0]?.name}</div>
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                }
                                            </div>
                                            {/* <div className="Search-button">
                                Search 
                            </div> */}
                                        </div>
                                }

                            </>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                {/* <div className="onetwo">1 of 2</div>
                            <div className="leftandright">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M6.07211 10.5891C5.91589 10.4328 5.82812 10.2209 5.82812 9.99991C5.82812 9.77893 5.91589 9.56701 6.07211 9.41074L11.0721 4.41074C11.2293 4.25894 11.4398 4.17494 11.6583 4.17684C11.8768 4.17874 12.0858 4.26638 12.2403 4.42089C12.3948 4.5754 12.4824 4.78441 12.4843 5.0029C12.4862 5.2214 12.4022 5.4319 12.2504 5.58907L7.83961 9.99991L12.2504 14.4107C12.4022 14.5679 12.4862 14.7784 12.4843 14.9969C12.4824 15.2154 12.3948 15.4244 12.2403 15.5789C12.0858 15.7334 11.8768 15.8211 11.6583 15.823C11.4398 15.8249 11.2293 15.7409 11.0721 15.5891L6.07211 10.5891Z" fill="#374664" />
                                </svg>
                                <svg
                                    style={{
                                        marginRight: "5px"
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                                    <path d="M6.92789 5.41093C7.08411 5.5672 7.17188 5.77912 7.17188 6.00009C7.17188 6.22107 7.08411 6.43299 6.92789 6.58926L1.92789 11.5893C1.77072 11.7411 1.56022 11.8251 1.34172 11.8232C1.12322 11.8213 0.914211 11.7336 0.759705 11.5791C0.605197 11.4246 0.517557 11.2156 0.515658 10.9971C0.51376 10.7786 0.597755 10.5681 0.749553 10.4109L5.16039 6.00009L0.749553 1.58926C0.597755 1.43209 0.51376 1.22159 0.515658 1.00309C0.517557 0.784597 0.605197 0.575587 0.759705 0.42108C0.914211 0.266573 1.12322 0.178932 1.34172 0.177033C1.56022 0.175135 1.77072 0.25913 1.92789 0.410929L6.92789 5.41093Z" fill="#374664" />
                                </svg>
                            </div> */}
                            </div>
                        </div>
                        <div className="label-section"
                            style={{
                                alignItems: "flex-end"
                            }}
                        >
                            <div className="label"
                                onClick={() => {
                                    settogglesearch(false);
                                    setlabelName("Therapies")
                                    setdatetoggle(false)
                                }}
                                style={{
                                    color: labelName == "Therapies" && "white",
                                    background: labelName == "Therapies" && "#FC4F00",
                                    height: labelName == "Therapies" && "43px",
                                    fontWeight: labelName == "Therapies" && "700",
                                }}
                            >Therapies </div>
                            <div className="label"
                                onClick={() => {
                                    settogglesearch(false);
                                    setlabelName("Consultations")
                                    setdatetoggle(false)
                                }}

                                style={{
                                    color: labelName == "Consultations" && "white",
                                    background: labelName == "Consultations" && "#FC4F00",
                                    height: labelName == "Consultations" && "43px",
                                    fontWeight: labelName == "Consultations" && "700",
                                }}
                            >Consultations</div>
                            <div className="label"
                                onClick={() => {
                                    settogglesearch(false);
                                    setlabelName("Packages")
                                    setdatetoggle(false)
                                }}

                                style={{
                                    color: labelName == "Packages" && "white",
                                    background: labelName == "Packages" && "#FC4F00",
                                    height: labelName == "Packages" && "43px"
                                }}
                            >Packages </div>
                        </div>





                        <div className="main-show">
                            {
                                !datetoggle &&
                                <div className="leftside">
                                    <div className="ClientDetails">Appointment details:</div>
                                    {
                                        labelName != "Packages" &&
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: '1.5rem',
                                                marginBottom: "1rem",
                                                gap: "2rem"
                                            }}
                                        >
                                            <div>
                                                <div className="selectServices"
                                                    style={{
                                                        marginBottom: "5px"
                                                    }}
                                                >Ad-hoc</div>
                                                <input type="radio"


                                                    checked={RadioSelect == "Ad-hoc"}
                                                    onChange={(event) => setRadioSelect(event.target.value)} value="Ad-hoc" />
                                            </div>
                                            <div>
                                                <div className="selectServices"
                                                    style={{
                                                        marginBottom: "5px"
                                                    }}
                                                >Package</div>
                                                <input

                                                    type="radio" checked={RadioSelect == "Package"}
                                                    onChange={(event) => setRadioSelect(event.target.value)} value="Package" />
                                            </div>
                                        </div>
                                    }


                                    {
                                        labelName == "Packages" ?
                                            <>
                                                <div className="selectServices">Select Package</div>
                                                <div className="dropdown-partent-div" ref={refservce}>
                                                    <div className="droplistselect"

                                                        onClick={() => setserviceToggle(!serviceToggle)}
                                                    >
                                                        {packageName ? packageName : "Click to Select"}

                                                        <svg
                                                            style={{
                                                                transform: serviceToggle && "rotate(180deg)"
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                                                            <path d="M1.125 0.48291C1.22917 0.378743 1.35417 0.316243 1.5 0.29541C1.625 0.29541 1.73958 0.337077 1.84375 0.42041L8 6.07666L14.1562 0.42041C14.4062 0.23291 14.6458 0.243327 14.875 0.45166C15.0625 0.70166 15.0521 0.941243 14.8438 1.17041L8.34375 7.17041C8.11458 7.33708 7.88542 7.33708 7.65625 7.17041L1.15625 1.17041C0.947917 0.962077 0.9375 0.73291 1.125 0.48291Z" fill="#7587A7" />
                                                        </svg>

                                                    </div>
                                                    {
                                                        serviceToggle &&
                                                        <div className="divselectlist"

                                                        >
                                                            <input
                                                                value={searchService}
                                                                onChange={(e) => setsearchService(e.target.value)}
                                                                placeholder="Search..." />
                                                            {
                                                                packageData?.filter((temp) =>
                                                                    temp?.name
                                                                        ?.toLowerCase()
                                                                        ?.includes(searchService?.toLowerCase())
                                                                )?.map(item => {
                                                                    return (
                                                                        // <div className="labelseletedcheck"

                                                                        //     style={{
                                                                        //         background: selectedServiceName == item?.name && "#FC4F00",
                                                                        //         color: selectedServiceName == item?.name && "#FFf"
                                                                        //     }}
                                                                        //     onClick={() => setselectedServiceName(item?.name)}
                                                                        // >{item?.name}
                                                                        //     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                                                                        //         <path d="M14.3438 1.7959L5.84375 10.2959C5.73958 10.4001 5.625 10.4521 5.5 10.4521C5.375 10.4521 5.26042 10.4001 5.15625 10.2959L0.65625 5.7959C0.447917 5.56673 0.447917 5.33757 0.65625 5.1084C0.885417 4.90007 1.11458 4.90007 1.34375 5.1084L5.5 9.2334L13.6562 1.1084C13.8854 0.900065 14.1146 0.900065 14.3438 1.1084C14.5521 1.33757 14.5521 1.56673 14.3438 1.7959Z" fill={selectedServiceName == item?.name ? "#fff" : "#7587A7"} />
                                                                        //     </svg>

                                                                        // </div>


                                                                        <div className="listitemNamedrop"
                                                                            onClick={() => {

                                                                                setpackageName(item?.name);
                                                                                setpackageid(item?.programid)
                                                                                setserviceToggle(false)
                                                                            }
                                                                            }
                                                                        >{item?.name}</div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="selectServices">Select Service</div>
                                                <div className="dropdown-partent-div" ref={refservce}>
                                                    <div className="droplistselect"

                                                        onClick={() => setserviceToggle(!serviceToggle)}
                                                    >
                                                        {selectedServiceName ? selectedServiceName : "Click to Select"}

                                                        <svg
                                                            style={{
                                                                transform: serviceToggle && "rotate(180deg)"
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                                                            <path d="M1.125 0.48291C1.22917 0.378743 1.35417 0.316243 1.5 0.29541C1.625 0.29541 1.73958 0.337077 1.84375 0.42041L8 6.07666L14.1562 0.42041C14.4062 0.23291 14.6458 0.243327 14.875 0.45166C15.0625 0.70166 15.0521 0.941243 14.8438 1.17041L8.34375 7.17041C8.11458 7.33708 7.88542 7.33708 7.65625 7.17041L1.15625 1.17041C0.947917 0.962077 0.9375 0.73291 1.125 0.48291Z" fill="#7587A7" />
                                                        </svg>

                                                    </div>
                                                    {
                                                        serviceToggle &&
                                                        <div className="divselectlist"

                                                        >
                                                            <input
                                                                value={searchService}
                                                                onChange={(e) => setsearchService(e.target.value)}
                                                                placeholder="Search..." />
                                                            {
                                                                servicedropClinet?.filter((temp) =>
                                                                    temp?.name
                                                                        ?.toLowerCase()
                                                                        ?.includes(searchService?.toLowerCase())
                                                                )?.map(item => {
                                                                    return (
                                                                        // <div className="labelseletedcheck"

                                                                        //     style={{
                                                                        //         background: selectedServiceName == item?.name && "#FC4F00",
                                                                        //         color: selectedServiceName == item?.name && "#FFf"
                                                                        //     }}
                                                                        //     onClick={() => setselectedServiceName(item?.name)}
                                                                        // >{item?.name}
                                                                        //     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                                                                        //         <path d="M14.3438 1.7959L5.84375 10.2959C5.73958 10.4001 5.625 10.4521 5.5 10.4521C5.375 10.4521 5.26042 10.4001 5.15625 10.2959L0.65625 5.7959C0.447917 5.56673 0.447917 5.33757 0.65625 5.1084C0.885417 4.90007 1.11458 4.90007 1.34375 5.1084L5.5 9.2334L13.6562 1.1084C13.8854 0.900065 14.1146 0.900065 14.3438 1.1084C14.5521 1.33757 14.5521 1.56673 14.3438 1.7959Z" fill={selectedServiceName == item?.name ? "#fff" : "#7587A7"} />
                                                                        //     </svg>

                                                                        // </div>


                                                                        <div className="listitemNamedrop"
                                                                            onClick={() => {
                                                                                console.log("zxczzzzzzzzzzzzz", item)
                                                                                setconstitid(labelName == "Therapies" ? item?.theraphyId : labelName == "Consultations" && item?.consultationserviceid);
                                                                                setselectedCost(item?.cost)
                                                                                setselectedServiceName(item?.name);
                                                                                setdurationName(item?.duration)
                                                                                setlocationstored("")
                                                                                setspecName("")
                                                                                setservicelocationid(item?.locationId)
                                                                                setserviceToggle(false)
                                                                            }
                                                                            }
                                                                        >{item?.name}</div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    }
                                                </div>
                                            </>

                                    }


                                    <div className="selectServices">Location</div>
                                    <div className="dropdown-partent-div"
                                        ref={reflocation}
                                    >
                                        <div className="droplistselect"

                                            onClick={() => setlocationtoggle(!locationtoggle)}
                                        >
                                            {
                                                locationstored ? locationstored : "Click to Select"
                                            }


                                            <svg
                                                style={{
                                                    transform: locationtoggle && "rotate(180deg)"
                                                }}
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                                                <path d="M1.125 0.48291C1.22917 0.378743 1.35417 0.316243 1.5 0.29541C1.625 0.29541 1.73958 0.337077 1.84375 0.42041L8 6.07666L14.1562 0.42041C14.4062 0.23291 14.6458 0.243327 14.875 0.45166C15.0625 0.70166 15.0521 0.941243 14.8438 1.17041L8.34375 7.17041C8.11458 7.33708 7.88542 7.33708 7.65625 7.17041L1.15625 1.17041C0.947917 0.962077 0.9375 0.73291 1.125 0.48291Z" fill="#7587A7" />
                                            </svg>

                                        </div>
                                        {
                                            locationtoggle &&
                                            <div className="divselectlist">
                                                <input
                                                    value={locationSearch}
                                                    onChange={(e) => setlocationSearch(e.target.value)}
                                                    placeholder="Search..." />

                                                {
                                                    locationdrop?.filter((temp) =>
                                                        (labelName == "Consultations" ?
                                                            temp?.name : labelName == "Packages" ?
                                                                temp?.name
                                                                : labelName == "Therapies" &&
                                                                temp?.LocationDetails?.[0]?.name)?.toLowerCase()?.includes(locationSearch?.toLowerCase())
                                                    )?.map(item => {
                                                        return (
                                                            <>
                                                                <div className="listitemNamedrop"

                                                                    onClick={() => {
                                                                        setlocationstored(labelName == "Consultations" ? item?.name : labelName == "Packages" ? item?.name : labelName == "Therapies" && item?.LocationDetails?.[0]?.name);
                                                                        setlocationid(labelName == "Consultations" ? item?._id : labelName == "Packages" ? item?._id : labelName == "Therapies" && item?.locationIds?.[0]);
                                                                        setlocationtoggle(false)
                                                                    }}
                                                                >{labelName == "Consultations" ? item?.name : labelName == "Packages" ? item?.name : labelName == "Therapies" && item?.LocationDetails?.[0]?.name}</div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </div>
                                        }

                                    </div>
                                    {
                                        labelName != "Packages" &&
                                        <>
                                            <div className="selectServices">Select Specialist</div>
                                            <div className="dropdown-partent-div"
                                                ref={refservece}
                                            >
                                                <div className="droplistselect"

                                                    onClick={() => settogglesetvice(!togglesetvice)}
                                                >

                                                    {specName ? specName : "Click to Select"}
                                                    <svg
                                                        style={{
                                                            transform: togglesetvice && "rotate(180deg)"
                                                        }}
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                                                        <path d="M1.125 0.48291C1.22917 0.378743 1.35417 0.316243 1.5 0.29541C1.625 0.29541 1.73958 0.337077 1.84375 0.42041L8 6.07666L14.1562 0.42041C14.4062 0.23291 14.6458 0.243327 14.875 0.45166C15.0625 0.70166 15.0521 0.941243 14.8438 1.17041L8.34375 7.17041C8.11458 7.33708 7.88542 7.33708 7.65625 7.17041L1.15625 1.17041C0.947917 0.962077 0.9375 0.73291 1.125 0.48291Z" fill="#7587A7" />
                                                    </svg>

                                                </div>
                                                {
                                                    togglesetvice &&
                                                    <div className="divselectlist">
                                                        <input
                                                            value={searvhSpecialist}
                                                            onChange={(e) => setsearvhSpecialist(e.target.value)}
                                                            placeholder="Search..." />
                                                        {specDrown?.filter((temp) =>
                                                            temp?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name
                                                                ?.toLowerCase()
                                                                ?.includes(searvhSpecialist?.toLowerCase())
                                                        )?.map((item) => {
                                                            return (
                                                                <div
                                                                    className="listitemNamedrop"
                                                                    onClick={() => {
                                                                        setspecName(item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name);
                                                                        setspecid(item?.specialistid);
                                                                        settogglesetvice(false)
                                                                    }}
                                                                >
                                                                    {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                }

                                            </div>
                                        </>
                                    }



                                    <div className="selectServices">Select Room</div>
                                    <div className="dropdown-partent-div"
                                        ref={refroom}
                                    >
                                        <div className="droplistselect"

                                            onClick={() => setroomtoggle(!roomtoggle)}
                                        >

                                            {roomName ? roomName : "Click to Select"}
                                            <svg
                                                style={{
                                                    transform: roomtoggle && "rotate(180deg)"
                                                }}
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                                                <path d="M1.125 0.48291C1.22917 0.378743 1.35417 0.316243 1.5 0.29541C1.625 0.29541 1.73958 0.337077 1.84375 0.42041L8 6.07666L14.1562 0.42041C14.4062 0.23291 14.6458 0.243327 14.875 0.45166C15.0625 0.70166 15.0521 0.941243 14.8438 1.17041L8.34375 7.17041C8.11458 7.33708 7.88542 7.33708 7.65625 7.17041L1.15625 1.17041C0.947917 0.962077 0.9375 0.73291 1.125 0.48291Z" fill="#7587A7" />
                                            </svg>

                                        </div>
                                        {
                                            roomtoggle &&
                                            <div className="divselectlist">
                                                <input
                                                    value={searchroom}
                                                    onChange={(e) => setsearchroom(e.target.value)}
                                                    placeholder="Search..." />
                                                {roomstore?.filter((temp) =>
                                                    temp?.name
                                                        ?.toLowerCase()
                                                        ?.includes(searchroom?.toLowerCase())
                                                )?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemNamedrop"
                                                            onClick={() => {
                                                                setroomName(labelName == "Packages" ? item?.name : item?.RoomDetails?.[0]?.name);
                                                                setroomid(labelName == "Packages" ? item?.roomid : labelName == "Therapies" ? item?.RoomDetails?.[0]?.roomid : item?.RoomDetails?.[0]?.roomid);
                                                                setroomtoggle(false)
                                                            }}
                                                        >
                                                            {labelName == "Packages" ? item?.name : item?.RoomDetails?.[0]?.name}
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        }

                                    </div>
                                    {
                                        labelName != "Packages" &&
                                        <>
                                            <div className="selectServices">Price</div>
                                            <div className="dropdown-partent-div"

                                            >
                                                <div className="droplistselect"


                                                >

                                                    {selectedCost}


                                                </div>

                                            </div>
                                        </>
                                    }
                                    {
                                        labelName == "Packages" &&
                                        <div className="dateSection">
                                            <div>
                                                <div className="selectServices">Start Date</div>
                                                <div className="startapitime">
                                                    {apipackagestartDate ? apipackagestartDate : "00-00-0000"}
                                                </div>
                                            </div>
                                            <div className="packagelines"></div>
                                            <div>
                                                <div className="selectServices">End Date</div>
                                                <div className="startapitime">
                                                    {apipackageendDate ? apipackageendDate : "00-00-0000"}
                                                </div>
                                            </div>
                                        </div>

                                    }

                                </div>
                            }
                            {
                                labelName == "Packages" ?
                                    <Package
                                        setpackagestartDate={setpackagestartDate}
                                        packagestartDate={packagestartDate}
                                        packageendDate={packageendDate}
                                        key={key}
                                        tabName={tabName}
                                        setStartDate={setpStartDate}
                                        startDate={pstartDate}
                                        endDate={pendDate}
                                        setEndDate={setpEndDate}
                                        setpackageendDate={setpackageendDate}
                                        Packagecaldnderdata={Packagecaldnderdata}
                                        startingdatefunctionPackage={startingdatefunctionPackage}
                                        setapipackagestartDate={setapipackagestartDate}
                                        setapipackageendDate={setapipackageendDate}
                                        endingdatefunctionPackage={endingdatefunctionPackage}
                                    />
                                    :
                                    <>
                                        <div
                                            style={{
                                                marginTop: "23px"
                                            }}
                                        >
                                            <div className="textFortext">Select date</div>

                                            <div className="main-date-section"
                                                style={{
                                                    marginTop: "0px"
                                                }}
                                            >


                                                <DatePicker
                                                    open={true}
                                                    ref={datepickerRef}
                                                    minDate={new Date()}
                                                    startDate={startDate}
                                                    selected={startDate}
                                                    onChange={(date) => caldnderdata(date)}
                                                />



                                                {/* <div className="timeavilable">Time Available</div> */}
                                                <div className="timedropdowndata">
                                                    <div className="dropdown-partent-div"
                                                        style={{
                                                            width: "max-content"
                                                        }}
                                                    >
                                                        {/* <div className="timedata"

                                                >

                                                    <div className="name"
                                                        ref={refTimeClose}
                                                        onClick={() => setstarttimedrop(true)}
                                                    >{startime ? startime : "--:--"}</div>
                                                    <div className="line"></div>
                                                    <div className="ampm"
                                                        ref={refTimeampm}
                                                        onClick={() => setampmtoggle(true)}
                                                    >--</div> 

                                                </div> */}
                                                        {/* {
                                                    ampmtoggle &&
                                                    <div className="divselectlist"

                                                        style={{
                                                            height: "100px",
                                                            overflow: "scroll"
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                setampm("AM")
                                                                setampmtoggle(false)
                                                            }}
                                                            className="listitemNamedrop"

                                                            style={{
                                                                paddingLeft: "0",
                                                                paddingRight: "0",
                                                                justifyContent: "center"
                                                            }}
                                                        >AM</div>
                                                        <div
                                                            onClick={() => {
                                                                setampm("PM")
                                                                setampmtoggle(false)
                                                            }}
                                                            className="listitemNamedrop"

                                                            style={{
                                                                paddingLeft: "0",
                                                                paddingRight: "0",
                                                                justifyContent: "center"
                                                            }}
                                                        >PM</div>

                                                    </div>
                                                } */}
                                                        {/* {
                                                    starttimedrop &&
                                                    <div className="divselectlist"

                                                        style={{
                                                            height: "100px",
                                                            overflow: "scroll"
                                                        }}
                                                    >
                                                        {timeStore?.map(item => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        setstartime(item)
                                                                        setstarttimedrop(false)
                                                                    }}
                                                                    className="listitemNamedrop"

                                                                    style={{
                                                                        paddingLeft: "0",
                                                                        paddingRight: "0",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >{item}</div>
                                                            )
                                                        })

                                                        }


                                                    </div>
                                                } */}

                                                    </div>


                                                </div>
                                            </div>

                                            {
                                                !datetoggle && startime != "" &&
                                                <>

                                                    <div className="timeshow">

                                                        <div className="crirlce"
                                                            onClick={() => setdatetoggle(true)}
                                                        >

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                <path d="M7.0878 6L11.7745 1.31329C12.0751 1.01275 12.0752 0.526114 11.7745 0.225504C11.4739 -0.075129 10.9873 -0.0751993 10.6867 0.225481L6 4.91219L1.31329 0.225504C1.01275 -0.0751056 0.526093 -0.0751993 0.225484 0.225457C-0.0751495 0.526067 -0.075173 1.01263 0.225484 1.31326L4.91215 6L0.22546 10.6867C-0.0744698 10.9866 -0.0744698 11.4746 0.22546 11.7745C0.526047 12.0751 1.01266 12.0752 1.31327 11.7745L5.99995 7.08776L10.6866 11.7744C10.9872 12.0751 11.4738 12.0751 11.7744 11.7745C12.0751 11.4739 12.0751 10.9873 11.7744 10.6866L7.0878 6Z" fill="#475F8A" />
                                                                <path d="M7.08775 6L11.7744 1.31329C12.075 1.01275 12.0752 0.526114 11.7745 0.225504C11.4739 -0.075129 10.9873 -0.0751993 10.6866 0.225481L5.99994 4.91217V7.08783L10.6866 11.7745C10.9872 12.0751 11.4738 12.0752 11.7744 11.7745C12.075 11.474 12.0751 10.9874 11.7744 10.6867L7.08775 6Z" fill="#475F8A" />
                                                            </svg>
                                                        </div>
                                                        <div

                                                            className="timeNamelistdivcalendar"

                                                            style={{
                                                                paddingLeft: "0",
                                                                paddingRight: "0",
                                                                justifyContent: "center"
                                                            }}
                                                        >{startime} - {convertTo24HourFormat1(startime)} </div>
                                                    </div></>
                                            }


                                        </div>
                                    </>

                            }

                            {/* <Package /> */}
                            {/* <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                            /> */}

                            {
                                datetoggle &&
                                <>


                                    <div className="timeshow">
                                        <div className="textFortext">Select new time</div>
                                        <div className="crirlce"
                                            onClick={() => setdatetoggle(false)}
                                        >

                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <path d="M7.0878 6L11.7745 1.31329C12.0751 1.01275 12.0752 0.526114 11.7745 0.225504C11.4739 -0.075129 10.9873 -0.0751993 10.6867 0.225481L6 4.91219L1.31329 0.225504C1.01275 -0.0751056 0.526093 -0.0751993 0.225484 0.225457C-0.0751495 0.526067 -0.075173 1.01263 0.225484 1.31326L4.91215 6L0.22546 10.6867C-0.0744698 10.9866 -0.0744698 11.4746 0.22546 11.7745C0.526047 12.0751 1.01266 12.0752 1.31327 11.7745L5.99995 7.08776L10.6866 11.7744C10.9872 12.0751 11.4738 12.0751 11.7744 11.7745C12.0751 11.4739 12.0751 10.9873 11.7744 10.6866L7.0878 6Z" fill="#475F8A" />
                                                <path d="M7.08775 6L11.7744 1.31329C12.075 1.01275 12.0752 0.526114 11.7745 0.225504C11.4739 -0.075129 10.9873 -0.0751993 10.6866 0.225481L5.99994 4.91217V7.08783L10.6866 11.7745C10.9872 12.0751 11.4738 12.0752 11.7744 11.7745C12.075 11.474 12.0751 10.9874 11.7744 10.6867L7.08775 6Z" fill="#475F8A" />
                                            </svg>
                                        </div>
                                        <div className="divselectlist"

                                            style={{
                                                height: "351px",
                                                position: "static",
                                                overflow: "scroll",
                                                padding: "1rem"
                                            }}
                                        >
                                            {timeStore?.map(item => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setstartime(item)

                                                            convertTo24HourFormat(item)
                                                            setstarttimedrop(false)
                                                            setdatetoggle(false)
                                                        }}
                                                        className="timeNamelistdiv"

                                                        style={{
                                                            paddingLeft: "0",
                                                            paddingRight: "0",
                                                            justifyContent: "center"
                                                        }}
                                                    >{item} - {convertTo24HourFormat1(item)}</div>
                                                )
                                            })

                                            }


                                        </div>
                                    </div>
                                </>
                            }


                        </div>

                    </div>
                    <div className="bottmsection">
                        <div className="decline"
                            onClick={close}
                        > <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M18.5 6L6.5 18" stroke="#7587A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.5 6L18.5 18" stroke="#7587A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Cancel </div>
                        <div
                            className="decline"
                            style={{
                                background: "#37B12B",
                                color: "white"

                            }}
                            onClick={labelName == "Consultations" ? therpysubmitfuction : labelName == "Packages" ? Packagesubmitfuction : labelName == "Therapies" && Originaltherpysubmitfuction}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13" fill="none">
                                <path d="M17.5 1L6.5 12L1.5 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Save</div>
                    </div>
                </div>
            </div >
        </>

    )
}