import React, { useEffect, useState, useContext, useRef } from "react";
import classNames from "./pipelinesubmenu.module.scss";
import "./Custom.scss"
//assets
import flatpickr from "flatpickr";
import { FaCaretDown } from "react-icons/fa";
import perscribeIcon from "../../../assets/images/icons/perscribe.svg";
import axios from "axios";
import { toastify } from "../../../assets/functions/toastify";
import { faVolumeControlPhone } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNavigate, useParams } from "react-router-dom";
const PipelineSubmenu = ({ stateValue, setState }) => {
  //constants
  const allSubMenu = [
    "Pre-Assessment Form",
    "Patient Profile Forms",
    "Widgets",
    "Prescriptions",
    "Saved Widgets",
  ];
  const { id } = useParams()
  const history = useNavigate()
  const [selectedMenu, setSelectedMenu] = useState("Pre-Assessment Form");
  const [sessionValue, setSessionValue] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [localRefresh, setLocalRefresh] = useState(false);

  //functions
  const [dop, setdop] = useState(null);
  const [widgenumber, setwidgenumber] = useState(0);
  const [widgenumberSaved, setwidgenumberSaved] = useState(null);
  const [togglesave, settogglesave] = useState(false)
  const [showdrop, setshowdrop] = useState(false);
  const [isToggleOn, setisToggleOn] = useState(false);
  const [wedgedata, setwedgedata] = useState([]);
  const [wedgetoggle, setwedgetoggle] = useState([
    {
      toggle: false,
    },
  ]);

  const [questionslist, setquestionslist] = useState([]);
  const {
    email,
    actionBtns,
    setActionBtns,
    setGlobalSearchQuery,
    dropDowndownvalue, globalSearchQuery,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [datalist, setdatalist] = useState([
    {
      name: "",
      questionslist: "",
      anstype: "",
      requried: true,
      toggle: false,
      questionlist: "",
      id: "",
    },
  ]);
  console.log("datalist", datalist)
  // const dropdownaddedpreform = (index, item, tyep) => {
  //   let data = [...preform];
  //   data[index].anstype = tyep;
  //   data[index].name = item;

  //   setpreform(data);
  //   setshowdrop(false);
  // };
  const dropdownadded = (index, item, tyep, see) => {
    let data = [...datalist];
    data[index].anstype = tyep;
    data[index].name = item;
    data[index].id = see?._id;
    data[index].questionlist = see?.name;
    // data[index].id = s?.questionId;
    setdatalist(data);
    setshowdrop(false);
    console.log("see", see)
  };

  const handleFormChange = (index, event, g, s) => {

    console.log("index, event, g, s", s)
    let data = [...datalist];
    data[index].anstype = g;
    data[index].id = s?._id;
    data[index].questionlist = s?.name;
    data[index][event.target.name] = event.target.value;
    setdatalist(data);
  };

  const [widetstore, setwidetstore] = useState({})
  const [saveditemshow, setsaveditemshow] = useState(false)
  console.log("33333333333", datalist)

  const savedata = () => {

    let dataset = datalist?.map(item => {
      return {
        "questionId": item?.id,
        "name": item?.questionlist,
        "answer": item?.name,

      }
    })

    let WorkingDaysasdassdeleted = dataset?.filter(item => {
      return item?.answer
    })

    console.log("123123123123", WorkingDaysasdassdeleted)




    axios
      .post(`https://uatamma.apimachine.com/api/adduserwidget`, {
        "clientid": storeclientid,
        "consultationsessionid": id,
        "name": widetstore?.name,
        "widgetid": widetstore?.widgetid,
        "questions": WorkingDaysasdassdeleted
      })

      .then(({ data }) => {
        if (data.status) {
          settogglesave(false)
          setwidgenumberSaved(null)
          setSelectedMenu("Saved Widgets");

          savedwidget()
        } else {

        }
      });
















  }




  const selected = (e, g) => {
    addFields(e?.questions);
    setwidgenumber(g);
    setwidetstore(e)
  };
  const addFields = (e) => {
    let newfield = { name: "", anstype: "", requried: true };

    let pop = e?.map((item, i) => {
      return {
        name: "",
        anstype: "",
        questionslist: "",
        requried: item?.isMandatory,
        toggle: false,
      };
    });
    console.log("pop", pop);
    setdatalist(pop);
  };
  useEffect(() => {
    getprescriptionFunction()
    savedwidget()
  }, [])

  const [prescriptiondata, setprescriptiondata] = useState([])
  const [storedata, setstoredata] = useState([])
  const savedwidget = () => {

    axios
      .get(`https://uatamma.apimachine.com/api/getuserwidget?consultationsessionid=${id}`)
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res.data?.success) {
          setstoredata(res?.data?.data);
        } else {

          setstoredata([]);

        }
      });
  };


  const getprescriptionFunction = (e) => {
    console.log("edfsdsf", e)
    axios
      .get(`https://uatamma.apimachine.com/api/getprescription?consultationsessionid=${id}`)
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res.data?.success) {
          setprescriptiondata(res?.data?.data);
        } else {

          setprescriptiondata([]);

        }
      });
  };


  const tablefucntion1 = (e) => {
    console.log("edfsdsf", e)
    axios
      .get(`https://uatamma.apimachine.com/api/getwidget?specialistid=${e}`)
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res.data?.success) {
          setwedgedata(res?.data?.data);
          let pop = res?.data?.data?.map((item, i) => {
            return {
              toggle: false,
            };
          });



        } else {
          setwedgetoggle([
            {
              toggle: false,
            },
          ]);
          setwedgedata([]);

        }
      });
  };
  console.log("selectedMenu", selectedMenu)
  const [storeclientid, setstoreclientid] = useState("")

  async function getDetailsOneSession() {
    try {
      let response = await axios.get(
        `https://uatamma.apimachine.com/api/getconsultsessions?consultationsessionid=${id}`
      );
      let res = response?.data?.data;
      if (res?.length > 0) {
        setSessionValue(res[0]);
        tablefucntion1(res[0]?.specialistid)
        setstoreclientid(res[0]?.clientid)
      }
      console.log(res, "sessionValue");
    } catch (error) {
      console.log(error?.message, "Get details one session error");
    }
  }

  async function updateStatusConsultationSession(updateStatus) {
    try {
      let response = await axios.put(
        `https://uatamma.apimachine.com/api/updateconsultsession/${sessionValue?._id}`,
        {
          status: updateStatus,
        }
      );
      if (response?.data?.success) {
        toastify(response?.data?.message, "success");
      } else {
        toastify(response?.data?.message, "error");
      }
      setShowDropdown(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.log(error?.message, "updateStatusConsultationSession error");
    }
  }
  var monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  //renderings
  const wedgetogglefunction = (index) => {
    let data = [...wedgetoggle];
    data[index].toggle = !data[index].toggle;

    setwedgetoggle(data);
  };
  useEffect(() => {
    getDetailsOneSession();
  }, []);



  return (
    <div className={classNames.pipelineSubmenu}>
      <div className={classNames.mainContainer}>
        <div className={classNames.headerContainer}>
          <div className="profileTop">
            <div
              className={classNames.profileName}
              style={{
                paddingBottom: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                onClick={() => history('/owner/consultation/sessions')}
              >
                <circle
                  cx="16.5"
                  cy="16.5"
                  r="16"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M10.4497 16.8516L16.171 22.535C16.2768 22.64 16.4253 22.7 16.5808 22.7H19.0393C19.5282 22.7 19.7821 22.1619 19.4491 21.8314L14.0779 16.5L19.4491 11.1686C19.7821 10.8381 19.5282 10.3 19.0393 10.3H16.5808C16.4253 10.3 16.2768 10.3596 16.171 10.465L10.4497 16.1484C10.2502 16.3467 10.2502 16.6533 10.4497 16.8516Z"
                  fill="url(#paint0_linear_1918_382)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1918_382"
                    x1="19.6001"
                    y1="16.5"
                    x2="10.3001"
                    y2="16.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#191F41" />
                  </linearGradient>
                </defs>
              </svg>

              <div style={{ marginLeft: "15px" }}>
                <span style={{ fontSize: "1.35rem", fontWeight: 550 }}>
                  {sessionValue?.clientdetails?.length > 0 &&
                    sessionValue?.clientdetails[0]?.name
                    ? sessionValue?.clientdetails[0]?.name
                    : ""}
                </span>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "6px",
                  }}
                >
                  {sessionValue?.consultationsessionid}
                </div>
              </div>
            </div>
            <div className={classNames.actionBtns}>
              <div className={classNames.statusBtn}>
                <div>Session Status</div>
                <div>|</div>
                <div>
                  <div onClick={() => setShowDropdown((prev) => !prev)}>
                    <span>
                      {sessionValue?.status?.charAt(0).toUpperCase() +
                        sessionValue?.status?.slice(1)}
                    </span>
                    <FaCaretDown />
                  </div>
                  {showDropdown && (
                    <div className={classNames.dropdownList}>
                      <div
                        className={classNames.dropdownItems}
                        onClick={() => {
                          updateStatusConsultationSession(
                            sessionValue?.status?.toLowerCase() === "open"
                              ? "close"
                              : "open"
                          );
                        }}
                      >
                        {sessionValue?.status?.toLowerCase() === "open"
                          ? "Close"
                          : "Open"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="deletedEditingButton"
                style={{ gap: "0.5rem" }}
                onClick={() => setSelectedMenu("Perscribe")}
              >
                <img
                  src={perscribeIcon}
                  alt="perscribeIcon"
                  style={{ height: "1.2rem" }}
                />
                Perscribe
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.contentContainer}>
          <div className="profiletab">
            {allSubMenu?.map((eachItem, index) => {
              return (
                <div
                  key={eachItem + index}
                  className="items"
                  style={{
                    background:
                      selectedMenu === "Patient Profile Forms Questions" &&
                        eachItem === "Patient Profile Forms"
                        ? "#3f506a"
                        : selectedMenu === eachItem
                          ? "#3f506a"
                          : "",
                  }}
                  onClick={() => {
                    if (selectedMenu === "Patient Profile Forms Questions") {
                      setSelectedMenu("Patient Profile Forms");
                    } else if (selectedMenu === "Saved Widgets") {
                      settogglesave(false)
                      setwidgenumberSaved(null)
                      setSelectedMenu(eachItem);
                    }
                    else {
                      setSelectedMenu(eachItem);
                    }
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
          </div>


          {selectedMenu == "Perscribe" ? (
            <Perscribe
              sessionValue={sessionValue}
              globalSearchQuery={globalSearchQuery}
              setGlobalSearchQuery={setGlobalSearchQuery}
            />
          ) : selectedMenu == "Prescriptions" ? (
            <>

              <div className="handle-table-editing">
                <div className="g-table-sec">
                  <div className="table-section">
                    <table style={{ minWidth: "100%" }}>
                      <thead>
                        <tr
                          style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                        >

                          <th>
                            Patient
                          </th>

                          <th>
                            Specialist

                          </th>
                          <th>
                            Date

                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {prescriptiondata?.map((row, index) => {
                          let date = row?.createdAt?.split("-");
                          let finial = row?.createdAt?.split("-")?.[2]?.split("T");
                          return (
                            <tr
                              key={row?.brokerage_name + index}
                              style={{
                                gridTemplateColumns: "min-content repeat(4, 1fr)",
                                // pointerEvents: sidebarOpen ? "none" : "",
                                cursor: "pointer",
                              }}
                              onClick={() => history(`/owner/pharmacy/prescriptions/${row?.prescriptionid}`)}
                            // onClick={() => getclientid(row?.consultationcatoriesSummaryid)}
                            >

                              <td>
                                {/* {
                            row?.TeamDetails?.map(such => {
                                return (
                                    such?.PlayerDetails?.map(item => {
                                        return (
                                            <>
                                                <img className="icon"
                                                    style={{
                                                        width: "26px",
                                                        height: "26px",
                                                        borderRadius: "10rem",
                                                        marginRight: "12px"
                                                    }}
                                                    src={item?.ProfilePic} />

                                                {item?.name}
                                                <CopyBtn
                                                    copyText={item?.name
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                )
                            })
                        } */}

                                <div
                                  style={{
                                    width: "300px"
                                  }}
                                >

                                  {row?.clientdetails?.[0]?.name}


                                </div>
                              </td>


                              <td

                              >

                                <div
                                  style={{
                                    width: "300px"
                                  }}
                                >

                                  {row?.sessiondetails?.[0]?.specialistdata?.[0]?.TeamDetails?.[0]?.SpecialistDetails?.[0]?.name}


                                </div>

                              </td>


                              <td>

                                {monthNames[date[1]?.replace(/^0/, '')]}  {finial?.[0]}  {date?.[0]}




                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </>
          ) : selectedMenu === "Pre-Assessment Form" ? (
            <PreEnrollmentForm
              localRefresh={localRefresh}
              sessionValue={sessionValue}
            />
          ) : selectedMenu === "Patient Profile Forms" ||
            selectedMenu === "Patient Profile Forms Questions" ? (
            <PatientProfileForm
              localRefresh={localRefresh}
              sessionValue={sessionValue}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          ) : selectedMenu === "Widgets" ?
            (
              <div className="innerSectionSession"
                style={{
                  paddingTop: "0px"
                }}
              >
                {/* <div className="buttonsave">
                            <div>Save</div>
                        </div> */}

                <div>
                  <div className="wedegettabs">
                    {
                      wedgedata?.map((such, i) => {
                        return (
                          <>
                            <div className="lables"

                              style={{
                                background: widgenumber == i && "linear-gradient(0deg, #FC4F00 0%, #F79540 100%)",
                                color: widgenumber == i && "white"
                              }}
                              onClick={() => selected(such, i)}
                            // onClick={() => onClick = {() => selected(such, i)}

                            //   setwidgenumber(i)}
                            >
                              {such?.name}
                            </div >


                          </>
                        )
                      })
                    }

                  </div>
                  {
                    wedgedata?.map((such, i) => {

                      console.log("wedgedata", wedgedata)
                      return (
                        <>
                          {
                            widgenumber == i &&
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "space-between"
                                }}
                              >
                                {such?.questions?.map((item, j) => {
                                  console.log("wedgedata123", wedgedata)
                                  if (item?.answerType?.toLowerCase() == "dropdown") {
                                    return (
                                      <div className="questionsection"

                                        style={{
                                          width: "48%"
                                        }}
                                      >
                                        <div className="queTitle">{item?.name}</div>
                                        <div
                                          className="queTitledrop"
                                          onClick={() => {
                                            setshowdrop(!showdrop);
                                            setdop(j);
                                          }}
                                        >
                                          {datalist?.[j]?.name}
                                          {dop == j && showdrop ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="7"
                                              height="6"
                                              viewBox="0 0 7 6"
                                              fill="none"
                                            >
                                              <path
                                                d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                                fill="#5A5656"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="7"
                                              height="6"
                                              viewBox="0 0 7 6"
                                              fill="none"
                                            >
                                              <path
                                                d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                                fill="#5A5656"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                        {dop == j && showdrop && (
                                          <div className="dropdonwlistdata">
                                            {item?.dropdownOptions?.map((such) => {
                                              console.log("xzcczxczxczxczx", such)
                                              return (
                                                <div
                                                  onClick={() =>
                                                    dropdownadded(j, such, "dropdown", item)
                                                  }
                                                >
                                                  {such}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="questionsection"
                                        style={{
                                          width: "48%"
                                        }}
                                      >
                                        <div className="queTitle">{item?.name}</div>
                                        <input
                                          className="queAnswerinput"
                                          name="name"
                                          value={datalist.name}
                                          onChange={(event) =>
                                            handleFormChange(j, event, "String", item)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </>


                          }

                        </>
                      )
                    })
                  }

                  {
                    wedgedata?.length > 0 &&
                    <div className="saveSection">
                      <div
                        onClick={savedata}

                      >Save</div>
                    </div>
                  }



                </div>

                {/* <>
                  {wedgedata?.map((such, i) => {
                 

                    return (
                      <>
                        {widgenumber == i ? (
                          <div className="widgetparent">
                            <div
                              className="namewidget"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => setwidgenumber(null)}
                            >
                              {such?.name}

                              <svg
                                onClick={() => setwidgenumber(null)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                              >
                                <path
                                  d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                  fill="#5A5656"
                                />
                              </svg>
                            </div>

                            {wedgetoggle[i]?.toggle ? (
                              ""
                            ) : (
                              <>
                                {such?.questions?.map((item, j) => {
                                  if (item?.answerType?.toLowerCase() == "dropdown") {
                                    return (
                                      <div className="questionsection">
                                        <div className="queTitle">{item?.name}</div>
                                        <div
                                          className="queTitledrop"
                                          onClick={() => {
                                            setshowdrop(!showdrop);
                                            setdop(j);
                                          }}
                                        >
                                          {datalist?.[j]?.name}
                                          {dop == j && showdrop ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="7"
                                              height="6"
                                              viewBox="0 0 7 6"
                                              fill="none"
                                            >
                                              <path
                                                d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                                fill="#5A5656"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="7"
                                              height="6"
                                              viewBox="0 0 7 6"
                                              fill="none"
                                            >
                                              <path
                                                d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                                fill="#5A5656"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                        {dop == j && showdrop && (
                                          <div className="dropdonwlistdata">
                                            {item?.dropdownOptions?.map((such) => {
                                              console.log("xzcczxczxczxczx", such)
                                              return (
                                                <div
                                                  onClick={() =>
                                                    dropdownadded(j, such, "dropdown", item)
                                                  }
                                                >
                                                  {such}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="questionsection">
                                        <div className="queTitle">{item?.name}</div>
                                        <input
                                          className="queAnswerinput"
                                          name="name"
                                          value={datalist.name}
                                          onChange={(event) =>
                                            handleFormChange(j, event, "String", item)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </>
                            )}


                          
                            <div className="saveSection">
                              <div
                                onClick={savedata}

                              >Save</div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="widgetparent"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => selected(such, i)}
                          >
                            <div className="namewidget">
                              {such?.name}

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                              >
                                <path
                                  d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                  fill="#5A5656"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </> */}


              </div>
            ) : selectedMenu === "Saved Widgets" &&

            (
              <div className="innerSectionSession">
                {/* <div className="buttonsave">
                            <div>Save</div>
                        </div> */}
                {
                  !togglesave && storedata?.length > 0 &&
                  < div
                    style={{
                      fontSize: '15px',
                      fontWeight: "500",
                      marginBottom: "1rem"
                    }}
                  >Select saved widgets</div>
                }


                {storedata?.map((such, i) => {
                  // console.log("iten34535", item)

                  return (
                    <>

                      {
                        togglesave && widgenumberSaved == i ?
                          <>

                            {
                              such?.questions?.map(item => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        fontSize: '15px',
                                        fontWeight: "500",
                                        marginBottom: "1rem"
                                      }}
                                    >  {item?.name}</div>

                                    <div
                                      className="widgetparent"
                                      style={{
                                        cursor: "pointer",
                                      }}

                                    >
                                      <div className="namewidget">
                                        {item?.answer}


                                      </div>
                                    </div>
                                  </>
                                )
                              })
                            }

                          </>

                          :
                          <>
                            {
                              !togglesave &&
                              <>

                                <div
                                  className="widgetparent"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    settogglesave(!togglesave)
                                    setwidgenumberSaved(i)
                                  }}
                                >
                                  <div className="namewidget">
                                    {such?.name}


                                  </div>
                                </div>
                              </>
                            }


                          </>
                      }


                      {/* 
                      <div
                        className="widgetparent"
                        style={{
                          cursor: "pointer",
                        }}
                      
                      >
                        <div>Select saved widgets</div>
                        <div className="namewidget">
                          {such?.clientid}

                       
                        </div>
                      </div> */}


                    </>
                  );
                })}
              </div>
            )
          }
        </div>
      </div>
    </div >
  );
};

export default PipelineSubmenu;

const PreEnrollmentForm = ({ localRefresh, sessionValue }) => {
  const [preAssessment, setPreAssessment] = useState("");

  //functions
  const { id } = useParams()
  async function getPreAssesment() {

    try {
      let response = await axios.get(
        `https://uatamma.apimachine.com/api/getuserassessment?consultationsessionid=${id}`
      );
      if (response?.data?.data?.length > 0) {
        setPreAssessment(response?.data?.data?.[0]);
      }

    } catch (error) {
      console.log(error, "Get pre dsadasd response");
    }
  }
  console.log("Get pre assesment response", id);
  //rendering
  useEffect(() => {
    getPreAssesment();
  }, [id]);

  return (
    <div className={classNames.preEnrollmentForm}>
      {preAssessment?.questions?.length > 0
        ? preAssessment?.questions?.map((eachQuestion, index) => {
          return (
            <div className={classNames.eachQuestion}>
              <div className={classNames.title}>{eachQuestion?.name}</div>
              <div className={classNames.answer}>{eachQuestion?.answer}</div>
            </div>
          );
        })
        : ""}
    </div>
  );
};

const Perscribe = ({ sessionValue, globalSearchQuery, setGlobalSearchQuery }) => {
  const allPerscribeItems = [
    "Prescribe diet plan",
    "Prescribe treatment",
    "Prescribe medication",
    "Prescribe investigation",
    "Prescribe life style advice",
    "Prescribe note",
  ];
  const [storetrement, setstoretrement] = useState([])
  const [option, setoption] = useState("main")
  const [catoriesSummary, setcatoriesSummary] = useState([])

  useEffect(() => {
    setoption("main")

  }, [])



  const backfunction = () => {
    //setstoretrement([])
    setoption("main")
    setGlobalSearchQuery("")
  }

  const callinerlist = (e) => {
    if (e === "Prescribe treatment") {
      //  setstoretrement([])

      tablefucntion()
      setoption("Prescribe treatment")
      setrightsidedname("Prescribe treatment")
    }
    else if (e == "Prescribe note") {
      setrightsidedname("Prescribe note")
      setoption("Prescribe note")
    }
    else if (e === "Prescribe diet plan") {
      //  setstoretrement([])
      deitplanfunction()
      closeexpadingfunction()
      setrightsidedname("Prescribe diet plan")
      setoption("Prescribe diet plan")
    }
    else if (e === "Prescribe medication") {
      //setstoretrement([])
      setrightsidedname("Prescribe medication")
      // deitplanfunction()
      // closeexpadingfunction()
      setoption("Prescribe medication")
      setmedicationSelet({
        name: "",
        dosage: "",
        frequency: "",
        lengthOfTime: "",
      })
      //setstoreinfoMedicance([])
    }
    else if (e === "Prescribe investigation") {
      //  setstoretrement([])
      setrightsidedname("Prescribe investigation")
      // deitplanfunction()
      // closeexpadingfunction()
      setinvestingaData({
        name: "",
        lab: "",
        description: "",
      })
      //setstoreinfoMedicance([])
      setoption("Prescribe investigation")
    }

    else if (e === "Prescribe life style advice") {
      //   setstoretrement([])
      setrightsidedname("Prescribe life style advice")
      // deitplanfunction()
      // closeexpadingfunction()
      // setmedicationSelet({
      //   dNname: "",
      //   dosage: "",
      //   Divtitle: ""
      // })

      setlifestyle({
        name: "",
        description: "",
      })
      setoption("Prescribe life style advice")
      setlifestyle([])
    }

  }

  const timpicker = [
    "12:00AM",
    "12:30AM",
    "1:00AM",
    "1:30AM",
    "2:00AM",
    "2:30AM",
    "3:00AM",
    "3:30AM",
    "4:00AM",
    "4:30AM",
    "5:00AM",
    "5:30AM",
    "6:00AM",
    "6:30AM",
    "7:00AM",
    "7:30AM",
    "8:00AM",
    "8:30AM",
    "9:00AM",
    "9:30AM",
    "10:00AM",
    "10:30AM",
    "11:00AM",
    "11:30AM",
    "12:00PM",
    "12:30PM",
    "1:00PM",
    "1:30PM",
    "2:00PM",
    "2:30PM",
    "3:00PM",
    "3:30PM",
    "4:00PM",
    "4:30PM",
    "5:00PM",
    "5:30PM",
    "6:00PM",
    "6:30PM",
    "7:00PM",
    "7:30PM",
    "8:00PM",
    "8:30PM",
    "9:00PM",
    "9:30PM",
    "10:00PM",
    "10:30PM",
    "11:00PM",
    "11:30PM"
  ]
  const [addingdiethard, setaddingdiethard] = useState([])


  const deitplanfunction = () => {
    axios

      .get(
        `https://uatamma.apimachine.com/api/getdietplan?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {
          setaddingdiethard(res?.data?.data);
        }
        else {
          setaddingdiethard([]);

        }

      });
  };
  const [thermentautobook, setthermentautobook] = useState([])
  const [backupDeitdata, setbackupDeitdata] = useState([])



  const tablefucntion = () => {
    axios

      .get(
        `https://uatamma.apimachine.com/api/gettheraphy?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {


          const result = res?.data?.data.map((group, z) => {

            return {
              ...group,
              remandeddropdown: "Recommend"

            };

            return group;
          });
          setcatoriesSummary(result);
        }

        else {
          setcatoriesSummary([]);

        }

      });
  };

  const theryduplicatedatastore = (e, i) => {

    const result = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,

          duplicatetherapyname: e.TeamDetails?.[0]?.PlayerDetails?.[0]?.name,
          duplicatetherapyid: e?.specialistid

        };
      }
      return group;
    });
    settherpyService({
      toggle: false,
      name: therpyService?.name
    })
    setstoretrement(result)
  }

  const Roomdatastore = (e, i) => {

    const result = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,

          duplicateroomname: e.name,
          duplicateroomid: e?.roomid

        };
      }
      return group;
    });


    setroomService({
      toggle: false,
      name: roomService?.name
    })
    setstoretrement(result)
  }


  const timeatastore = (e, i) => {

    const result = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,

          duplicatertime: e,


        };
      }
      return group;
    });


    settimeshow({
      toggle: false,
      name: timeshow?.name
    })
    setstoretrement(result)
  }






  const lacationdatastore = (e, i) => {

    const result = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,

          duplicatelocationname: e.name,
          duplicatelocationid: e?._id

        };
      }
      return group;
    });


    setloctionService({
      toggle: false,
      name: loctionService?.name
    })
    setstoretrement(result)
  }


  const chnagetreatment = (e, i) => {

    const newData = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,

          des: e.target.value

        };
      }
      return group;
    });

    // let arr = [...storetrement]
    // arr[i] = {
    //   des: e.target.value
    // }
    setstoretrement(newData)
  }


  console.log("234234c", storetrement)
  const [dietplan, setdietplan] = useState([])
  const adddietpla = (e) => {
    console.log("zxczxczxc", Object.keys(backupDeitdata)?.length)
    let filteredArray = catoriesSummary.filter(item => item?.name !== e?.name)
    setcatoriesSummary(filteredArray)
    if (Object.keys(backupDeitdata)?.length > 0) {
      setdietplan([backupDeitdata])

    }
    else {
      setdietplan([e])
    }
  }

  const additembackdiet = (e) => {
    setdietplan([...dietplan, e])
    setcatoriesSummary([...catoriesSummary, e])
    let filteredArray = dietplan.filter(item => item?.name !== e?.name)
    // // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    // console.log("storetrement", filteredArray)
    setdietplan(filteredArray)
    setbackupDeitdata({})
    // setcatoriesSummary(filteredArray)

  }

  console.log("dietplan", dietplan)

  const additem = (e) => {
    setstoretrement([...storetrement, e])
    setthermentautobook([...thermentautobook, e])
    let filteredArray = catoriesSummary.filter(item => item?.name !== e?.name)


    setcatoriesSummary(filteredArray)
  }
  console.log("storetrement", storetrement)


  const additemback = (e) => {
    setcatoriesSummary([...catoriesSummary, e])

    let filteredArray = storetrement.filter(item => item?.name !== e?.name)
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray)
    setstoretrement(filteredArray)

  }


  const [rightsidedname, setrightsidedname] = useState("")

  const [expandable, setexpandable] = useState(false)
  const [samevalue, setsamevalue] = useState("")
  const expadingfunction = (e) => {
    setsamevalue(e?._id)
    setexpandable(true)
  }

  const closeexpadingfunction = () => {
    setsamevalue("")
    setexpandable(false)
  }

  //addnote 
  const [addnote, setaddnote] = useState({

    description: "",

  })
  const [addnoteseleted, setaddnoteseleted] = useState([])


  const addNotefucntion = async (e) => {
    await setaddnoteseleted([addnote])
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setaddnote({

      description: "",
    })

  }
  console.log("2342342342", addnoteseleted)
  const removenote = (e) => {

    setaddnoteseleted([...addnoteseleted, e])

    let filteredArray = addnoteseleted.filter(item => item?.description !== e?.description)
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)

    setaddnoteseleted(filteredArray)

  }


  //lifestyle
  const [lifestyle, setlifestyle] = useState({
    name: "",
    description: "",

  })
  const [lifestyleseleted, setlifestyleseleted] = useState([])
  const addlifestyle = async (e) => {
    await setlifestyleseleted([lifestyle, ...lifestyleseleted])
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setlifestyle({
      name: "",
      description: "",
    })

  }

  const removelifestyle = (e) => {

    setlifestyleseleted([...investingaSelected, e])

    let filteredArray = investingaSelected.filter(item => item?.name !== e?.name)
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray)
    setlifestyleseleted(filteredArray)

  }

  // investigation


  const [investingaData, setinvestingaData] = useState({
    name: "",
    lab: "",
    description: "",

  })




  const [investingaSelected, setinvestingaSelected] = useState([])


  const addinvest = async (e) => {
    await setinvestingaSelected([investingaData, ...investingaSelected,])
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setinvestingaData({
      name: "",
      lab: "",
      description: "",
    })

  }

  const removeInvesting = (e) => {

    setinvestingaSelected([...investingaSelected, e])

    let filteredArray = investingaSelected.filter(item => item?.name !== e?.name)
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray)
    setinvestingaSelected(filteredArray)

  }





  console.log("investingaSelected", investingaSelected)


  // medicance
  const [medicationSelet, setmedicationSelet] = useState({
    name: "",
    dosage: "",
    frequency: "",
    lengthOfTime: "",
  })


  const [storeinfoMedicance, setstoreinfoMedicance] = useState([])

  const [storeMedience, setstoreMedience] = useState([])

  const additempop = async (e) => {










    await setstoreinfoMedicance([medicationSelet, ...storeinfoMedicance])
    await setmedicationSelet({
      name: "",
      dosage: "",
      frequency: "",
      lengthOfTime: "",
    })

  }

  console.log("medicationSelet", storeinfoMedicance)

  const addMediance = async (e) => {
    await setstoreinfoMedicance([...storeinfoMedicance, medicationSelet])
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setmedicationSelet({
      name: "",
      dosage: "",
      frequency: "",
      lengthOfTime: "",
    })

  }


  const removemediance = (e) => {

    setstoreinfoMedicance([...storeinfoMedicance, e])

    let filteredArray = storeinfoMedicance.filter(item => item?.name !== e?.name)
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray)
    setstoreinfoMedicance(filteredArray)

  }






  const additembackmedican = (e) => {

    setstoreinfoMedicance([...storeinfoMedicance, e])

    let filteredArray = storeinfoMedicance.filter(item => item?.name !== e?.name)
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray)
    setstoreinfoMedicance(filteredArray)

  }



  const [locationdrop, setlocationdrop] = useState([])


  const [loctionService, setloctionService] = useState({
    name: null,
    toggle: false
  })

  const [therpyService, settherpyService] = useState({
    name: null,
    toggle: false
  })
  const [roomService, setroomService] = useState({
    name: null,
    toggle: false
  })

  const [timeshow, settimeshow] = useState({
    name: null,
    toggle: false
  })
  console.log("da3", loctionService)


  const [roomdata, setroomdata] = useState([])
  const [remandeddropdown, setremandeddropdown] = useState("Recommend")

  const [therphsydata, settherphsydata] = useState([])










  useEffect(() => {
    //setremandeddropdown("Recommend")
    locationdropfunction()
    therapistfunction()
    Roomdatafunction()
  }, [])




  const addpremandeddrop = (e, i) => {

    const result = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,
          remandeddropdown: e,
          remandeddropdownid: i,


        };
      }
      return group;
    });

    setstoretrement(result)
  }




  // useEffect(() => {
  //   const flatpickrInstances = datepickerRef2.current.map((inputRef, index) => {
  //     return flatpickr(inputRef, {
  //       dateFormat: 'Y-m-d',
  //       onChange: (selectedDates, dateStr) => {
  //         // const updatedDates = [...dates];
  //         // updatedDates[index] = dateStr;
  //         //setDates(updatedDates);
  //         console.log(`Input at index ${index} changed to: ${dateStr}`);
  //       }
  //     });
  //   });

  //   return () => {
  //     flatpickrInstances.forEach(instance => instance.destroy()); // Cleanup Flatpickr instances
  //   };
  // }, [datepickerRef2?.current]);









  const datechange = (e) => {
    console.log("2342342", e)
  }


  const Roomdatafunction = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getroom?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {
          setroomdata(res?.data?.data);
        }
        else {
          setroomdata([]);
        }
      });
  };



  const locationdropfunction = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {
          setlocationdrop(res?.data?.data);
        }
        else {
          setlocationdrop([]);
        }
      });
  };


  const therapistfunction = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getspecialist?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}&type=therapist`
      )
      .then((res) => {
        if (res?.data?.success) {
          settherphsydata(res?.data?.data);
        }
        else {
          settherphsydata([]);
        }
      });
  };















  const { id } = useParams()

  const savdmediciance = () => {








    let deitplan = dietplan?.map(item => {
      return {
        "name": item?.name,
        "description": item?.description,
        "numofdays": item?.numofdays,
        "startingday": item?.startingday,
        "plandetails": item?.plandetails
      }
    })

    let lifystyle = lifestyleseleted?.map(item => {
      return {
        "name": item?.name,
        "description": item?.description,
      }
    })

    let investingaSelecteddata = investingaSelected?.map(item => {
      return {
        "name": item?.name,

        "lab": item?.lab,
        "description": item?.description,
      }
    })

    let storeinfoMedicancedata = storeinfoMedicance?.map(item => {
      return {
        "name": item?.name,
        "dosage": item?.dosage,
        "frequency": item?.frequency,
        "lengthOfTime": item?.lengthOfTime,
      }
    })
    let treatment = storetrement?.map(item => {
      return {
        "theraphyid": item?.theraphyId,
        "details": item?.des,

      }
    })

    // remandeddropdown == "Recommend"


    let automatetretment = storetrement?.map(item => {

      if (item?.remandeddropdown == "Recommend") {
        return {
          "theraphyid": item?.theraphyId,
          "details": item?.des,
        }
      }
      else {
        return {
          // "theraphyid": item?.theraphyId,
          // "details": item?.des,
          // // 
          "theraphyid": item?.theraphyId,
          "details": "",
          "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
          "clientId": sessionValue?.clientid,
          "dateOfBooking": item?.duplicateDate,
          "timeOfBooking": item?.duplicatertime,
          "specialistid": item?.duplicatetherapyid,
          "roomid": item?.duplicateroomid,
          "serviceId": item?.theraphyId,
          "locationId": item?.duplicatelocationid,
          "bookingStatus": "booked"

        }
      }
    })



    let deitplandata = deitplan?.map(item => {
      return {
        "name": item?.name,
        "description": item?.description,
        "numofdays": item?.numofdays,
        "startingday": item?.startingday,
        "plandetails": item?.plandetails?.map(such => {
          return {
            "day": such?.day,
            "dayplan": such?.dayplan?.map(eaxh => {
              return {
                "kitchenitemid": eaxh?.kitchenitemid,
                "kitchenItemName": eaxh?.kitchenItemName,
                "kitchenItemIcon": eaxh?.kitchenItemIcon,
                "unitname": eaxh?.unitname,
                "unitamount": eaxh?.unitamount,
                "time": eaxh?.time
              }
            })
          }
        })
      }
    })

    // let resultsavetreament = remandeddropdown == "Recommend" ? treatment : automatetretment
    let resut = {
      "consultationsessionid": sessionValue?.consultationsessionid,
      "clientid": sessionValue?.clientid,



      "bookingrequired": storetrement?.[0]?.remandeddropdown == "Recommend" ? false : true,
      "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
      "bookingrequired": storetrement?.[0]?.remandeddropdown == "Recommend" ? false : true,
      "items": [

        ...storeinfoMedicancedata


      ],


      "theraphyids": [
        ...automatetretment
      ],
      "investigationList": [

        ...investingaSelecteddata

      ],
      "lifestyleList": [
        ...lifystyle
      ],

      "notes": addnoteseleted?.[0]?.description,
      "dietplandetails": { ...deitplandata?.[0] }

    }
    console.log("234234234234234234234", resut)



    axios
      .post(`https://uatamma.apimachine.com/api/addprescription`, {
        ...resut
      })

      .then(({ data }) => {
        if (data.status) {

          setTimeout(() => {
            window.location.reload();
          }, 1000);


        } else {

        }
      });


  }

  // console.log("medicationSelet", storeinfoMedicance)

  const [dplandropdown, setdplandropdown] = useState(false)
  const [dietid, setdietid] = useState(false)


  console.log("medicationSelet", backupDeitdata)

  const changedietpainNamet = async (each, dayIndex, dayplanIndex, newUnitName) => {
    // let pop = {
    //   ...backupDeitdata, plandetails: [
    //     ...backupDeitdata?.plandetails,backupDeitdata?.plandetails[dayIndex] [
    //       ...backupDeitdata?.plandetails[dayIndex],dayplan[]
    //     ]
    //   ]
    // }

    // 


    // let lallasd =
    // {
    //   ...backupDeitdata,
    // }


    // console.log("pop", lallasd)
    // setaccomdationRoom({ ...accomdationRoom, coverPhoto: [...accomdationRoom?.coverPhoto, { name: "" }] });

    // setbackupDeitdata({
    //   ...backupDeitdata, plandetails: [...backupDeitdata?.plandetails?.[dayIndex], {}]
    //   //   dayplan:[...dayplan: [...backupDeitdata, plandetails?.[dayIndex]?.dayplan[dayplanIndex], {
    //   //     name: ""

    //   //   }]
    //   // ]
    // })

    //   let add = {
    //     ...backupDeitdata,{ backupDeitdata.plandetails[dayIndex].dayplan[dayplanIndex].unitname: newUnitName }
    // }

    await setbackupDeitdata(prevAdd => {
      // const newAdd = [...prevAdd];
      const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
      if (
        newAdd &&
        newAdd.plandetails &&
        newAdd.plandetails[dayIndex] &&
        newAdd.plandetails[dayIndex].dayplan &&
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
      ) {
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex].unitname = newUnitName;
      } else {
        console.log("Invalid index provided");
      }
      return newAdd;
    });


  }

  const changedietpainTime = (each, dayIndex, dayplanIndex, newUnitName) => {


    setbackupDeitdata(prevAdd => {
      const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
      if (
        newAdd &&
        newAdd.plandetails &&
        newAdd.plandetails[dayIndex] &&
        newAdd.plandetails[dayIndex].dayplan &&
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
      ) {
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex].time = newUnitName;
      } else {
        console.log("Invalid index provided");
      }
      return newAdd;
    });
  }

  // console.log(catoriesSummary, "ae5e5e5d111")
  console.log(backupDeitdata, "ae5e5e5d")
  const changedietpainAmount = (each, dayIndex, dayplanIndex, newUnitName) => {





    setbackupDeitdata(prevAdd => {
      const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
      if (
        newAdd.plandetails &&
        newAdd.plandetails[dayIndex] &&
        newAdd.plandetails[dayIndex].dayplan &&
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
      ) {
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex].unitamount = newUnitName;
      } else {
        console.log("Invalid index provided");
      }
      return newAdd;
    });

  }
  console.log("deitplan213123", dietplan)

  const [kichenitemName, setkichenitemName] = useState("")

  const changedietKitenitem = (each, dayIndex, dayplanIndex, newUnitName) => {

    setkichenitemName(newUnitName?.name)
    setbackupDeitdata(prevAdd => {
      const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
      if (
        newAdd.plandetails &&
        newAdd.plandetails[dayIndex] &&
        newAdd.plandetails[dayIndex].dayplan &&
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
      ) {
        newAdd.plandetails[dayIndex].dayplan[dayplanIndex].kitchenitemid = newUnitName?._id;
      } else {
        console.log("Invalid index provided");
      }
      return newAdd;
    });
    setshowselecteddropdown(false)
  }


  const [kitchenItem, setkitchenItem] = useState([])
  useEffect(() => {
    kitchenDrown()
  }, [])

  const kitchenDrown = () => {
    axios
      // .get(`https://uatamma.apimachine.com/api/getwidget`)
      .get(
        `https://uatamma.apimachine.com/api/getkitchenitem?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {
          setkitchenItem(res?.data?.data);
        }
        else {
          setkitchenItem([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

      });
  };
  const expandedcall = (e) => {

    expadingfunction(e)
    setbackupDeitdata(e)
  }
  console.log("storetremen77777777t", storetrement)


  const [showselecteddropdown, setshowselecteddropdown] = useState(false)
  return (
    <div className={classNames.perscribe}>

      <div className={classNames.leftside}>
        {

          option == "Prescribe medication" ?
            <>
              <div className={classNames.title} onClick={backfunction}>

                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9EBF3" />
                  <path d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z" fill="url(#paint0_linear_102_3785)" />
                  <defs>
                    <linearGradient id="paint0_linear_102_3785" x1="14.8001" y1="12.4999" x2="7.90003" y2="12.4999" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#0B2447" />
                      <stop offset="0.311" stop-color="#0D264C" />
                      <stop offset="0.6768" stop-color="#122E5A" />
                      <stop offset="1" stop-color="#19376D" />
                    </linearGradient>
                  </defs>
                </svg>

                Choose medication</div>

              <input className={classNames.draginput} placeholder="Drug name"
                value={medicationSelet.name}
                onChange={(e) => setmedicationSelet({
                  name: e.target.value,
                  dosage: medicationSelet?.dosage,
                  frequency: medicationSelet.frequency,
                  lengthOfTime: medicationSelet.lengthOfTime
                })}
              />
              <div className={classNames.selectdiv} >Or select from your organizations pharmacy</div>
              <input className={classNames.draginput} placeholder="Dosage"
                value={medicationSelet.dosage}
                onChange={(e) => setmedicationSelet({
                  name: medicationSelet.name,
                  dosage: e.target.value,
                  frequency: medicationSelet.frequency,
                  lengthOfTime: medicationSelet.lengthOfTime
                })}
              />

              <div className={classNames.tablesection}>
                <input
                  style={
                    {
                      width: "48%"
                    }
                  }
                  className={classNames.draginput} placeholder="Frequency"
                  value={medicationSelet.frequency}
                  onChange={(e) => setmedicationSelet({
                    name: medicationSelet.name,
                    dosage: medicationSelet.dosage,
                    frequency: e.target.value,
                    lengthOfTime: medicationSelet.lengthOfTime
                  })}
                />


                <input
                  style={
                    {
                      width: "48%"
                    }
                  }
                  className={classNames.draginput} placeholder="Repetitions"
                  value={medicationSelet.lengthOfTime}
                  onChange={(e) => setmedicationSelet({
                    name: medicationSelet.name,
                    dosage: medicationSelet.dosage,
                    frequency: medicationSelet.frequency,
                    lengthOfTime: e.target.value,
                  })}
                />

                {/* <div
                  style={{
                    border: medicationSelet.Divtitle == "Frequency" ? "1px solid #0B2447" : "1px solid #E5E5E5"
                  }}
                  onClick={() => setmedicationSelet({
                    dNname: medicationSelet.dNname,
                    dosage: medicationSelet.dosage,
                    Divtitle: "Frequency"
                  })}
                >Frequency</div> */}
                {/* <div
                  style={{
                    border: medicationSelet.Divtitle == "Repetitions" ? "1px solid #0B2447" : "1px solid #E5E5E5"
                  }}
                  onClick={() => setmedicationSelet({
                    dNname: medicationSelet.dNname,
                    dosage: medicationSelet.dosage,
                    Divtitle: "Repetitions"
                  })}
                >Repetitions </div> */}
              </div>
              <div className={classNames.Addto}
                onClick={addMediance}
              >Add to Prescription </div>
            </>
            :
            option == "Prescribe life style advice" ?
              <>
                <div className={classNames.title} onClick={backfunction}>

                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9EBF3" />
                    <path d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z" fill="url(#paint0_linear_102_3785)" />
                    <defs>
                      <linearGradient id="paint0_linear_102_3785" x1="14.8001" y1="12.4999" x2="7.90003" y2="12.4999" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0B2447" />
                        <stop offset="0.311" stop-color="#0D264C" />
                        <stop offset="0.6768" stop-color="#122E5A" />
                        <stop offset="1" stop-color="#19376D" />
                      </linearGradient>
                    </defs>
                  </svg>

                  Choose lifestyle advice</div>

                <input className={classNames.draginput} placeholder="Name"
                  style={{
                    marginBottom: "1rem"
                  }}
                  value={lifestyle.name}
                  onChange={(e) => setlifestyle({
                    name: e.target.value,
                    description: lifestyle.Divtitle
                  })}
                />



                <textarea className={classNames.draginput} placeholder="Description"
                  style={{
                    height: "90px",
                    paddingTop: '1.3rem'
                  }}
                  value={lifestyle.description}
                  onChange={(e) => setlifestyle({
                    name: lifestyle.name,
                    description: e.target.value,
                  })}
                />

                <div className={classNames.Addto}
                  onClick={addlifestyle}
                >Add to Prescription </div>
              </>
              :
              option == "Prescribe note" ?
                <>
                  <div className={classNames.title} onClick={backfunction}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9EBF3" />
                      <path d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z" fill="url(#paint0_linear_102_3785)" />
                      <defs>
                        <linearGradient id="paint0_linear_102_3785" x1="14.8001" y1="12.4999" x2="7.90003" y2="12.4999" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#0B2447" />
                          <stop offset="0.311" stop-color="#0D264C" />
                          <stop offset="0.6768" stop-color="#122E5A" />
                          <stop offset="1" stop-color="#19376D" />
                        </linearGradient>
                      </defs>
                    </svg>

                    Add Note</div>

                  <input className={classNames.draginput} placeholder="Investigation name"
                    style={{
                      marginBottom: "1rem"
                    }}
                    value={addnote.description}
                    onChange={(e) => setaddnote({

                      description: e.target.value
                    })}
                  />






                  <div className={classNames.Addto}
                    onClick={addNotefucntion}
                  >Add to Prescription </div>
                </>


                : option == "Prescribe investigation" ?
                  <>
                    <div className={classNames.title} onClick={backfunction}>

                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9EBF3" />
                        <path d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z" fill="url(#paint0_linear_102_3785)" />
                        <defs>
                          <linearGradient id="paint0_linear_102_3785" x1="14.8001" y1="12.4999" x2="7.90003" y2="12.4999" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>

                      Choose investigation</div>

                    <input className={classNames.draginput} placeholder="Investigation name"
                      style={{
                        marginBottom: "1rem"
                      }}
                      value={investingaData.name}
                      onChange={(e) => setinvestingaData({
                        name: e.target.value,
                        lab: investingaData.lab,
                        description: investingaData.description
                      })}
                    />

                    <input className={classNames.draginput} placeholder="Lab name"
                      style={{
                        marginBottom: "1rem"
                      }}
                      value={investingaData.lab}
                      onChange={(e) => setinvestingaData({
                        name: investingaData.name,
                        lab: e.target.value,
                        description: investingaData.description
                      })}
                    />


                    <textarea className={classNames.draginput} placeholder="Description"
                      style={{
                        height: "90px",
                        paddingTop: '1.3rem'
                      }}
                      value={investingaData.description}
                      onChange={(e) => setinvestingaData({
                        name: investingaData.name,
                        lab: investingaData?.lab,
                        description: e.target.value,
                      })}
                    />

                    <div className={classNames.Addto}
                      onClick={addinvest}
                    >Add to Prescription </div>
                  </>


                  :
                  option == "main" ?
                    <>
                      <div className={classNames.title}>Options</div>
                      {allPerscribeItems?.length > 0 &&
                        allPerscribeItems?.map((eachPerscribe, index) => (
                          <div key={eachPerscribe + index} className={classNames.eachOption}

                            onClick={() => callinerlist(eachPerscribe)}
                          >
                            {eachPerscribe}

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                              <path d="M15.3003 12.2473L11.24 8.21392C11.1649 8.13939 11.0595 8.0968 10.9492 8.0968H9.20443C8.85747 8.0968 8.67729 8.47867 8.91358 8.7132L12.7254 12.4968L8.91358 16.2804C8.67729 16.5149 8.85747 16.8968 9.20443 16.8968H10.9492C11.0595 16.8968 11.1649 16.8545 11.24 16.7797L15.3003 12.7463C15.4418 12.6056 15.4418 12.388 15.3003 12.2473Z" fill="url(#paint0_linear_61_2789)" />
                              <defs>
                                <linearGradient id="paint0_linear_61_2789" x1="8.80642" y1="12.4968" x2="15.4064" y2="12.4968" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#0B2447" />
                                  <stop offset="0.311" stop-color="#0D264C" />
                                  <stop offset="0.6768" stop-color="#122E5A" />
                                  <stop offset="1" stop-color="#19376D" />
                                </linearGradient>
                              </defs>
                            </svg>

                          </div>
                        ))}
                    </>
                    :
                    option == "Prescribe treatment" ?
                      <>
                        <div


                          onClick={() => {
                            setrightsidedname("Prescribe medication")
                            backfunction()
                          }
                          }
                          className={classNames.title}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9EBF3" />
                            <path d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z" fill="url(#paint0_linear_102_3785)" />
                            <defs>
                              <linearGradient id="paint0_linear_102_3785" x1="14.8001" y1="12.4999" x2="7.90003" y2="12.4999" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0B2447" />
                                <stop offset="0.311" stop-color="#0D264C" />
                                <stop offset="0.6768" stop-color="#122E5A" />
                                <stop offset="1" stop-color="#19376D" />
                              </linearGradient>
                            </defs>
                          </svg>

                          Choose treatment</div>
                        {catoriesSummary?.length > 0 &&
                          catoriesSummary?.filter((qitem) =>
                            qitem?.name.includes(globalSearchQuery)
                          )
                            ?.map((eachPerscribe, index) => (
                              <div key={eachPerscribe + index} className={classNames.eachOption}
                                onClick={() => additem(eachPerscribe)}

                              >
                                {eachPerscribe?.name}

                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                  <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_102_3794)" />
                                  <g opacity="0.2">
                                    <path opacity="0.2" d="M9.04433 12.379C6.57106 14.3463 3.81674 15.3581 1.23106 15.4143C-0.680116 12.1541 -0.286625 4.22844 1.9618 1.92381C3.98538 -0.155971 10.3372 -0.549462 13.9909 0.743376C14.8902 4.45328 13.0353 9.17494 9.04433 12.379Z" fill="white" />
                                  </g>
                                  <path d="M14.3281 9.00631C14.3281 9.5684 13.8784 10.0181 13.3163 10.0181H9.94372V13.3907C9.94372 13.9528 9.49402 14.4025 8.93193 14.4025C8.36983 14.4025 7.92014 13.9528 7.92014 13.3907V10.0181H4.54748C3.98538 10.0181 3.53569 9.5684 3.53569 9.00631C3.53569 8.44421 3.98538 7.99452 4.54748 7.99452H7.9201V4.6219C7.9201 4.0598 8.36979 3.61011 8.93189 3.61011C9.49398 3.61011 9.94368 4.0598 9.94368 4.6219V7.99452H13.3163C13.8784 7.99452 14.3281 8.44417 14.3281 9.00631Z" fill="white" />
                                  <defs>
                                    <linearGradient id="paint0_linear_102_3794" x1="8.98844" y1="17.9997" x2="8.98844" y2="0.025855" gradientUnits="userSpaceOnUse">
                                      <stop offset="2.6639e-08" stop-color="#FC4F00" />
                                      <stop offset="1" stop-color="#F79540" />
                                    </linearGradient>
                                  </defs>
                                </svg>

                              </div>
                            ))}
                      </>
                      : option == "Prescribe diet plan" ?


                        <>
                          <div

                            className={classNames.title}>
                            <svg
                              onClick={backfunction}
                              xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                              <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9EBF3" />
                              <path d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z" fill="url(#paint0_linear_102_3785)" />
                              <defs>
                                <linearGradient id="paint0_linear_102_3785" x1="14.8001" y1="12.4999" x2="7.90003" y2="12.4999" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#0B2447" />
                                  <stop offset="0.311" stop-color="#0D264C" />
                                  <stop offset="0.6768" stop-color="#122E5A" />
                                  <stop offset="1" stop-color="#19376D" />
                                </linearGradient>
                              </defs>
                            </svg>

                            Choose diet plan</div>
                          {addingdiethard?.length > 0 &&
                            addingdiethard?.filter((qitem) =>
                              qitem?.name.includes(globalSearchQuery)
                            )
                              ?.map((eachPerscribe, index) => (
                                <div key={eachPerscribe + index} className={classNames.eachOption}
                                  // 

                                  style={{
                                    height: expandable && samevalue == eachPerscribe?._id && "auto",
                                    display: "block"
                                  }}
                                >

                                  <div className={classNames.closesection}>
                                    {eachPerscribe?.name}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      {
                                        expandable && samevalue == eachPerscribe?._id ?
                                          <div className={classNames.close}
                                            onClick={closeexpadingfunction}
                                          >Close</div>
                                          :

                                          <div className={classNames.close}
                                            onClick={() => expandedcall(eachPerscribe)}

                                          >Expand</div>
                                      }



                                      <svg
                                        onClick={() => {
                                          adddietpla(eachPerscribe)
                                          //setbackupDeitdata(eachPerscribe)
                                        }

                                        }
                                        xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_102_3794)" />
                                        <g opacity="0.2">
                                          <path opacity="0.2" d="M9.04433 12.379C6.57106 14.3463 3.81674 15.3581 1.23106 15.4143C-0.680116 12.1541 -0.286625 4.22844 1.9618 1.92381C3.98538 -0.155971 10.3372 -0.549462 13.9909 0.743376C14.8902 4.45328 13.0353 9.17494 9.04433 12.379Z" fill="white" />
                                        </g>
                                        <path d="M14.3281 9.00631C14.3281 9.5684 13.8784 10.0181 13.3163 10.0181H9.94372V13.3907C9.94372 13.9528 9.49402 14.4025 8.93193 14.4025C8.36983 14.4025 7.92014 13.9528 7.92014 13.3907V10.0181H4.54748C3.98538 10.0181 3.53569 9.5684 3.53569 9.00631C3.53569 8.44421 3.98538 7.99452 4.54748 7.99452H7.9201V4.6219C7.9201 4.0598 8.36979 3.61011 8.93189 3.61011C9.49398 3.61011 9.94368 4.0598 9.94368 4.6219V7.99452H13.3163C13.8784 7.99452 14.3281 8.44417 14.3281 9.00631Z" fill="white" />
                                        <defs>
                                          <linearGradient id="paint0_linear_102_3794" x1="8.98844" y1="17.9997" x2="8.98844" y2="0.025855" gradientUnits="userSpaceOnUse">
                                            <stop offset="2.6639e-08" stop-color="#FC4F00" />
                                            <stop offset="1" stop-color="#F79540" />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    </div>
                                  </div>

                                  {

                                    expandable && samevalue == eachPerscribe?._id &&
                                    <>
                                      <div className={classNames.listItem}>
                                        <div>Amount of days:</div>
                                        <div>{eachPerscribe?.numofdays}</div>


                                      </div>
                                      <div className={classNames.listItem}>
                                        <div>Starts on:</div>
                                        <div>{eachPerscribe?.startingday}</div>


                                      </div>


                                      <div className={classNames.listItem}

                                        style={{
                                          lineHeight: "25px"
                                        }}

                                      >
                                        {eachPerscribe?.description}
                                      </div>
                                      {
                                        eachPerscribe?.plandetails?.map((item, z) => {
                                          return (
                                            <>
                                              <div className={classNames.questionsection}>
                                                <div className={classNames.questionsectionName}>{item?.day}</div>
                                                {
                                                  item?.dayplan?.map((such, p) => {
                                                    return (
                                                      <>

                                                        <div className={classNames.questionsectBox}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              alignItems: "center",
                                                              justifyContent: "space-between"

                                                            }}
                                                            onClick={() => {
                                                              setdplandropdown(true);
                                                              setdietid(such?._id)
                                                            }}
                                                          >
                                                            <div>{such?.time}</div>
                                                            <div>{such?.unitamount} {such?.unitname} {such?.kitchenItemName}

                                                              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                <path d="M3.69846 4.91959L6.90683 1.84362C6.96612 1.7867 7 1.70688 7 1.62328V0.301496C7 0.0386509 6.69624 -0.0978492 6.50968 0.0811595L3.5 2.96891L0.490321 0.0811595C0.303761 -0.0978492 -4.76837e-07 0.0386509 -4.76837e-07 0.301496V1.62328C-4.76837e-07 1.70688 0.0336499 1.7867 0.0931659 1.84362L3.30154 4.91959C3.41347 5.0268 3.58653 5.0268 3.69846 4.91959Z" fill="url(#paint0_linear_145_4817)" />
                                                                <defs>
                                                                  <linearGradient id="paint0_linear_145_4817" x1="3.50001" y1="-2.63166e-05" x2="3.50001" y2="4.99999" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#0B2447" />
                                                                    <stop offset="0.311" stop-color="#0D264C" />
                                                                    <stop offset="0.6768" stop-color="#122E5A" />
                                                                    <stop offset="1" stop-color="#19376D" />
                                                                  </linearGradient>
                                                                </defs>
                                                              </svg>
                                                            </div>
                                                          </div>
                                                          {
                                                            dplandropdown && such?._id == dietid &&
                                                            <>
                                                              <div className="deitpaldroplist">
                                                                <div className="inner">
                                                                  <div className="name">New Quantity:</div>
                                                                  <input placeholder="0.00"

                                                                    onChange={(e) => changedietpainAmount(eachPerscribe, z, p, e.target.value)}
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="deitpaldroplist">
                                                                <div className="inner">
                                                                  <div className="name">Change Unit:</div>
                                                                  <input placeholder="ex. Grams"
                                                                    onChange={(e) => changedietpainNamet(eachPerscribe, z, p, e.target.value)}
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="deitpaldroplist">
                                                                <div className="inner">
                                                                  <div className="name">New Time:</div>
                                                                  <input placeholder="HH:MM:AA"

                                                                    onChange={(e) => changedietpainTime(eachPerscribe, z, p, e.target.value)}
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="deitpaldroplist"
                                                                onClick={() => setshowselecteddropdown(!showselecteddropdown)}
                                                              >
                                                                <div className="inner">
                                                                  <div className="name">

                                                                    {kichenitemName == "" ? "Switch kitchen item" :
                                                                      kichenitemName
                                                                    }

                                                                  </div>

                                                                </div>
                                                              </div>
                                                              {
                                                                showselecteddropdown &&
                                                                <div className="dropdownkitechitem">

                                                                  {kitchenItem?.map(item => {
                                                                    return (
                                                                      <>
                                                                        <div
                                                                          onClick={() => changedietKitenitem(eachPerscribe, z, p, item)}
                                                                        >
                                                                          {item?.name}
                                                                        </div>
                                                                      </>
                                                                    )
                                                                  })}

                                                                </div>
                                                              }


                                                            </>
                                                          }

                                                        </div>

                                                      </>
                                                    )
                                                  })
                                                }

                                              </div>

                                            </>
                                          )
                                        })
                                      }
                                    </>
                                  }






                                </div>
                              ))}
                        </>

                        :

                        <>
                          <div className={classNames.title}>Options</div>
                          {allPerscribeItems?.length > 0 &&
                            allPerscribeItems?.map((eachPerscribe, index) => (
                              <div key={eachPerscribe + index} className={classNames.eachOption}

                                onClick={() => setoption(eachPerscribe)}
                              >
                                {eachPerscribe}

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                                  <path d="M15.3003 12.2473L11.24 8.21392C11.1649 8.13939 11.0595 8.0968 10.9492 8.0968H9.20443C8.85747 8.0968 8.67729 8.47867 8.91358 8.7132L12.7254 12.4968L8.91358 16.2804C8.67729 16.5149 8.85747 16.8968 9.20443 16.8968H10.9492C11.0595 16.8968 11.1649 16.8545 11.24 16.7797L15.3003 12.7463C15.4418 12.6056 15.4418 12.388 15.3003 12.2473Z" fill="url(#paint0_linear_61_2789)" />
                                  <defs>
                                    <linearGradient id="paint0_linear_61_2789" x1="8.80642" y1="12.4968" x2="15.4064" y2="12.4968" gradientUnits="userSpaceOnUse">
                                      <stop stop-color="#0B2447" />
                                      <stop offset="0.311" stop-color="#0D264C" />
                                      <stop offset="0.6768" stop-color="#122E5A" />
                                      <stop offset="1" stop-color="#19376D" />
                                    </linearGradient>
                                  </defs>
                                </svg>

                              </div>
                            ))}
                        </>
        }
      </div>

      <div className={classNames.rightside}>
        Prescription

        {
          rightsidedname != "Prescribe note" && rightsidedname != "Prescribe diet plan" && rightsidedname != "Prescribe medication" && rightsidedname != "Prescribe investigation" && rightsidedname != "Prescribe life style advice" ?
            <>
              <div className={classNames.rightsubdiv}>
                {storetrement?.length > 0 &&
                  storetrement?.map((eachPerscribe, index) => {

                    const keyToCheck = 'duplicatelocationname';
                    const keyExists = keyToCheck in eachPerscribe;
                    const keyToCheck1 = 'duplicatetherapyname';
                    const keyExists1 = keyToCheck1 in eachPerscribe;
                    const keyToCheck2 = 'duplicateroomname';
                    const keyExists2 = keyToCheck2 in eachPerscribe;
                    const keyToCheck3 = 'duplicatertime';
                    const keyExists3 = keyToCheck3 in eachPerscribe;

                    return (

                      <div key={eachPerscribe + index} className={classNames.eachOptionright}


                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%"
                          }}
                        >
                          {eachPerscribe?.name}

                          <svg
                            onClick={() => {
                              additemback(eachPerscribe)


                            }}
                            xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                            <g opacity="0.2">
                              <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                            </g>
                            <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                            <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                            <defs>
                              <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0B2447" />
                                <stop offset="0.311" stop-color="#0D264C" />
                                <stop offset="0.6768" stop-color="#122E5A" />
                                <stop offset="1" stop-color="#19376D" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>

                        <div>
                          <div className="labelsectedTheraply">
                            <label
                              onClick={() => addpremandeddrop("Recommend", index)}
                              style={{
                                background: eachPerscribe?.remandeddropdown == "Recommend" && "linear-gradient(0deg, #FC4F00 0%, #F79540 100%)",
                                color: eachPerscribe?.remandeddropdown == "Recommend" && "white"
                              }}
                            >Recommend</label>
                            <label

                              style={{
                                background: eachPerscribe?.remandeddropdown == "Autobook" && "linear-gradient(0deg, #FC4F00 0%, #F79540 100%)",
                                color: eachPerscribe?.remandeddropdown == "Autobook" && "white"
                              }}

                              onClick={() => addpremandeddrop("Autobook", index)}
                            >Autobook</label>
                          </div>
                          {
                            eachPerscribe?.remandeddropdown == "Recommend" &&
                            <textarea
                              value={eachPerscribe?.des}
                              onChange={(e) => chnagetreatment(e, index)}
                              style={{
                                width: "100%",
                                fontFamily: "Montserrat",
                                fontSize: "11px",
                                fontWeight: "400",
                                lineHeight: "13.41px",
                                padding: "1rem",
                                border: " 1px solid #E5E5E5",
                                borderRadius: "5px",
                                marginTop: "0.7rem"
                              }}
                              placeholder="Description"

                            />
                          }
                          {
                            eachPerscribe?.remandeddropdown == "Autobook" &&
                            <div className="slectedtheryauotbook">
                              {/* < div className='titleseleted' >Select location</div > */}

                              <div className='dropdownParentSection'
                                //  ref={ref1}
                                style={{
                                  width: "100%"
                                }}
                              >
                                <div className='dropdown-planb'
                                  onClick={() => settherpyService({
                                    toggle: !therpyService?.toggle,
                                    name: index
                                  })}

                                >
                                  <div

                                    style={{
                                      display: "flex",
                                      alignItems: 'center'
                                    }}
                                  >
                                    {
                                      !keyExists1 &&
                                      "Select therapist"
                                    }

                                    {eachPerscribe?.duplicatetherapyname == "" ? "Select location" : eachPerscribe?.duplicatetherapyname}
                                  </div>
                                  {
                                    loctionService.toggle ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                      </svg>
                                      :

                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                      </svg>
                                  }


                                </div>


                                {
                                  therpyService.toggle && index == therpyService?.name &&
                                  <div className='dropdwonlist'

                                  >
                                    {
                                      therphsydata?.map(item => {
                                        return (
                                          <div className='listitemName'
                                            onClick={() =>
                                              theryduplicatedatastore(item, index)

                                            }
                                          >
                                            {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                          </div>
                                        )
                                      })
                                    }

                                  </div>
                                }



                              </div>







                              <div className='dropdownParentSection'
                                //  ref={ref1}
                                style={{
                                  width: "100%"
                                }}
                              >
                                <div className='dropdown-planb'
                                  onClick={() => setloctionService({
                                    toggle: !loctionService?.toggle,
                                    name: index
                                  })}

                                >
                                  <div

                                    style={{
                                      display: "flex",
                                      alignItems: 'center'
                                    }}
                                  >
                                    {
                                      !keyExists &&
                                      "Select location"
                                    }

                                    {eachPerscribe?.duplicatelocationname == "" ? "Select location" : eachPerscribe?.duplicatelocationname}
                                  </div>
                                  {
                                    loctionService.toggle ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                      </svg>
                                      :

                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                      </svg>
                                  }


                                </div>


                                {
                                  loctionService.toggle && index == loctionService?.name &&
                                  <div className='dropdwonlist'

                                  >
                                    {
                                      locationdrop?.map(item => {
                                        return (
                                          <div className='listitemName'
                                            onClick={() =>
                                              lacationdatastore(item, index)

                                            }
                                          >
                                            {item?.name}
                                          </div>
                                        )
                                      })
                                    }

                                  </div>
                                }



                              </div>






                              <div className='dropdownParentSection'
                                //  ref={ref1}
                                style={{
                                  width: "100%"
                                }}
                              >
                                <div className='dropdown-planb'
                                  onClick={() => setroomService({
                                    toggle: !roomService?.toggle,
                                    name: index
                                  })}

                                >
                                  <div

                                    style={{
                                      display: "flex",
                                      alignItems: 'center'
                                    }}
                                  >
                                    {
                                      !keyExists2 &&
                                      "Select room"
                                    }

                                    {eachPerscribe?.duplicateroomname == "" ? "Select room" : eachPerscribe?.duplicateroomname}
                                  </div>
                                  {
                                    roomService.toggle ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                      </svg>
                                      :

                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                      </svg>
                                  }


                                </div>


                                {
                                  roomService.toggle && index == roomService?.name &&
                                  <div className='dropdwonlist'

                                  >
                                    {
                                      roomdata?.map(item => {
                                        return (
                                          <div className='listitemName'
                                            onClick={() =>
                                              Roomdatastore(item, index)

                                            }
                                          >
                                            {item?.name}
                                          </div>
                                        )
                                      })
                                    }

                                  </div>
                                }



                              </div>
                              <div className='dropdownParentSection'
                                // ref={ref1}
                                style={{
                                  width: "100%"
                                }}
                              >


                                <div className='dropdown-planb'

                                // onClick={() => setlead({
                                //   toggle: !lead?.toggle,
                                //   name: lead?.name
                                // })}

                                >
                                  <div

                                    style={{
                                      display: "flex",
                                      alignItems: 'center',
                                      width: "100%"
                                    }}
                                  >
                                    <MyDatePicker
                                      index={index}

                                      storetrement={storetrement}
                                      setstoretrement={setstoretrement}
                                      value={"eachPerscribe?.duplicateDate"

                                      }
                                    />

                                    {/* <input
                                    type="text"
                                    id="date-input"
                                    key={index}
                                    ref={datepickerRef2}
                                    className="dateinput"
                                    placeholder="Click To Select"

                                  /> */}
                                  </div>
                                </div>
                              </div>
                              <div className='dropdownParentSection'
                                style={{
                                  width: "100%"
                                }}
                              >
                                <div className='dropdown-planb'

                                  onClick={() => settimeshow({
                                    toggle: !timeshow?.toggle,
                                    name: index
                                  })}

                                >
                                  <div

                                    style={{
                                      display: "flex",
                                      alignItems: 'center'
                                    }}
                                  >

                                    {
                                      !keyExists3 &&
                                      "Select time"
                                    }

                                    {eachPerscribe?.duplicatertime == "" ? "Select time" : eachPerscribe?.duplicatertime}
                                  </div>
                                  {
                                    timeshow.toggle ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                      </svg>
                                      :

                                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                      </svg>
                                  }


                                </div>


                                {
                                  timeshow.toggle && index == timeshow?.name &&
                                  <div className='dropdwonlist'
                                    style={{
                                      height: "12rem",
                                      overflow: " scroll"
                                    }}
                                  >
                                    {
                                      timpicker?.map(item => {
                                        return (
                                          <div className='listitemName'

                                            onClick={() =>
                                              timeatastore(item, index)

                                            }
                                          // onClick={() =>
                                          //   selettimeshow(item)

                                          // }
                                          >


                                            {item}
                                          </div>
                                        )
                                      })
                                    }

                                  </div>
                                }










                              </div>

                            </div>

                          }
                        </div>
                      </div>
                    )
                  })}

              </div>
              <div className={classNames.bottomlabel}>
                <div className={classNames.leftside}
                  style={{
                    cursor: "not-allowed"
                  }}
                >
                  Push To Almari<br />
                  <div
                    style={{
                      fontSize: "10px"
                    }}
                  >(Coming Soon)</div>

                </div>
                <div className={classNames.rightside}
                  style={{
                    cursor: "pointer"
                  }}

                  onClick={savdmediciance}
                >
                  Submit
                </div>
              </div>
            </>
            :
            <>
              <div className={classNames.rightsubdiv}>

                <>



                  {dietplan?.length > 0 &&
                    dietplan?.map((eachPerscribe, index) => (
                      <div key={eachPerscribe + index} className={classNames.eachOptionright}
                        style={{
                          display: "flex"
                        }}
                        onClick={() => additembackdiet(eachPerscribe)}
                      >

                        {eachPerscribe?.name}

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                          <g opacity="0.2">
                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                          </g>
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                          <defs>
                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0B2447" />
                              <stop offset="0.311" stop-color="#0D264C" />
                              <stop offset="0.6768" stop-color="#122E5A" />
                              <stop offset="1" stop-color="#19376D" />
                            </linearGradient>
                          </defs>
                        </svg>

                      </div>
                    ))}

                  {storetrement?.length > 0 &&
                    storetrement?.map((eachPerscribe, index) => (
                      <div key={eachPerscribe + index} className={classNames.eachOptionright}
                        style={{
                          display: "flex"
                        }}
                        onClick={() => additemback(eachPerscribe)}
                      >

                        {eachPerscribe?.name}

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                          <g opacity="0.2">
                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                          </g>
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                          <defs>
                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0B2447" />
                              <stop offset="0.311" stop-color="#0D264C" />
                              <stop offset="0.6768" stop-color="#122E5A" />
                              <stop offset="1" stop-color="#19376D" />
                            </linearGradient>
                          </defs>
                        </svg>

                      </div>
                    ))}






                  {lifestyleseleted?.length > 0 &&
                    lifestyleseleted?.map((eachPerscribe, index) => (
                      <div key={eachPerscribe + index} className={classNames.eachOptionright}
                        style={{
                          display: "flex"
                        }}
                        onClick={() => removelifestyle(eachPerscribe)}
                      >

                        {eachPerscribe?.name} | {eachPerscribe?.description}

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                          <g opacity="0.2">
                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                          </g>
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                          <defs>
                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0B2447" />
                              <stop offset="0.311" stop-color="#0D264C" />
                              <stop offset="0.6768" stop-color="#122E5A" />
                              <stop offset="1" stop-color="#19376D" />
                            </linearGradient>
                          </defs>
                        </svg>

                      </div>
                    ))}





                  {investingaSelected?.length > 0 &&
                    investingaSelected?.map((eachPerscribe, index) => (
                      <div key={eachPerscribe + index} className={classNames.eachOptionright}
                        style={{
                          display: "flex"
                        }}
                        onClick={() => removeInvesting(eachPerscribe)}
                      >

                        {eachPerscribe?.name} |{eachPerscribe?.lab} | {eachPerscribe?.description}

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                          <g opacity="0.2">
                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                          </g>
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                          <defs>
                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0B2447" />
                              <stop offset="0.311" stop-color="#0D264C" />
                              <stop offset="0.6768" stop-color="#122E5A" />
                              <stop offset="1" stop-color="#19376D" />
                            </linearGradient>
                          </defs>
                        </svg>

                      </div>
                    ))}



                  {addnoteseleted?.length > 0 &&
                    addnoteseleted?.map((eachPerscribe, index) => (
                      <div key={eachPerscribe + index} className={classNames.eachOptionright}
                        style={{
                          display: "flex"
                        }}
                        onClick={() => removenote(eachPerscribe)}
                      >

                        {eachPerscribe?.description}

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                          <g opacity="0.2">
                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                          </g>
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                          <defs>
                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0B2447" />
                              <stop offset="0.311" stop-color="#0D264C" />
                              <stop offset="0.6768" stop-color="#122E5A" />
                              <stop offset="1" stop-color="#19376D" />
                            </linearGradient>
                          </defs>
                        </svg>

                      </div>
                    ))}
                  {storeinfoMedicance?.length > 0 &&
                    storeinfoMedicance?.map((eachPerscribe, index) => (
                      <div key={eachPerscribe + index} className={classNames.eachOptionright}
                        style={{
                          display: "flex"
                        }}
                        onClick={() => removemediance(eachPerscribe)}
                      >

                        {eachPerscribe?.name} |{eachPerscribe?.dosage} | {eachPerscribe?.frequency}  | {eachPerscribe?.lengthOfTime}

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                          <g opacity="0.2">
                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                          </g>
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                          <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                          <defs>
                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0B2447" />
                              <stop offset="0.311" stop-color="#0D264C" />
                              <stop offset="0.6768" stop-color="#122E5A" />
                              <stop offset="1" stop-color="#19376D" />
                            </linearGradient>
                          </defs>
                        </svg>

                      </div>
                    ))}
                </>



              </div>
              <div className={classNames.bottomlabel}>
                <div className={classNames.leftside}
                  style={{
                    cursor: "not-allowed"
                  }}
                >
                  Push To Almari<br />
                  <div
                    style={{
                      fontSize: "10px"
                    }}
                  >(Coming Soon)</div>

                </div>
                <div className={classNames.rightside}
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={savdmediciance}
                >
                  Submit
                </div>
              </div>
            </>

        }


      </div>


    </div >
  );
};

const PatientProfileForm = ({
  localRefresh,
  stateValue, sessionValue,
  selectedMenu,
  setSelectedMenu,
}) => {
  const [patientProfileForm, setPatientProfileForm] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");

  //functions
  async function getPatientProfileForms() {
    try {
      let response = await axios.get(
        `https://uatamma.apimachine.com/api/getuserpatient?clientid=${sessionValue?.clientid}`
      );
      if (response?.data?.data?.length > 0) {
        setPatientProfileForm(response?.data?.data);
      }
      console.log(response, "Get patient profile forms response", stateValue);
    } catch (error) {
      console.log(error, "Get patient profile forms response");
    }
  }

  //rendering
  useEffect(() => {
    getPatientProfileForms();
  }, [sessionValue?.clientid]);
  console.log("selectedMenu", selectedMenu)
  return (
    // <div className={classNames.preEnrollmentForm}>
    //   {selectedMenu === "Patient Profile Forms Questions"
    //     ? selectedPatient?.questions?.length > 0
    //       ? selectedPatient?.questions?.map((eachQuestion, index) => {
    //         return (
    //           <div
    //             className={classNames.eachQuestion}
    //             key={eachQuestion?.name + index}
    //           >
    //             <div className={classNames.title}>{eachQuestion?.name}</div>
    //             <div className={classNames.answer}>
    //               {eachQuestion?.answer}
    //             </div>
    //           </div>
    //         );
    //       })
    //       : ""
    //     : patientProfileForm.length > 0
    //       ? patientProfileForm.map((eachPatient, index) => {
    //         return (
    //           <div
    //             className={classNames.eachQuestion}
    //             key={eachPatient?.formname + index}
    //           >
    //             {index == 0 && (
    //               <div
    //                 className={classNames.title}
    //                 style={{ marginBottom: "1.2rem" }}
    //               >
    //                 Select patient profile
    //               </div>
    //             )}
    //             <div
    //               className={classNames.answer}
    //               onClick={() => {
    //                 setSelectedPatient(eachPatient);
    //                 setSelectedMenu("Patient Profile Forms Questions");
    //               }}
    //             >
    //               {eachPatient?.formname}fhgfgh
    //             </div>
    //           </div>
    //         );
    //       })
    //       : ""}
    // </div>

    <div className={classNames.preEnrollmentForm}>
      {selectedMenu === "Patient Profile Forms Questions" ?
        <>
          {
            selectedPatient?.questions?.length > 0 &&
            <>
              {selectedPatient?.questions?.map((eachQuestion, index) => {
                return (
                  <div
                    className={classNames.eachQuestion}
                    key={eachQuestion?.name + index}
                  >
                    <div className={classNames.title}>{eachQuestion?.name}</div>
                    <div className={classNames.answer}>
                      {eachQuestion?.answer}
                    </div>
                  </div>
                );
              })
              }
            </>

          }
        </>
        :
        <>
          {
            patientProfileForm.length > 0
            && patientProfileForm.map((eachPatient, index) => {
              return (
                <div
                  className={classNames.eachQuestion}
                  key={eachPatient?.formname + index}
                >
                  {index == 0 && (
                    <div
                      className={classNames.title}
                      style={{ marginBottom: "1.2rem" }}
                    >
                      Select patient profile
                    </div>
                  )}
                  <div
                    className={classNames.answer}
                    onClick={() => {
                      setSelectedPatient(eachPatient);
                      setSelectedMenu("Patient Profile Forms Questions");
                    }}
                  >
                    {eachPatient?.formname}
                  </div>
                </div>
              );
            })
          }
        </>


      }
    </div >

  );
};


const MyDatePicker = ({ index, setstoretrement, storetrement }) => {
  const datepickerRef2 = useRef(null);
  console.log("llkl", storetrement)

  useEffect(() => {
    if (datepickerRef2?.current) {
      // alert(index)
      const options = {
        dateFormat: "m/d/Y",
        // Other options here

        onChange: function (selectedDates, dateStr, instance) {

          const result = storetrement.map((group, z) => {
            console.log("czxczxczxc", group)
            if (z == index) {
              return {
                ...group,

                duplicateDate: dateStr,

              };
            }
            return group;
          })

          setstoretrement(result)







          console.log("Selected start date:", index);
          // setappointdate(dateStr)

        },
      };


      flatpickr(datepickerRef2.current, options);
    }
  }, [datepickerRef2.current, index, storetrement]);
  return <input
    style={{
      width: "100%",
      border: "0px",
      height: "100%",
      fontSize: "13px",
      color: "#5F6163"

    }}
    key={index}
    type="text"
    placeholder="Select date"
    ref={datepickerRef2}
  //value={dates[index] || ''}
  />
};