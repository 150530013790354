import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Session.module.scss";
import "./Sessionpage.scss";
//assets
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";

import moment from "moment/moment";
import PipelineSubmenu from "./Submenu";
import Drawer from "react-modern-drawer";
import { EachCustomDropdown, EachInput } from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const Organization = ({ checkBoxStage, labelName, setCheckBoxStage, selectedRow, setSelectedRow }) => {
    const {
        email,
        actionBtns,
        setActionBtns,
        globalSearchQuery,
        dropDowndownvalue,
        // isSpecialist,
    } = useContext(GlobalContext);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isOpen, setIsOpen] = useState(null);
    const [formErr, setFormErr] = useState(null);
    // const [answerName, setAnswerName] = useState([]);
    const [answerName, setAnswerName] = useState([]);

    const [sidebarStep, setSidebarStep] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [session, setsession] = useState([]);
    const [consulation, setconsulation] = useState({
        constultandName: [],
        constultandid: [],
        locationid: "",
        locationName: "",
        spaceid: "",
        spaceName: "",
        name: "",
        description: "",
        coverPhoto: "",
        duration: "",

        cost: "",
    });
    console.log("selectedFormselectedForm", selectedForm);

    const [specialistlistitem, setspecialistlistitem] = useState("");

    const removeMe = (index) => {
        const temp = [...consulation?.constultandName];
        temp.splice(index, 1);
        setconsulation({
            constultandName: temp,
        });
    };

    const [wedgedata, setwedgedata] = useState([]);
    const [isSpecialist, setIsSpecialist] = useState(null);
    useEffect(() => {
        tablefucntion();
    }, [dropDowndownvalue, isSpecialist]);
    const [locationdata, setlocationdata] = useState({});
    const history = useNavigate();
    useEffect(() => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getspecialistdetail?email=${email}`
            )
            .then(({ data }) => {
                if (data.success) {
                    setIsSpecialist(data?.data[0]?.specialistDetails[0]);
                } else {
                    setIsSpecialist(false);
                }
            });
    }, []);

    const tablefucntion = () => {
        if (isSpecialist === false) {
            axios
                .get(
                    `https://uatamma.apimachine.com/api/getconsultsessions?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))
                        ?.TeamUsername || ""
                    }`
                )
                .then((res) => {
                    console.log("cxczxczxc", res?.data?.data);
                    setsession(res?.data?.data);
                });
        } else if (isSpecialist !== null && isSpecialist !== false) {
            axios
                .get(
                    `https://uatamma.apimachine.com/api/getconsultsessions?specialistid=${isSpecialist?.specialistid}`
                )
                .then((res) => {
                    console.log("cxczxczxc", res?.data?.data);
                    setsession(res?.data?.data);
                });
        }
    };

    const [player, setplayer] = useState([]);

    const submitservicessFucntion = () => {
        let pop = consulation?.constultandName?.map((item) => {
            return item?.id;
        });
        console.log("PoP", pop);

        axios
            .post("https://uatamma.apimachine.com/api/addconsultationservice", {
                teamplayerid: consulation?.spaceid,
                locationid: consulation?.locationid,
                serviceids: pop,
            })
            .then(({ data }) => {
                if (data.status) {
                    toastify("Added succesfully!", "success");
                    setCheckBoxStage("");
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                } else {
                    toastify(data?.message, "error");
                }
            });
    };

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    const monthNames = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    console.log("cxc66666zxczxc", player);
    const [active, setactive] = useState("Pre Assessment Form");

    const [page, setpage] = useState({ title: "main" });
    const [getsesion, setgetsesion] = useState({});
    const [show, setshow] = useState(null);
    useEffect(() => {
        setpage({ title: "main" });
        setshow(null);
        setsublist("Pre Assessment Form");
        setactive("Pre Assessment Form");
        setquestionslist([]);
        setexistpreformdata([]);
    }, []);

    const [questionslist, setquestionslist] = useState([]);

    const [datalist, setdatalist] = useState([
        {
            name: "",
            anstype: "",
            requried: true,
            toggle: false,
        },
    ]);

    const [preform, setpreform] = useState([
        {
            name: "",
            anstype: "",
            ans: "",
            requried: true,
        },
    ]);
    console.log("fiusfbdssbfsn", getsesion);

    const getquestion = (e, g) => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getassessment?preassessmentid=${g}`
            )
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                if (res?.data?.success) {
                    setquestionslist(res?.data?.data?.[0]?.questions);

                    let pop = res?.data?.data?.[0]?.questions?.map((item, i) => {
                        return {
                            name: "",
                            anstype: "",
                            ans: "",
                            requried: item?.isMandatory,
                        };
                    });
                    console.log("pop", pop);
                    setpreform(pop);
                } else {
                    setpreform([
                        {
                            name: "",
                            anstype: "",
                            ans: "",
                            requried: true,
                        },
                    ]);
                    setquestionslist([]);
                }
            });

        exitinggetquestion(e, g);
    };

    const [existpreformdata, setexistpreformdata] = useState([]);
    const exitinggetquestion = (e, g) => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getuserassessment?preassessmentid=${g}&clientid=${getsesion?.clientid}&bookingid=${e}`
            )
            .then((res) => {
                console.log("12344", res?.data?.data);
                if (res?.data?.success) {
                    setexistpreformdata(res?.data?.data?.[0]?.questions);

                    // setquestionslist(res?.data?.data?.[0]?.questions)
                } else {
                    setexistpreformdata([]);
                    // setquestionslist([])
                }
            });
    };

    const [dop, setdop] = useState(null);

    const [wedgetoggle, setwedgetoggle] = useState([
        {
            toggle: false,
        },
    ]);

    const tablefucntion1 = (e) => {
        axios
            .get(`https://uatamma.apimachine.com/api/getwidget?specialistid=${e}`)
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                if (res.data?.success) {
                    setwedgedata(res?.data?.data);
                    let pop = res?.data?.data?.map((item, i) => {
                        return {
                            toggle: false,
                        };
                    });
                    console.log("pop", pop);
                    setwedgetoggle(pop);
                    // addFields(res?.data?.data)
                } else {
                    setwedgetoggle([
                        {
                            toggle: false,
                        },
                    ]);
                    setwedgedata([]);
                    // addFields(res?.data?.data?.[0]?.questions)
                }
            });
    };

    const wedgetogglefunction = (index) => {
        let data = [...wedgetoggle];
        data[index].toggle = !data[index].toggle;

        setwedgetoggle(data);
    };

    const handleFormChangePreform = (index, event, g) => {
        let data = [...preform];

        data[index].anstype = g;

        data[index][event.target.name] = event.target.value;
        console.log("ppppp", data);
        setpreform(data);
    };

    const dropdownaddedpreform = (index, item, tyep) => {
        let data = [...preform];
        data[index].anstype = tyep;
        data[index].name = item;

        setpreform(data);
        setshowdrop(false);
    };

    const handleFormChange = (index, event, g) => {
        let data = [...datalist];
        data[index].anstype = g;

        data[index][event.target.name] = event.target.value;
        setdatalist(data);
    };

    const dropdownadded = (index, item, tyep) => {
        let data = [...datalist];
        data[index].anstype = tyep;
        data[index].name = item;

        setdatalist(data);
        setshowdrop(false);
    };

    console.log("ryufsgdfsjh", wedgetoggle);

    const selected = (e, g) => {
        addFields(e?.questions);
        setwidgenumber(g);
    };
    const addFields = (e) => {
        let newfield = { name: "", anstype: "", requried: true };

        let pop = e?.map((item, i) => {
            return {
                name: "",
                anstype: "",
                requried: item?.isMandatory,
                toggle: false,
            };
        });
        console.log("pop", pop);
        setdatalist(pop);
    };
    console.log("datalist", datalist);
    const [sublist, setsublist] = useState("Pre Assessment Form");
    const [showdrop, setshowdrop] = useState(false);
    const [widgenumber, setwidgenumber] = useState(null);

    const [isToggleOn, setisToggleOn] = useState(false);
    let loc = window.location.pathname;
    let finallo = loc?.split("/");
    const sublistswtch = () => {
        switch (sublist) {
            case "Widgets":
                return (
                    <div className="innerSectionSession">
                        {/* <div className="buttonsave">
                            <div>Save</div>
                        </div> */}
                        {wedgedata?.map((such, i) => {
                            // console.log("iten34535", item)

                            return (
                                <>
                                    {widgenumber == i ? (
                                        <div className="widgetparent">
                                            <div
                                                className="namewidget"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setwidgenumber(null)}
                                            >
                                                {such?.name}

                                                <svg
                                                    onClick={() => setwidgenumber(null)}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="7"
                                                    height="6"
                                                    viewBox="0 0 7 6"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                                        fill="#5A5656"
                                                    />
                                                </svg>
                                            </div>

                                            {wedgetoggle[i]?.toggle ? (
                                                ""
                                            ) : (
                                                <>
                                                    {such?.questions?.map((item, j) => {
                                                        if (item?.answerType == "Dropdown") {
                                                            return (
                                                                <div className="questionsection">
                                                                    <div className="queTitle">{item?.name}</div>
                                                                    <div
                                                                        className="queTitledrop"
                                                                        onClick={() => {
                                                                            setshowdrop(!showdrop);
                                                                            setdop(j);
                                                                        }}
                                                                    >
                                                                        {datalist?.[j]?.name}
                                                                        {dop == j && showdrop ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="7"
                                                                                height="6"
                                                                                viewBox="0 0 7 6"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                                                                    fill="#5A5656"
                                                                                />
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="7"
                                                                                height="6"
                                                                                viewBox="0 0 7 6"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                                                                    fill="#5A5656"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </div>
                                                                    {dop == j && showdrop && (
                                                                        <div className="dropdonwlistdata">
                                                                            {item?.dropdownOptions?.map((item) => {
                                                                                return (
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            dropdownadded(j, item, "dropdown")
                                                                                        }
                                                                                    >
                                                                                        {item}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div className="questionsection">
                                                                    <div className="queTitle">{item?.name}</div>
                                                                    <input
                                                                        className="queAnswerinput"
                                                                        name="name"
                                                                        value={datalist.name}
                                                                        onChange={(event) =>
                                                                            handleFormChange(j, event, "String")
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            )}

                                            {/* 
                                                    {
                                                        wedgedata?.length > 0 &&

                                                        <div className="ToggleSwitch"
                                                            onClick={() => wedgetogglefunction(i)}
                                                        >
                                                            <div className={wedgetoggle[i]?.toggle ? 'knob active' : 'knob'} />
                                                            {wedgetoggle[i]?.toggle ? 'Existing Form' : 'New Form'}
                                                        </div>
                                                    } */}
                                            <div className="saveSection">
                                                <div>Save</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="widgetparent"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => selected(such, i)}
                                        >
                                            <div className="namewidget">
                                                {such?.name}

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="7"
                                                    height="6"
                                                    viewBox="0 0 7 6"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                                        fill="#5A5656"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        })}
                    </div>
                );
                break;
            case "Pre Assessment Form":
                return (
                    <>
                        <div className="innerSectionSession">
                            {isToggleOn ? (
                                <>
                                    {existpreformdata?.map((s, j) => {
                                        return (
                                            <div className="questionsection" key={j}>
                                                <div className="queTitle">{s?.name}</div>
                                                <input className="queAnswerinput" value={s?.answer} />
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {questionslist?.map((item, j) => {
                                        if (item?.answerType == "dropdown") {
                                            return (
                                                <div className="questionsection">
                                                    <div className="queTitle">{item?.name}</div>
                                                    <div
                                                        className="queTitledrop"
                                                        onClick={() => {
                                                            setshowdrop(!showdrop);
                                                            setdop(j);
                                                        }}
                                                    >
                                                        {preform?.[j]?.name}
                                                        {dop == j && showdrop ? (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="7"
                                                                height="6"
                                                                viewBox="0 0 7 6"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                                                    fill="#5A5656"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="7"
                                                                height="6"
                                                                viewBox="0 0 7 6"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                                                    fill="#5A5656"
                                                                />
                                                            </svg>
                                                        )}
                                                    </div>
                                                    {dop == j && showdrop && (
                                                        <div className="dropdonwlistdata">
                                                            {item?.dropdownOptions?.map((item) => {
                                                                return (
                                                                    <div
                                                                        onClick={() =>
                                                                            dropdownaddedpreform(j, item, "dropdown")
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="questionsection">
                                                    <div className="queTitle">{item?.name}</div>
                                                    <input
                                                        className="queAnswerinput"
                                                        name="name"
                                                        value={preform?.[j]?.name}
                                                        onChange={(event) =>
                                                            handleFormChangePreform(j, event, "String")
                                                        }
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </>
                            )}

                            {/* {
                                questionslist?.map(item => {
                                    return (
                                        <div className="questionsection">

                                            <div className="queTitle">
                                                {item?.name}
                                            </div>
                                            <div className="queAnswer">{item?.answer}</div>
                                        </div>
                                    )
                                })
                            } */}

                            {questionslist?.length > 0 && (
                                <div
                                    className="ToggleSwitch"
                                    onClick={() => setisToggleOn(!isToggleOn)}
                                >
                                    <div className={isToggleOn ? "knob active" : "knob"} />
                                    {isToggleOn ? "Existing Form" : "New Form"}
                                </div>
                            )}
                            {!isToggleOn && questionslist?.length > 0 && (
                                <div
                                    className="saveSection"
                                    style={{
                                        marginTop: "2rem",
                                        position: "absolute",
                                        bottom: "2rem",
                                        right: "21px",
                                    }}
                                >
                                    <div>Save</div>
                                </div>
                            )}
                        </div>
                    </>
                );
                break;

            default:
                break;
        }
    };

    const handleDropItemClick = (e, row, opt) => {
        setSelectedRow(row);
        e.stopPropagation();
        setSidebarStep(0);
        if (opt === 0) {
            setCheckBoxStage("preAssessment");
        } else if (opt === 1) {
            setCheckBoxStage("patientProfile");
        } else if (opt === 2) {
            setCheckBoxStage("consulationSummary");
        }
    };


    const [allForms, setAllForms] = useState([]);
    const getAllForms = (checkBoxStage) => {
        const uname = JSON.parse(
            localStorage.getItem("orginzationdetail")
        )?.TeamUsername;
        if (checkBoxStage === "preAssessment") {
            axios
                .get(
                    `https://uatamma.apimachine.com/api/getassessment?teamusername=${uname}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setAllForms(data.data);
                        setSidebarStep("")
                        setSelectedForm(null)
                    }
                });
        } else if (checkBoxStage === "patientProfile") {
            axios
                .get(
                    `https://uatamma.apimachine.com/api/getpatientdetails?teamusername=${uname}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setAllForms(data.data);
                        setSidebarStep("")
                        setSelectedForm(null)
                    }
                });
        }
    };

    useEffect(() => {
        getAllForms(checkBoxStage);
    }, [checkBoxStage]);

    useEffect(() => {
        // Populate answerName based on selectedForm.questions
        if (selectedForm?.questions) {
            const initialAnswers = selectedForm.questions.map((item) => ({
                questionId: item._id,
                name: item.name,
                answer: "",
            }));
            setAnswerName(initialAnswers);
        }
    }, [selectedForm]);

    const handleChange = (index, newValue) => {
        const updatedAnswers = [...answerName];
        updatedAnswers[index].answer = newValue;
        setAnswerName(updatedAnswers);
    };

    useEffect(() => {
        console.log(selectedRow, "123123123123");
    }, [answerName]);
    console.log(selectedRow, "123123123123");
    const handleSubmitForm = () => {
        const apiBody = {
            preassessmentid: selectedForm?.preassessmentid,
            clientid: selectedRow?.clientid,
            consultationsessionid: selectedRow?.consultationsessionid,
            questions: answerName,
        };

        const allAnswered = apiBody.questions.every((question) => question.answer);
        if (allAnswered) {
            console.log(selectedRow, "qkjwgdkjqwegfwef");
            if (checkBoxStage === "preAssessment") {
                if (labelName == "theraphy") {
                    axios
                        .post(`https://uatamma.apimachine.com/api/adduserassessment`, {
                            preassessmentid: selectedForm?.preassessmentid,
                            clientid: selectedRow?.clientId,
                            theraphysessionid: selectedRow?.theraphyId,
                            questions: answerName,
                            formname: selectedForm?.formname,
                        })
                        .then(({ data }) => {
                            if (data.status) {
                                setCheckBoxStage("");
                                setSidebarStep("");
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 1000);
                                setFormErr(null);
                            } else {
                                setFormErr(data.message);
                            }
                        });
                }
                else {
                    axios
                        .post(`https://uatamma.apimachine.com/api/adduserassessment`, {
                            preassessmentid: selectedForm?.preassessmentid,
                            clientid: selectedRow?.clientId,
                            consultationsessionid: selectedRow?.consultsessiondetails?.[0]?.consultationsessionid,
                            questions: answerName,
                            formname: selectedForm?.formname,
                        })
                        .then(({ data }) => {
                            if (data.status) {
                                setCheckBoxStage("");
                                setSidebarStep("");
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 1000);
                                setFormErr(null);
                            } else {
                                setFormErr(data.message);
                            }
                        });
                }

            } else if (checkBoxStage === "patientProfile") {
                console.log(
                    selectedForm,
                    {
                        // preassessmentid: selectedForm?.preassessmentid,
                        patientid: selectedForm?.patientid,
                        clientid: selectedRow?.clientId,
                        formname: selectedForm?.formname,
                        // consultationsessionid: selectedRow?.consultationsessionid,
                        questions: answerName,
                    },
                    "kjafkjaefhkjehfwfw"
                );

                axios
                    .post(`https://uatamma.apimachine.com/api/adduserpatient`, {
                        patientid: selectedForm?.patientid,
                        clientid: selectedRow?.clientid,
                        formname: selectedForm?.formname,
                        // consultationsessionid: selectedRow?.consultationsessionid,
                        questions: answerName,
                    })
                    .then(({ data }) => {
                        if (data.status) {
                            setCheckBoxStage("");
                            setSidebarStep("");
                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 1000);
                            setFormErr(null);
                        } else {
                            setFormErr(data.message);
                        }
                    });
            }
        } else {
            setFormErr("Fill all inputs");
        }
    };

    const conditionalSidebar = () => {
        switch (sidebarStep) {
            case 1:
                return (
                    <>
                        <div
                            className={classNames.eachFilterItem}
                            style={{
                                fontWeight: "700",
                                fontSize: "22px",
                                pointerEvents: "none",
                                paddingLeft: "0",
                                marginBottom: "1.5rem",
                                paddingTop: "5rem",
                            }}
                        >
                            {selectedForm?.formname}
                        </div>
                        <div className={classNames.formList}>
                            {selectedForm?.questions?.map((item, index) => (
                                <div key={index}>
                                    {item?.answerType === "String" ? (
                                        <div className={classNames.inputgroup}>
                                            <label>
                                                {item?.name}&nbsp;
                                                {item?.isMandatory && (
                                                    <span style={{ color: "orange" }}>*</span>
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Your Answer..."
                                                value={answerName[index]?.answer}
                                                onChange={(e) => handleChange(index, e.target.value)}
                                                required={item?.isMandatory}
                                            />
                                        </div>
                                    ) : (
                                        <div className={classNames.inputgroup}>
                                            <label>
                                                {item?.name}&nbsp;
                                                {item?.isMandatory && (
                                                    <span style={{ color: "orange" }}>*</span>
                                                )}
                                            </label>
                                            <select
                                                value={answerName[index]?.answer}
                                                onChange={(e) => handleChange(index, e.target.value)}
                                                required={item?.isMandatory}
                                            >
                                                <option value="">Select an option</option>
                                                {item?.dropdownOptions.map((option, optionIndex) => (
                                                    <option key={optionIndex} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                        <div className={classNames.submitbtn} onClick={handleSubmitForm}>
                            Submit
                        </div>
                    </>
                );

            default:
                return (
                    <>
                        <div
                            className={classNames.eachFilterItem}
                            style={{
                                fontWeight: "700",
                                fontSize: "22px",
                                pointerEvents: "none",
                                paddingLeft: "0",
                                marginBottom: "1.5rem",
                                paddingTop: "5rem",
                            }}
                        >
                            Select Form
                        </div>
                        <div className={classNames.formList}>
                            {allForms?.map((item) => (
                                <div
                                    className={classNames.eachForm}
                                    onClick={(e) => {
                                        setSidebarStep(1);
                                        setSelectedForm(item);
                                    }}
                                >
                                    {item?.formname}
                                </div>
                            ))}
                        </div>
                    </>
                );
        }
    };

    return (
        <>

            <div className="drowpside-right" style={{ padding: "0px 29px" }}>
                {conditionalSidebar()}
                {/* <EachCustomDropdown
            dropdown={["abc", "def"]}
            name="specialistList"
            title="Select specialist"
            // stateValue={selectedSpecialist}
            // setState={setSelectedSpecialist}
            topTitle="true"
          />
          <EachInput
            title="What is the name of this form?"
            name="name"
            placeholder="Name..."
            // stateValue={formName}
            // setState={setFormName}
            topTitle="true"
          /> */}

                {/* <button
            // onClick={addQuestion}
            className={classNames.addQBtn}
          >
            Add Question
          </button>

          <button>Submit</button> */}
                {/* {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>} */}
            </div>

        </>
    );
};

export default Organization;
